import * as React from 'react';

import PToolTip from '@Library/PToolTip';

type VZeroSideMenuItemProps = {
  children: React.ReactNode;
  Icon?: React.ElementType;
  pVariant?: 'primary';
  disabled?: boolean;
  onClick: () => void;
  active?: boolean;
  showLabel?: boolean;
  post?: any;
};

function VZeroSideMenuItem({
  children,
  Icon = null,
  pVariant = 'primary',
  disabled = false,
  onClick,
  active = false,
  showLabel = true,
  post,
}: VZeroSideMenuItemProps) {
  const menuItem = (
    <div
      className={`text-base flex items-center gap-3 rounded-lg pl-4 pr-3 py-2 text-gray-500 transition-all hover:text-gray-900 cursor-pointer ${
        active ? 'bg-gray-100' : ''
      }`}
      onClick={onClick}
    >
      {Icon && <Icon className="h-5 w-5" />}
      {showLabel && <span className={`font-bold ${active ? 'text-gray-900' : ''}`}>{children}</span>}
    </div>
  );

  return showLabel ? (
    menuItem
  ) : (
    <PToolTip title={children} placement="right">
      {menuItem}
    </PToolTip>
  );
}

export default VZeroSideMenuItem;
