'use client';

import * as React from 'react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { cn } from '@/lib/utils';

type ConfirmationMethod = 'button' | 'text' | 'checkbox';

interface ConfirmationDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  description: string;
  confirmText?: string;
  cancelText?: string;
  severity?: 'low' | 'medium' | 'high';
  confirmationMethod?: ConfirmationMethod;
  confirmationValue?: string;
  caseSensitive?: boolean;
}

export function ConfirmationDialog({
  isOpen,
  onClose,
  onConfirm,
  title,
  description,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  severity = 'medium',
  confirmationMethod = 'button',
  confirmationValue = '',
  caseSensitive = true,
}: ConfirmationDialogProps) {
  const [inputValue, setInputValue] = React.useState('');
  const [isChecked, setIsChecked] = React.useState(false);

  const isConfirmDisabled =
    (confirmationMethod === 'text' &&
      (caseSensitive
        ? inputValue !== confirmationValue
        : inputValue.toLowerCase() !== confirmationValue.toLowerCase())) ||
    (confirmationMethod === 'checkbox' && !isChecked);

  return (
    <AlertDialog open={isOpen} onOpenChange={onClose}>
      <AlertDialogContent className="max-w-md rounded-lg border border-border bg-background p-6">
        <AlertDialogHeader className="space-y-3">
          <AlertDialogTitle className="text-lg font-semibold">{title}</AlertDialogTitle>
          <AlertDialogDescription className="text-sm text-muted-foreground">{description}</AlertDialogDescription>
        </AlertDialogHeader>

        {confirmationMethod === 'text' && (
          <div className="mt-4 space-y-3">
            <Label htmlFor="confirm" className="text-sm text-foreground">
              Please type <span className="font-medium">{confirmationValue}</span> to confirm
            </Label>
            <Input
              id="confirm"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder={`Type ${confirmationValue} here to confirm`}
              className="h-9 border-border bg-background text-sm"
            />
          </div>
        )}

        {confirmationMethod === 'checkbox' && (
          <div className="mt-4 flex items-center space-x-2">
            <input
              type="checkbox"
              id="confirm-checkbox"
              checked={isChecked}
              onChange={(e) => setIsChecked(e.target.checked)}
              className="h-4 w-4 rounded border-border text-primary focus:ring-1 focus:ring-ring"
            />
            <Label htmlFor="confirm-checkbox" className="text-sm text-muted-foreground">
              I understand the consequences of this action
            </Label>
          </div>
        )}

        <AlertDialogFooter className="mt-6 gap-2">
          <AlertDialogCancel className="h-9 border-border bg-background text-sm font-normal hover:bg-muted">
            {cancelText}
          </AlertDialogCancel>
          <AlertDialogAction
            onClick={onConfirm}
            disabled={isConfirmDisabled}
            className={cn(
              'h-9 bg-primary text-sm font-normal text-primary-foreground hover:bg-primary/90',
              severity === 'high' && 'bg-destructive hover:bg-destructive/90',
            )}
          >
            {confirmText}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
