import * as React from 'react';
import { InputLabel, Box, Button, TextField, Modal } from '@mui/material';
import fundzService from '../../../services/fundzService';
import FileUploadComponent from './fileUploadComponent';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { useContext } from 'react';
import { FundzContext } from './FundzContext';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function FundzUploadData() {
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalMessage, setModalMessage] = React.useState('');
  const { setFundzMatches } = useContext(FundzContext);

  const disableDates = (date) => {
    if (startDate && date < startDate) {
      return true;
    }
    return false;
  };

  const handleModalOpen = (message) => {
    setModalMessage(message);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ p: 3, bgcolor: 'background.paper' }}>
          <UploadCsvForm />
        </Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box sx={{ mb: 2 }}>
            <InputLabel>Start Date (Date to begin processing data)</InputLabel>
            <DatePicker
              value={startDate}
              onChange={setStartDate}
              renderInput={(props) => <TextField {...props} fullWidth />}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <InputLabel>Optional End Date (Date to stop processing data)</InputLabel>
            <DatePicker
              value={endDate}
              onChange={setEndDate}
              shouldDisableDate={disableDates}
              renderInput={(props) => <TextField {...props} fullWidth />}
            />
          </Box>
        </LocalizationProvider>
        <Button
          variant="contained"
          color="primary"
          disabled={!startDate || (endDate && endDate < startDate)}
          onClick={() => {
            const processingDates = { startDate: startDate };
            if (endDate) {
              processingDates.endDate = endDate;
            }
            fundzService
              .processFundzData(processingDates)
              .then((res) => {
                if (res.status === true) {
                  setFundzMatches(res.data);
                  handleModalOpen(`Detected ${res?.data?.length || 0} event(s)`);
                } else {
                  handleModalOpen(`${res?.statusText} - ${res?.data}`);
                }
              })
              .catch((error) => {
                handleModalOpen(`Error: ${error.message}`);
              });
          }}
        >
          Process Fundz Data
        </Button>
      </Box>

      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <h2 id="modal-title">Processing Complete</h2>
          <p id="modal-description">{modalMessage}</p>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button onClick={handleModalClose} sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={handleModalClose}>
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

const UploadCsvForm = () => {
  // const theme = useTheme();
  // const fileInputRef = React.useRef(null);
  const [acquisitionFile, setAcquisitionFile] = React.useState(null);
  const [fundingFile, setFundingFile] = React.useState(null);
  const [uploadedAcquiFilePath, setUploadedAcquisitionsFileName] = React.useState('');
  const [uploadedFundsFilePath, setUploadedFundsFileName] = React.useState('');
  const [processedComplete, setProcessedComplete] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('loading');

  const handleSubmit = (event) => {
    event.preventDefault();
  };
  // const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDeleteAcquisitionFile = () => {
    setUploadedAcquisitionsFileName('');
    setAcquisitionFile(null);
    setProcessedComplete(false);
  };

  const setUploadedFundsSuccess = (filename) => {
    setUploadedFundsFileName(filename);
    setProcessedComplete(false);
  };

  const setUploadedAcquisitionsSuccess = (filename) => {
    setUploadedAcquisitionsFileName(filename);
    setProcessedComplete(false);
  };

  const handleDeleteFundingFile = () => {
    setUploadedFundsFileName('');
    setFundingFile(null);
    setProcessedComplete(false);
  };

  const handleProcessFile = async () => {
    setOpen(true); // Open the modal
    setMessage('Processing...');
    let resultMessage = ``;
    if (uploadedFundsFilePath) {
      console.log('uploadedFundsFilePath exists process it');
      const responsefundz = await fundzService.postFundingData(uploadedFundsFilePath);
      resultMessage += `Fundings: ${responsefundz?.data?.savedResults?.insertedCount} `;
      if (responsefundz?.data?.savedResults?.writeErrorCount) {
        resultMessage += `(duplicates: ${responsefundz?.data?.savedResults?.writeErrorCount}) `;
      }
    }

    if (uploadedAcquiFilePath) {
      console.log('uploadedAcquiFilePath exists process it');
      const responseAcquisition = await fundzService.postAcquisitionData(uploadedAcquiFilePath);
      resultMessage += `Acquisitions: ${responseAcquisition?.data?.savedResults?.insertedCount} `;
      if (responseAcquisition?.data?.savedResults?.writeErrorCount) {
        resultMessage += `(duplicates: ${responseAcquisition?.data?.savedResults?.writeErrorCount}) `;
      }
    }

    setMessage(resultMessage);
    setProcessedComplete(true);
  };

  const handleFileUpload = async (file, apiEndpoint) => {
    if (!file) {
      console.warn('No file selected');
      return;
    }

    try {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = async (event) => {
        const fileContent = event.target.result;
        const formData = new FormData();
        formData.append('file', file);
        formData.append('originalname', file.name);
        formData.append('path', file.path);
        formData.append('fileContent', fileContent);

        const response = await fetch(apiEndpoint, {
          method: 'POST',
          body: formData,
        });
        if (response.ok) {
          const data = await response.json();
          setProcessedComplete(false);
          return data.fileName;
        } else {
          console.error('HTTP-Error: ' + response.status);
        }
      };
    } catch (error) {
      console.error(error);
    }
  };

  // const processFile = async (serviceFunction, fileName) => {
  //   await serviceFunction(fileName);
  //   handleDeleteFile();
  // };

  return (
    <form onSubmit={handleSubmit}>
      <FileUploadComponent
        selectedFile={fundingFile}
        apiEndpoint="/api/funding/uploadCsv"
        handleSaveFile={handleFileUpload}
        buttonLabel="Add Fundings CSV File"
        setFile={setFundingFile}
        onUploadSuccess={setUploadedFundsSuccess}
        onUploadError={console.error}
        handleDeleteFile={handleDeleteFundingFile}
      />

      <FileUploadComponent
        selectedFile={acquisitionFile}
        apiEndpoint="/api/acquisition/uploadCsv"
        handleSaveFile={handleFileUpload}
        buttonLabel="Add Acquisition CSV File"
        setFile={setAcquisitionFile}
        onUploadSuccess={setUploadedAcquisitionsSuccess}
        onUploadError={console.error}
        handleDeleteFile={handleDeleteAcquisitionFile}
      />
      {(uploadedAcquiFilePath || uploadedFundsFilePath) && (
        <>
          <Button variant="contained" color="primary" onClick={handleProcessFile} disabled={processedComplete}>
            {processedComplete ? 'Processed' : 'Process File'}
          </Button>
          <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
            <Box sx={modalStyle}>
              <h2 id="modal-title">Uploaded Results</h2>
              <p id="modal-description">{message}</p>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <Button onClick={handleClose} sx={{ mr: 1 }}>
                  Cancel
                </Button>
                <Button variant="contained" color="primary" onClick={handleClose}>
                  Close
                </Button>
              </Box>
            </Box>
          </Modal>
        </>
      )}
    </form>
  );
};
