import { demoData } from '../mockdata';
import Fuse from 'fuse.js';

// Add these helper functions at the top
function normalizeTitle(title: string): string {
  return title.toLowerCase().trim();
}

// New helper function for title matching logic
function matchTitleStrings(
  normalizedActual: string,
  targetTitle: string,
  fuseOptions: Fuse.IFuseOptions<any>,
): boolean {
  const normalizedTarget = normalizeTitle(targetTitle);

  // Exact match
  if (normalizedActual === normalizedTarget) {
    return true;
  }

  // Fuzzy match using Fuse.js
  const fuse = new Fuse([{ title: normalizedTarget }], fuseOptions);
  const fuzzyMatch = fuse.search(normalizedActual);
  if (fuzzyMatch.length > 0) {
    return true;
  }

  // Handle common abbreviations
  if (targetTitle.includes('(') && targetTitle.includes(')')) {
    const [full, abbrev] = targetTitle.split(/[()]/);
    if (normalizedActual === normalizeTitle(full) || normalizedActual === normalizeTitle(abbrev)) {
      return true;
    }
  }

  // Check if actual title contains the full target phrase
  if (normalizedActual.includes(normalizedTarget)) {
    return true;
  }

  // Check for consecutive word matches
  const actualWords = normalizedActual.split(' ');
  const targetWords = normalizedTarget.split(' ');

  if (targetWords.length > actualWords.length) {
    return false;
  }

  for (let i = 0; i <= actualWords.length - targetWords.length; i++) {
    const slice = actualWords.slice(i, i + targetWords.length);
    if (slice.join(' ') === targetWords.join(' ')) {
      return true;
    }
  }

  return false;
}

// Refactored isTitleMatch function
function isTitleMatch(actualTitle: string, targetTitle: string | { [displayTitle: string]: string[] }): boolean {
  const normalizedActual = normalizeTitle(actualTitle);
  const fuseOptions = {
    includeScore: true,
    threshold: 0.3,
    keys: ['title'],
  };

  if (typeof targetTitle === 'string') {
    const result = matchTitleStrings(normalizedActual, targetTitle, fuseOptions);
    return result;
  } else if (typeof targetTitle === 'object') {
    const result = Object.entries(targetTitle).some(([displayTitle, variations]) => {
      if (matchTitleStrings(normalizedActual, displayTitle, fuseOptions)) {
        return true;
      }
      return variations.some((variation) => {
        const variationMatch = matchTitleStrings(normalizedActual, variation, fuseOptions);
        return variationMatch;
      });
    });
    return result;
  }

  return false;
}

function isLocationSubset(contactLocation: string, searchLocation: string): boolean {
  const normalizedContactLocation = contactLocation.toLowerCase().trim();
  const normalizedSearchLocation = searchLocation.toLowerCase().trim();
  return normalizedContactLocation.includes(normalizedSearchLocation);
}

/**
 * Finds contacts based on various parameters.
 *
 * @param {Object} params - The search parameters.
 * @param {Object} [params.industryMappings] - Mapping of display industries to their variations.
 * @param {Object} [params.titleMappings] - Mapping of display titles to their variations.
 * @param {string[]} [params.knownBy] - The knownBy contact to search for (exact match).
 * @param {string} [params.location] - The location to search for (fuzzy match).
 * @param {string} [params.companySize] - Company size range (e.g., "1-10", "11-50", etc.).
 * @param {string[]} [params.industries] - Array of industries to filter by.
 * @returns {Array} - An array of contacts that match the search criteria.
 */
export function findContacts({
  industryMappings,
  titleMappings,
  selectedTitles,
  knownBy,
  location,
  companySize,
  industries,
}: {
  industryMappings?: { [key: string]: string[] };
  titleMappings?: { [key: string]: string[] };
  selectedTitles?: string[];
  knownBy?: string[];
  location?: string;
  companySize?: string;
  industries?: string[];
}) {
  const results: any[] = [];

  demoData.forEach((company) => {
    if (companySize && !filterByCompanySize(company, companySize)) {
      return;
    }

    if (industries?.length > 0 && !matchesIndustry(company, industries, industryMappings)) {
      return;
    }

    company.contacts.forEach((contact) => {
      const contactInfo = contact.contactInfo[0];
      if (
        matchesKnownBy(contact, knownBy) &&
        matchesTitle(contactInfo, selectedTitles, titleMappings) &&
        matchesLocation(contactInfo, location)
      ) {
        results.push({
          companyData: company.companyData,
          contactInfo: contact.contactInfo,
          knownBy: contact.knownBy,
          relationshipScore: contact.relationshipScore,
        });
      }
    });
  });

  return results;
}

/**
 * Filters a company based on the specified size range.
 *
 * @param {Object} company - The company object.
 * @param {string} sizeRange - The size range to filter by.
 * @returns {boolean} - True if the company matches the size range, else false.
 */
function filterByCompanySize(company: any, sizeRange: string): boolean {
  const employeeCount = company.companyData.employees?.toString() || '0';
  const isInRange = isInSizeRange(employeeCount, sizeRange);
  return isInRange;
}

/**
 * Filters a company based on the specified industries.
 *
 * @param {Object} company - The company object.
 * @param {string[]} industries - The industries to filter by.
 * @returns {boolean} - True if the company matches any of the industries, else false.
 */
function matchesIndustry(company: any, industries: string[], industryMappings: { [key: string]: string[] }): boolean {
  if (!industries || industries.length === 0) return true;

  if (!company.companyData.industry?.trim()) {
    return false;
  }

  const allIndustries = industries.reduce((acc, industry) => {
    return [...acc, industry, ...(industryMappings?.[industry] || [])];
  }, []);

  const industryMatched = allIndustries.some((industry) => isTitleMatch(company.companyData.industry, industry));

  return industryMatched;
}

/**
 * Determines if a contact matches the knownBy criteria.
 *
 * @param {Object} contact - The contact object.
 * @param {string[]} [knownBy] - The knownBy criteria.
 * @returns {boolean} - True if the contact matches the knownBy criteria, else false.
 */
function matchesKnownBy(contact: any, knownBy?: string[]): boolean {
  if (!knownBy || knownBy.length === 0) return true;

  if (knownBy.includes('Nobody')) {
    const hasConnections = contact.knownBy && contact.knownBy.length > 0;
    return !hasConnections;
  }

  const isKnownByAny = contact.knownBy?.some((person) => knownBy.includes(person)) || false;
  return isKnownByAny;
}

/**
 * Determines if a contact's title matches the selected titles or their variations.
 *
 * @param {Object} contactInfo - The contact information object.
 * @param {string[]} [selectedTitles] - The selected titles to match.
 * @param {Object} [titleMappings] - The title mappings for variations.
 * @returns {boolean} - True if the title matches, else false.
 */
function matchesTitle(
  contactInfo: any,
  selectedTitles?: string[],
  titleMappings?: { [key: string]: string[] },
): boolean {
  if (!selectedTitles || selectedTitles.length === 0) return true;

  if (!contactInfo.title || contactInfo.title.trim() === '') {
    return false;
  }

  const allTitles = selectedTitles.reduce((acc, title) => {
    return [...acc, title, ...(titleMappings?.[title] || [])];
  }, []);

  const titleMatched = allTitles.some((title) => isTitleMatch(contactInfo.title, title));

  return titleMatched;
}

/**
 * Determines if a contact's location matches the specified location criteria.
 *
 * @param {Object} contactInfo - The contact information object.
 * @param {string} [location] - The location criteria.
 * @returns {boolean} - True if the location matches, else false.
 */
function matchesLocation(contactInfo: any, location?: string): boolean {
  if (!location) return true;

  if (!contactInfo.location || contactInfo.location.trim() === '') {
    return false;
  }

  const fuse = new Fuse([contactInfo], { keys: ['location'], threshold: 0.2 });
  const fuzzyMatch = fuse.search(location).length > 0;

  if (fuzzyMatch) {
    return true;
  }

  const subsetMatch = isLocationSubset(contactInfo.location, location);
  return subsetMatch;
}

/**
 * Performs a fuzzy search on a given key within a dataset.
 *
 * @param {Array} data - The dataset to search within.
 * @param {string} key - The key to perform the fuzzy search on.
 * @param {string} term - The search term.
 * @returns {Array} - The filtered dataset based on the fuzzy search.
 */
function fuzzySearch(data: any[], key: string, term: string) {
  const options = {
    keys: [key],
    threshold: 0.3,
  };
  const fuse = new Fuse(data, options);
  const searchResults = fuse.search(term);

  return searchResults.map((result) => result.item);
}

export function getUniqueKnownByNames(): string[] {
  const knownBySet = new Set<string>();

  demoData.forEach((company) => {
    company.contacts.forEach((contact) => {
      if (contact.knownBy) {
        contact.knownBy.forEach((name) => knownBySet.add(name));
      }
    });
  });

  return Array.from(knownBySet).sort();
}

const COUNTRY_FLAG_MAPPINGS: { [key: string]: string } = {
  AD: '🇦🇩',
  AE: '🇦🇪',
  AF: '🇦🇫',
  AG: '🇦🇬',
  AI: '🇦🇮',
  AL: '🇦🇱',
  AM: '🇦🇲',
  AO: '🇦🇴',
  AR: '🇦🇷',
  AS: '🇦🇸',
  AT: '🇦🇹',
  AU: '🇦🇺',
  AW: '🇦🇼',
  AX: '🇦🇽',
  AZ: '🇦🇿',
  BA: '🇧🇦',
  BB: '🇧🇧',
  BD: '🇧🇩',
  BE: '🇧🇪',
  BF: '🇧🇫',
  BG: '🇧🇬',
  BH: '🇧🇭',
  BI: '🇧🇮',
  BJ: '🇧🇯',
  BL: '🇧🇱',
  BM: '🇧🇲',
  BN: '🇧🇳',
  BO: '🇧🇴',
  BQ: '🇧🇶',
  BR: '🇧🇷',
  BS: '🇧🇸',
  BT: '🇧🇹',
  BV: '🇧🇻',
  BW: '🇧🇼',
  BY: '🇧🇾',
  BZ: '🇧🇿',
  CA: '🇨🇦',
  CC: '🇨🇨',
  CD: '🇨🇩',
  CF: '🇨🇫',
  CG: '🇨🇬',
  CH: '🇨🇭',
  CI: '🇨🇮',
  CK: '🇨🇰',
  CL: '🇨🇱',
  CM: '🇨🇲',
  CN: '🇨🇳',
  CO: '🇨🇴',
  CR: '🇨🇷',
  CU: '🇨🇺',
  CV: '🇨🇻',
  CW: '🇨🇼',
  CX: '🇨🇽',
  CY: '🇨🇾',
  CZ: '🇨🇿',
  DE: '🇩🇪',
  DJ: '🇩🇯',
  DK: '🇩🇰',
  DM: '🇩🇲',
  DO: '🇩🇴',
  DZ: '🇩🇿',
  EC: '🇪🇨',
  EE: '🇪🇪',
  EG: '🇪🇬',
  EH: '🇪🇭',
  ER: '🇪🇷',
  ES: '🇪🇸',
  ET: '🇪🇹',
  FI: '🇫🇮',
  FJ: '🇫🇯',
  FM: '🇫🇲',
  FO: '🇫🇴',
  FR: '🇫🇷',
  GA: '🇬🇦',
  GB: '🇬🇧',
  UK: '🇬🇧',
  GD: '🇬🇩',
  GE: '🇬🇪',
  GF: '🇬🇫',
  GG: '🇬🇬',
  GH: '🇬🇭',
  GI: '🇬🇮',
  GL: '🇬🇱',
  GM: '🇬🇲',
  GN: '🇬🇳',
  GP: '🇬🇵',
  GQ: '🇬🇶',
  GR: '🇬🇷',
  GT: '🇬🇹',
  GU: '🇬🇺',
  GW: '🇬🇼',
  GY: '🇬🇾',
  HK: '🇭🇰',
  HM: '🇭🇲',
  HN: '🇭🇳',
  HR: '🇭🇷',
  HT: '🇭🇹',
  HU: '🇭🇺',
  ID: '🇮🇩',
  IE: '🇮🇪',
  IL: '🇮🇱',
  IM: '🇮🇲',
  IN: '🇮🇳',
  IO: '🇮🇴',
  IQ: '🇮🇶',
  IR: '🇮🇷',
  IS: '🇮🇸',
  IT: '🇮🇹',
  JE: '🇯🇪',
  JM: '🇯🇲',
  JO: '🇯🇴',
  JP: '🇯🇵',
  KE: '🇰🇪',
  KG: '🇰🇬',
  KH: '🇰🇭',
  KI: '🇰🇮',
  KM: '🇰🇲',
  KN: '🇰🇳',
  KP: '🇰🇵',
  KR: '🇰🇷',
  KW: '🇰🇼',
  KY: '🇰🇾',
  KZ: '🇰🇿',
  LA: '🇱🇦',
  LB: '🇱🇧',
  LC: '🇱🇨',
  LI: '🇱🇮',
  LK: '🇱🇰',
  LR: '🇱🇷',
  LS: '🇱🇸',
  LT: '🇱🇹',
  LU: '🇱🇺',
  LV: '🇱🇻',
  LY: '🇱🇾',
  MA: '🇲🇦',
  MC: '🇲🇨',
  MD: '🇲🇩',
  ME: '🇲🇪',
  MF: '🇲🇫',
  MG: '🇲🇬',
  MH: '🇲🇭',
  MK: '🇲🇰',
  ML: '🇲🇱',
  MM: '🇲🇲',
  MN: '🇲🇳',
  MO: '🇲🇴',
  MP: '🇲🇵',
  MQ: '🇲🇶',
  MR: '🇲🇷',
  MS: '🇲🇸',
  MT: '🇲🇹',
  MU: '🇲🇺',
  MV: '🇲🇻',
  MW: '🇲🇼',
  MX: '🇲🇽',
  MY: '🇲🇾',
  MZ: '🇲🇿',
  NA: '🇳🇦',
  NC: '🇳🇨',
  NE: '🇳🇪',
  NF: '🇳🇫',
  NG: '🇳🇬',
  NI: '🇳🇮',
  NL: '🇳🇱',
  NO: '🇳🇴',
  NP: '🇳🇵',
  NR: '🇳🇷',
  NU: '🇳🇺',
  NZ: '🇳🇿',
  OM: '🇴🇲',
  PA: '🇵🇦',
  PE: '🇵🇪',
  PF: '🇵🇫',
  PG: '🇵🇬',
  PH: '🇵🇭',
  PK: '🇵🇰',
  PL: '🇵🇱',
  PM: '🇵🇲',
  PN: '🇵🇳',
  PR: '🇵🇷',
  PT: '🇵🇹',
  PW: '🇵🇼',
  PY: '🇵🇾',
  QA: '🇶🇦',
  RE: '🇷🇪',
  RO: '🇷🇴',
  RS: '🇷🇸',
  RU: '🇷🇺',
  RW: '🇷🇼',
  SA: '🇸🇦',
  SB: '🇸🇧',
  SC: '🇸🇨',
  SD: '🇸🇩',
  SE: '🇸🇪',
  SG: '🇸🇬',
  SH: '🇸🇭',
  SI: '🇸🇮',
  SJ: '🇸🇯',
  SK: '🇸🇰',
  SL: '🇸🇱',
  SM: '🇸🇲',
  SN: '🇸🇳',
  SO: '🇸🇴',
  SR: '🇸🇷',
  SS: '🇸🇸',
  ST: '🇸🇹',
  SV: '🇸🇻',
  SX: '🇸🇽',
  SY: '🇸🇾',
  SZ: '🇸🇿',
  TC: '🇹🇨',
  TD: '🇹🇩',
  TF: '🇹🇫',
  TG: '🇹🇬',
  TH: '🇹🇭',
  TJ: '🇯🇯',
  TK: '🇹🇰',
  TL: '🇹🇱',
  TM: '🇹🇲',
  TN: '🇹🇳',
  TO: '🇹🇴',
  TR: '🇹🇷',
  TT: '🇹🇹',
  TV: '🇹🇻',
  TZ: '🇹🇿',
  UA: '🇺🇦',
  UG: '🇺🇬',
  UM: '🇺🇲',
  US: '🇺🇸',
  UY: '🇺🇾',
  UZ: '🇺🇿',
  VA: '🇻🇦',
  VC: '🇻🇨',
  VE: '🇻🇪',
  VG: '🇻🇬',
  VI: '🇻🇮',
  VN: '🇻🇳',
  VU: '🇻🇺',
  WF: '🇼🇫',
  WS: '🇼🇸',
  YE: '🇾🇪',
  YT: '🇾🇹',
  ZA: '🇿🇦',
  ZM: '🇿🇲',
  ZW: '🇿🇼',
};

/**
 * Converts a country code to its corresponding emoji flag
 * @param countryCode - 2-letter country code (US, CA, UK, etc)
 * @returns emoji flag for the country
 */
export function getCountryFlag(countryCode: string): string {
  const flag = COUNTRY_FLAG_MAPPINGS[countryCode.toUpperCase()];
  if (flag) {
    return flag;
  } else {
    console.warn(`No flag found for country code: ${countryCode}`);
    return '🏳️'; // fallback flag
  }
}

// Examples:
// getCountryFlag('US') => 🇺🇸
// getCountryFlag('CA') => 🇨🇦
// getCountryFlag('UK') => 🇬🇧

function parseEmployeeCount(employeeString: string): number {
  const cleanString = employeeString.replace(/,|\+/g, '');
  return parseInt(cleanString, 10);
}

function isInSizeRange(employeeCount: string, sizeRange: string): boolean {
  const actualSize = parseEmployeeCount(employeeCount);

  if (sizeRange.endsWith('+')) {
    // Handle "5000+" case
    const minSize = parseEmployeeCount(sizeRange.slice(0, -1));
    return actualSize >= minSize;
  } else {
    // Handle "0-1000" case
    const [minSize, maxSize] = sizeRange.split('-').map(parseEmployeeCount);
    return actualSize >= minSize && actualSize <= maxSize;
  }
}

const COUNTRY_NAME_TO_CODE: { [key: string]: string } = {
  'Andorra': 'AD',
  'United Arab Emirates': 'AE',
  'Afghanistan': 'AF',
  'Antigua and Barbuda': 'AG',
  'Anguilla': 'AI',
  'Albania': 'AL',
  'Armenia': 'AM',
  'Angola': 'AO',
  'Argentina': 'AR',
  'American Samoa': 'AS',
  'Austria': 'AT',
  'Australia': 'AU',
  'Aruba': 'AW',
  'Åland Islands': 'AX',
  'Azerbaijan': 'AZ',
  'Bosnia and Herzegovina': 'BA',
  'Barbados': 'BB',
  'Bangladesh': 'BD',
  'Belgium': 'BE',
  'Burkina Faso': 'BF',
  'Bulgaria': 'BG',
  'Bahrain': 'BH',
  'Burundi': 'BI',
  'Benin': 'BJ',
  'Saint Barthélemy': 'BL',
  'Bermuda': 'BM',
  'Brunei Darussalam': 'BN',
  'Bolivia': 'BO',
  'Bonaire, Sint Eustatius and Saba': 'BQ',
  'Brazil': 'BR',
  'Bahamas': 'BS',
  'Bhutan': 'BT',
  'Bouvet Island': 'BV',
  'Botswana': 'BW',
  'Belarus': 'BY',
  'Belize': 'BZ',
  'Canada': 'CA',
  'Cocos (Keeling) Islands': 'CC',
  'Congo (Kinshasa)': 'CD',
  'Central African Republic': 'CF',
  'Congo (Brazzaville)': 'CG',
  'Switzerland': 'CH',
  "Côte d'Ivoire": 'CI',
  'Cook Islands': 'CK',
  'Chile': 'CL',
  'Cameroon': 'CM',
  'China': 'CN',
  'Colombia': 'CO',
  'Costa Rica': 'CR',
  'Cuba': 'CU',
  'Cabo Verde': 'CV',
  'Curaçao': 'CW',
  'Christmas Island': 'CX',
  'Cyprus': 'CY',
  'Czechia': 'CZ',
  'Germany': 'DE',
  'Djibouti': 'DJ',
  'Denmark': 'DK',
  'Dominica': 'DM',
  'Dominican Republic': 'DO',
  'Algeria': 'DZ',
  'Ecuador': 'EC',
  'Estonia': 'EE',
  'Egypt': 'EG',
  'Western Sahara': 'EH',
  'Eritrea': 'ER',
  'Spain': 'ES',
  'Ethiopia': 'ET',
  'Finland': 'FI',
  'Fiji': 'FJ',
  'Micronesia': 'FM',
  'Faroe Islands': 'FO',
  'France': 'FR',
  'Gabon': 'GA',
  'United Kingdom': 'GB',
  'Grenada': 'GD',
  'Georgia': 'GE',
  'French Guiana': 'GF',
  'Guernsey': 'GG',
  'Ghana': 'GH',
  'Gibraltar': 'GI',
  'Greenland': 'GL',
  'Gambia': 'GM',
  'Guinea': 'GN',
  'Guadeloupe': 'GP',
  'Equatorial Guinea': 'GQ',
  'Greece': 'GR',
  'Guatemala': 'GT',
  'Guam': 'GU',
  'Guinea-Bissau': 'GW',
  'Guyana': 'GY',
  'Hong Kong': 'HK',
  'Heard Island and McDonald Islands': 'HM',
  'Honduras': 'HN',
  'Croatia': 'HR',
  'Haiti': 'HT',
  'Hungary': 'HU',
  'Indonesia': 'ID',
  'Ireland': 'IE',
  'Israel': 'IL',
  'Isle of Man': 'IM',
  'India': 'IN',
  'British Indian Ocean Territory': 'IO',
  'Iraq': 'IQ',
  'Iran': 'IR',
  'Iceland': 'IS',
  'Italy': 'IT',
  'Jersey': 'JE',
  'Jamaica': 'JM',
  'Jordan': 'JO',
  'Japan': 'JP',
  'Kenya': 'KE',
  'Kyrgyzstan': 'KG',
  'Cambodia': 'KH',
  'Kiribati': 'KI',
  'Comoros': 'KM',
  'Saint Kitts and Nevis': 'KN',
  'North Korea': 'KP',
  'South Korea': 'KR',
  'Kuwait': 'KW',
  'Cayman Islands': 'KY',
  'Kazakhstan': 'KZ',
  "Lao People's Democratic Republic": 'LA',
  'Lebanon': 'LB',
  'Saint Lucia': 'LC',
  'Liechtenstein': 'LI',
  'Sri Lanka': 'LK',
  'Liberia': 'LR',
  'Lesotho': 'LS',
  'Lithuania': 'LT',
  'Luxembourg': 'LU',
  'Latvia': 'LV',
  'Libya': 'LY',
  'Morocco': 'MA',
  'Monaco': 'MC',
  'Moldova': 'MD',
  'Montenegro': 'ME',
  'Saint Martin (French part)': 'MF',
  'Madagascar': 'MG',
  'Marshall Islands': 'MH',
  'North Macedonia': 'MK',
  'Mali': 'ML',
  'Myanmar': 'MM',
  'Mongolia': 'MN',
  'Macao': 'MO',
  'Northern Mariana Islands': 'MP',
  'Martinique': 'MQ',
  'Mauritania': 'MR',
  'Montserrat': 'MS',
  'Malta': 'MT',
  'Mauritius': 'MU',
  'Maldives': 'MV',
  'Malawi': 'MW',
  'Mexico': 'MX',
  'Malaysia': 'MY',
  'Mozambique': 'MZ',
  'Namibia': 'NA',
  'New Caledonia': 'NC',
  'Niger': 'NE',
  'Norfolk Island': 'NF',
  'Nigeria': 'NG',
  'Nicaragua': 'NI',
  'Netherlands': 'NL',
  'Norway': 'NO',
  'Nepal': 'NP',
  'Nauru': 'NR',
  'Niue': 'NU',
  'New Zealand': 'NZ',
  'Oman': 'OM',
  'Panama': 'PA',
  'Peru': 'PE',
  'French Polynesia': 'PF',
  'Papua New Guinea': 'PG',
  'Philippines': 'PH',
  'Pakistan': 'PK',
  'Poland': 'PL',
  'Saint Pierre and Miquelon': 'PM',
  'Pitcairn': 'PN',
  'Puerto Rico': 'PR',
  'Portugal': 'PT',
  'Palau': 'PW',
  'Paraguay': 'PY',
  'Qatar': 'QA',
  'Réunion': 'RE',
  'Romania': 'RO',
  'Serbia': 'RS',
  'Russian Federation': 'RU',
  'Rwanda': 'RW',
  'Saudi Arabia': 'SA',
  'Solomon Islands': 'SB',
  'Seychelles': 'SC',
  'Sudan': 'SD',
  'Sweden': 'SE',
  'Singapore': 'SG',
  'Saint Helena, Ascension and Tristan da Cunha': 'SH',
  'Slovenia': 'SI',
  'Svalbard and Jan Mayen': 'SJ',
  'Slovakia': 'SK',
  'Sierra Leone': 'SL',
  'San Marino': 'SM',
  'Senegal': 'SN',
  'Somalia': 'SO',
  'Suriname': 'SR',
  'South Sudan': 'SS',
  'Sao Tome and Principe': 'ST',
  'El Salvador': 'SV',
  'Sint Maarten (Dutch part)': 'SX',
  'Syrian Arab Republic': 'SY',
  'Eswatini': 'SZ',
  'Turks and Caicos Islands': 'TC',
  'Chad': 'TD',
  'French Southern Territories': 'TF',
  'Togo': 'TG',
  'Thailand': 'TH',
  'Tajikistan': 'TJ',
  'Tokelau': 'TK',
  'Timor-Leste': 'TL',
  'Turkmenistan': 'TM',
  'Tunisia': 'TN',
  'Tonga': 'TO',
  'Turkey': 'TR',
  'Trinidad and Tobago': 'TT',
  'Tuvalu': 'TV',
  'Tanzania': 'TZ',
  'Ukraine': 'UA',
  'Uganda': 'UG',
  'United States Minor Outlying Islands': 'UM',
  'United States of America': 'US',
  'Uruguay': 'UY',
  'Uzbekistan': 'UZ',
  'Holy See': 'VA',
  'Saint Vincent and the Grenadines': 'VC',
  'Venezuela (Bolivarian Republic of)': 'VE',
  'Virgin Islands (British)': 'VG',
  'Virgin Islands (U.S.)': 'VI',
  'Viet Nam': 'VN',
  'Vanuatu': 'VU',
  'Wallis and Futuna': 'WF',
  'Samoa': 'WS',
  'Yemen': 'YE',
  'Mayotte': 'YT',
  'South Africa': 'ZA',
  'Zambia': 'ZM',
  'Zimbabwe': 'ZW',
};

// Function to convert country name to code
export function convertCountryNameToCode(countryName: string): string {
  const code = COUNTRY_NAME_TO_CODE[countryName];
  if (code) {
    return code;
  } else {
    console.warn(`No country code found for country name: ${countryName}`);
    return countryName; // return the original name if no code is found
  }
}

function matchIndustryStrings(
  normalizedActual: string,
  targetIndustry: string,
  fuseOptions: Fuse.IFuseOptions<any>,
): boolean {
  const normalizedTarget = normalizedActual.toLowerCase().trim();

  // Exact match
  if (normalizedActual === normalizedTarget) {
    return true;
  }

  // Fuzzy match using Fuse.js
  const fuse = new Fuse([{ industry: normalizedTarget }], fuseOptions);
  const fuzzyMatch = fuse.search(normalizedActual);
  if (fuzzyMatch.length > 0) {
    return true;
  }

  // Check if actual industry contains the full target phrase
  if (normalizedActual.includes(normalizedTarget)) {
    return true;
  }

  return false;
}
