import { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useToast } from '@Hooks/use-toast.tsx';
import useOutsideClick from '@Hooks/useOutsideClick';
import {
  changeClientFilterSearchTerm,
  changeClientFilterStages,
  changeClientFilterLastInteraction,
  changeClientSortCriteria,
  changeClientShowHidden,
  changeClientPage,
  setSelectedClient,
  fetchAndSetHighestScores,
  setEditingStageClientId,
  setLoadingStage,
  updateClientStage,
  changeClientVisibility,
} from '@/redux/ermDashboardSlice';

export const useClientTable = () => {
  const dispatch = useDispatch();
  const { toast } = useToast();
  const popoverRef = useRef<HTMLDivElement>(null);

  // Update selectors to include new UI state
  const editingStageClientId = useSelector((state: any) => state.ermDashboard.clientTable.ui.editingStageClientId);
  const isClientSettingStage = useSelector((state: any) => state.ermDashboard.clientTable.ui.isClientSettingStage);
  const pipelineStages = useSelector((state: any) => state.auth.company.company.ermPreference.pipelineStages);
  const allStageNames = useMemo(() => pipelineStages.map((stage: any) => stage.name), [pipelineStages]);

  // Remove local state management
  useOutsideClick(popoverRef, () => dispatch(setEditingStageClientId(null)));

  // Get state from ermDashboardSlice
  const { page, limit, filters, sortCriteria } = useSelector(
    (state: any) => state.ermDashboard.clientTable.queryParams,
  );
  const { cacheByPage, highestRelationshipScore, total } = useSelector(
    (state: any) => state.ermDashboard.clientTable.data,
  );

  const isLoading = useSelector(
    (state: any) => !!state.ermDashboard.clientTable.loading.loadingStateByPage[page]?.loading,
  );

  const userCompany = useSelector((state) => state.auth.company?.company);
  const selectedClient = useSelector((state) => state.ermDashboard.selectedClient);

  // Derived state
  const clients = cacheByPage[page] || [];

  // Callbacks
  const onSort = (criteria: { key: string; direction: 'asc' | 'desc' }) => {
    dispatch(changeClientSortCriteria(criteria));
  };

  const onClientSelect = (client: any) => {
    dispatch(setSelectedClient(client));
  };

  const handleSearchTermChange = (value: string) => {
    dispatch(changeClientFilterSearchTerm(value));
  };

  const toggleStageFilter = (stageLabel: string) => {
    console.log('stageLabel:', stageLabel);
    const updatedFilters = {
      ...filters.stages,
      [stageLabel]: !filters.stages[stageLabel],
    };
    dispatch(changeClientFilterStages(updatedFilters));
  };

  const toggleLastInteractionFilter = (interaction: string) => {
    const updatedFilters = {
      ...filters.lastInteraction,
      [interaction]: !filters.lastInteraction[interaction],
    };
    dispatch(changeClientFilterLastInteraction(updatedFilters));
  };

  const handleStageChange = (clientId: string, newStage: string) => {
    try {
      dispatch(updateClientStage({ clientId, newStage }));
    } catch (error) {
      console.error('Failed to update stage:', error);
      toast({
        title: 'Error',
        description: 'Failed to update client stage',
      });
    }
  };

  const handleToggleClientVisibility = (clientId: string, action: 'hide' | 'show') => {
    try {
      dispatch(changeClientVisibility(clientId, action));
    } catch (error) {
      toast({
        title: 'Error',
        description: `Failed to ${action} client`,
      });
    }
  };

  const handleToggleShowHidden = (value) => {
    dispatch(changeClientShowHidden(value));
  };

  const handlePageChange = (newPage) => {
    dispatch(changeClientPage(newPage));
  };

  return {
    // State
    clients,
    page,
    limit,
    total,
    editingStageClientId,
    highestRelationshipScore,
    isLoading,
    isClientSettingStage,
    popoverRef,
    selectedClient,
    sortCriteria,
    searchTerm: filters.searchTerm,
    stageFilters: filters.stages,
    lastInteractionFilters: filters.lastInteraction,
    showHidden: filters.showHidden,
    userCompany,

    // Callbacks
    handleSearchTermChange,
    handleStageChange,
    handleToggleClientVisibility,
    handleToggleShowHidden,
    handlePageChange,
    onClientSelect,
    onSort,
    setEditingStageClientId: (id: string | null) => dispatch(setEditingStageClientId(id)),
    toggleLastInteractionFilter,
    toggleStageFilter,
  };
};
