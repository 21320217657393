import React, { useContext, useState, useEffect } from 'react';
import {
  useTheme,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Collapse,
  CircularProgress,
  Button,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { FundzContext } from './FundzContext';
import fundzService from '../../../services/fundzService';

function Row(props) {
  const { match } = props;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [detailedData, setDetailedData] = useState(null);
  const { selectedDate } = props;

  const handleExpandClick = async () => {
    if (!open) {
      setLoading(true);
      try {
        const response = await fundzService.getAllFundzData(match.eventId);
        if (response && response.status) {
          setDetailedData(response.data);
        }
      } catch (error) {
        console.error('Error fetching detailed data:', error);
      } finally {
        setLoading(false);
      }
    }
    setOpen(!open);
  };

  const formatAmount = (amount) => {
    if (!amount) return 'N/A';
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={handleExpandClick}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          {match.userName || 'N/A'}
        </TableCell>
        <TableCell>{match.contactName || 'N/A'}</TableCell>
        <TableCell>{match.contactCompany || 'N/A'}</TableCell>
        <TableCell>{match.userSequenceId}</TableCell>
        <TableCell>{match.onModel}</TableCell>
        <TableCell>{match.eventId}</TableCell>
        <TableCell>{formatAmount(match.fundingAmount)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {loading ? (
                <CircularProgress size={20} />
              ) : (
                detailedData && (
                  <>
                    {/* Add Post Details Section */}
                    <Box sx={{ p: 2 }}>
                      <Typography variant="h6" gutterBottom component="div">
                        Post Details
                      </Typography>
                      <Table size="small">
                        <TableBody>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Subject
                            </TableCell>
                            <TableCell>{detailedData.postDetails?.subject || 'N/A'}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Message
                            </TableCell>
                            <TableCell style={{ whiteSpace: 'pre-wrap' }}>
                              {detailedData.postDetails?.message || 'N/A'}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>
                    {/* Funding Details Section */}
                    <Box sx={{ p: 2 }}>
                      <Typography variant="h6" gutterBottom component="div">
                        Details
                      </Typography>
                      <Table size="small">
                        <TableBody>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Company
                            </TableCell>
                            <TableCell>{detailedData.eventDetails?.company || 'N/A'}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Money Raised
                            </TableCell>
                            <TableCell>{formatAmount(detailedData.eventDetails?.amount) || 'N/A'}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Currency
                            </TableCell>
                            <TableCell>{detailedData.eventDetails?.currency || 'N/A'}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Money Raised (USD)
                            </TableCell>
                            <TableCell>{formatAmount(detailedData.eventDetails?.moneyRaisedUSD) || 'N/A'}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Round Type
                            </TableCell>
                            <TableCell>{detailedData.eventDetails?.roundType || 'N/A'}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Description
                            </TableCell>
                            <TableCell>{detailedData.eventDetails?.companyDescription || 'N/A'}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Domain
                            </TableCell>
                            <TableCell>{detailedData.eventDetails?.domain || 'N/A'}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Industry
                            </TableCell>
                            <TableCell>{detailedData.eventDetails?.industry || 'N/A'}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Related Persons
                            </TableCell>
                            <TableCell>{detailedData.eventDetails?.relatedPersons || 'N/A'}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              LinkedIn
                            </TableCell>
                            <TableCell>{detailedData.eventDetails?.linkedIn || 'N/A'}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Location
                            </TableCell>
                            <TableCell>{detailedData.eventDetails?.location || 'N/A'}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Company UUID
                            </TableCell>
                            <TableCell>{detailedData.eventDetails?.companyUUID || 'N/A'}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Source
                            </TableCell>
                            <TableCell>
                              {detailedData.eventDetails?.source ? (
                                <a href={detailedData.eventDetails.source} target="_blank" rel="noopener noreferrer">
                                  {detailedData.eventDetails.source}
                                </a>
                              ) : (
                                'N/A'
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>

                    {/* Contact Details Section */}
                    <Box sx={{ p: 2 }}>
                      <Typography variant="h6" gutterBottom component="div">
                        Contact Details
                      </Typography>
                      <Table size="small">
                        <TableBody>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Contact ID
                            </TableCell>
                            <TableCell>{detailedData.contactId}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Title
                            </TableCell>
                            <TableCell>{detailedData.contactDetails?.title}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Name
                            </TableCell>
                            <TableCell>{detailedData.contactDetails?.name}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Company
                            </TableCell>
                            <TableCell>{detailedData.contactDetails?.company}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Email
                            </TableCell>
                            <TableCell>{detailedData.contactDetails?.primaryEmail}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row">
                              LinkedIn
                            </TableCell>
                            <TableCell>{detailedData.contactDetails?.linkedin_url}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>
                  </>
                )
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function FundzDataRecordMatches() {
  const [fundzMatches, setFundzMatches] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(dayjs());

  useEffect(() => {
    fetchSummary();
  }, [selectedDate]);

  const fetchSummary = async () => {
    setIsLoading(true);
    try {
      const eventListResponse = await fundzService.showMatchEvent(selectedDate.toDate());
      if (eventListResponse && eventListResponse.status) {
        const eventIds = eventListResponse.data;
        const summaryPromises = eventIds.map((eventId) => fundzService.getFundzSummary(eventId));
        const summaryResponses = await Promise.all(summaryPromises);
        const matches = summaryResponses.map((response) => response.data);
        setFundzMatches(matches);
      }
    } catch (error) {
      console.error('Error fetching summary:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ mb: 3 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Select Date"
            value={selectedDate}
            onChange={(newValue) => setSelectedDate(newValue)}
            sx={{ width: 250 }}
          />
        </LocalizationProvider>
      </Box>

      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : !fundzMatches || fundzMatches.length === 0 ? (
        <Typography variant="h6">No matches found for {selectedDate.format('MMMM D, YYYY')}</Typography>
      ) : (
        <>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Found {fundzMatches.length} Event Matches for {selectedDate.format('MMMM D, YYYY')}
          </Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="fundz matches table">
              <TableHead>
                <TableRow>
                  <TableCell>User</TableCell>
                  <TableCell>Contact Person</TableCell>
                  <TableCell>Company</TableCell>
                  <TableCell>Sequence ID</TableCell>
                  <TableCell>Event Type</TableCell>
                  <TableCell>Event ID</TableCell>
                  <TableCell>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fundzMatches.map((match, index) => (
                  <Row key={index} match={match} selectedDate={selectedDate} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  );
}
