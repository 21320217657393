import { useState, useEffect, useRef } from 'react';
import {
  DialogTitle,
  DialogContent,
  Button,
  Dialog,
  Typography,
  IconButton,
  Grid,
  Chip,
  CircularProgress,
  Alert,
  Avatar,
  Stack,
  Snackbar,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import Http from '@/http/httpClient';
import { getContactDetails } from '@/helper/apiHelper';
import PTextfield from '@/components/library/PTextfield';
import { ReactComponent as Back } from '@/assets/Icons/Arrow-Left.svg';
import { ReactComponent as Delete } from '@/assets/Icons/Delete.svg';
import featureFlagService from '@/services/featureFlagService';
import { refreshPosts } from '@/App';

export default function ManualListModal(props) {
  const dispatch = useDispatch();
  const featureFlags = useSelector((state) => state.auth.featureFlags);
  const authSliceSelectedDelegation = useSelector((state) => state.auth.selectedDelegation);
  const theme = useTheme();
  const { onClose, open, setListItem, handleListCreation, forSetup, contact, refreshListContacts, setupList } = props;

  const [savedContacts, setSavedContacts] = useState([]);

  const birthdayDate = contact && contact.birthday ? new Date(contact.birthday) : null;
  const anniversaryDate = contact && contact.anniversary ? new Date(contact.anniversary) : null;
  const contractRenewalDate = contact && contact.contractRenewalDate ? new Date(contact.contractRenewalDate) : null;
  const primaryEmail = contact ? contact.primaryEmail : '';
  const primaryPhone = contact ? contact.primaryPhoneNumber : '';
  const lastNameInputRef = useRef(null);
  const [firstName, setFirstName] = useState(contact?.first_name ?? '');
  const [lastName, setLastName] = useState(contact?.last_name ?? '');
  const emailInputRef = useRef(null);
  const [email, setEmail] = useState(primaryEmail);
  const phoneNumberInputRef = useRef(null);
  const [phoneNumber, setPhoneNumber] = useState(primaryPhone);
  const companyNameInputRef = useRef(null);
  const [companyName, setCompanyName] = useState(contact?.company ?? '');

  const roleInputRef = useRef(null);
  const [role, setRole] = useState(contact?.title ?? '');

  const linkedinInputRef = useRef(null);
  const [linkedin, setLinkedin] = useState(contact?.linkedin_url ?? '');

  const twitterInputRef = useRef(null);
  const [twitter, setTwitter] = useState(contact?.twitter_url ?? '');

  const monthInputRef = useRef(null);
  const [month, setMonth] = useState(birthdayDate ? (birthdayDate.getMonth() + 1).toString() : '');

  const dayInputRef = useRef(null);
  const [day, setDay] = useState(birthdayDate ? birthdayDate.getDate().toString() : '');

  const yearInputRef = useRef(null);
  const [year, setYear] = useState(birthdayDate ? birthdayDate.getFullYear().toString() : '');

  const anniversaryMonthInputRef = useRef(null);
  const [anniversaryMonth, setAnniversaryMonth] = useState(
    anniversaryDate ? (anniversaryDate.getMonth() + 1).toString() : '',
  );

  const anniversaryDayInputRef = useRef(null);
  const [anniversaryDay, setAnniversaryDay] = useState(anniversaryDate ? anniversaryDate.getDate().toString() : '');

  const anniversaryYearInputRef = useRef(null);
  const [anniversaryYear, setAnniversaryYear] = useState(
    anniversaryDate ? anniversaryDate.getFullYear().toString() : '',
  );

  const contractRenewalMonthInputRef = useRef(null);
  const [contractRenewalMonth, setContractRenewalMonth] = useState(
    contractRenewalDate ? (contractRenewalDate.getMonth() + 1).toString() : '',
  );

  const contractRenewalDayInputRef = useRef(null);
  const [contractRenewalDay, setContractRenewalDay] = useState(
    contractRenewalDate ? contractRenewalDate.getDate().toString() : '',
  );

  const contractRenewalYearInputRef = useRef(null);
  const [contractRenewalYear, setContractRenewalYear] = useState(
    contractRenewalDate ? contractRenewalDate.getFullYear().toString() : '',
  );

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [createList, setCreateList] = useState(false);
  const [isUpdateProgress, setIsUpdateProgress] = useState(false);

  useEffect(() => {
    const initialContactId = props?.contact?._id || props?.post?.sequenceAttr?.contactId;
    fetchContactDetails(initialContactId);
  }, []);

  useEffect(() => {
    if (showAlert) {
      const timeoutId = setTimeout(() => {
        setShowAlert(false);
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [showAlert]);

  const fetchContactDetails = async (id) => {
    if (id) {
      try {
        // Get the selected delegation ID from Redux
        const delegationAccessId = authSliceSelectedDelegation?.userId || null;

        // Pass the delegationAccessId to getContactDetails if it exists
        const fetchedDetails = await getContactDetails(id, delegationAccessId);
        if (fetchedDetails) {
          setFirstName(fetchedDetails.first_name || '');
          setLastName(fetchedDetails.last_name || '');
          setEmail(fetchedDetails.primaryEmail || '');
          setPhoneNumber(fetchedDetails.primaryPhoneNumber || '');
          setCompanyName(fetchedDetails.company || '');
          setRole(fetchedDetails.title || '');
          setLinkedin(fetchedDetails.linkedin_url || '');
          setTwitter(fetchedDetails.twitter_url || '');

          const formatWithLeadingZero = (value) => (value < 10 ? `0${value}` : value.toString());

          if (fetchedDetails.birthday) {
            const birthday = new Date(fetchedDetails.birthday);
            setMonth(birthday ? formatWithLeadingZero(birthday.getMonth() + 1) : '');
            setDay(birthday ? formatWithLeadingZero(birthday.getDate()) : '');
            setYear(birthday ? birthday.getFullYear().toString() : '');
          }

          if (fetchedDetails.anniversary) {
            const anniversary = new Date(fetchedDetails.anniversary);
            setAnniversaryMonth(formatWithLeadingZero(anniversary.getMonth() + 1));
            setAnniversaryDay(formatWithLeadingZero(anniversary.getDate()));
            setAnniversaryYear(anniversary.getFullYear().toString());
          } else {
            setAnniversaryMonth('');
            setAnniversaryDay('');
            setAnniversaryYear('');
          }

          if (fetchedDetails.contractRenewalDate) {
            const contractRenewalDate = new Date(fetchedDetails.contractRenewalDate);
            setContractRenewalMonth(
              contractRenewalDate ? formatWithLeadingZero(contractRenewalDate.getMonth() + 1) : '',
            );
            setContractRenewalDay(contractRenewalDate ? formatWithLeadingZero(contractRenewalDate.getDate()) : '');
            setContractRenewalYear(contractRenewalDate ? contractRenewalDate.getFullYear().toString() : '');
          } else {
            setContractRenewalMonth('');
            setContractRenewalDay('');
            setContractRenewalYear('');
          }
        }
      } catch (error) {
        console.error('Failed to fetch contact details:', error);
      }
    }
  };

  const resetFields = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhoneNumber('');
    setCompanyName('');
    setRole('');
    setLinkedin('');
    setTwitter('');
    setMonth('');
    setDay('');
    setYear('');
    setAnniversaryMonth('');
    setAnniversaryDay('');
    setAnniversaryYear('');
    setContractRenewalMonth('');
    setContractRenewalDay('');
    setContractRenewalYear('');
  };

  const validatedContacts = (forContinue = false, resetValues = true) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    let resContacts = null;

    if (
      firstName &&
      lastName &&
      // Temporarily removing email as a required field
      (!email || emailRegex.test(email))
    ) {
      const isMonthEmpty = !month.trim();
      const isDayEmpty = !day.trim();
      const isYearEmpty = !year.trim();
      let birthday = null;

      const isAnniversaryMonthEmpty = !anniversaryMonth.trim();
      const isAnniversaryDayEmpty = !anniversaryDay.trim();
      const isAnniversaryYearEmpty = !anniversaryYear.trim();
      let anniversary = null;

      const isContractRenewalMonthEmpty = !contractRenewalMonth.trim();
      const isContractRenewalDayEmpty = !contractRenewalDay.trim();
      const isContractRenewalYearEmpty = !contractRenewalYear.trim();
      let contractRenewal = null;

      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (month && year && day) {
        birthday = parseDateValue(month, day, year);
        if (!birthday || birthday.getTime() > today.getTime()) {
          setAlertMessage('Please provide valid birthday date.');
          setShowAlert(true);
          return;
        }
      } else {
        if (!isMonthEmpty || !isDayEmpty || !isYearEmpty) {
          setAlertMessage('Please fill in all birthday fields.');
          setShowAlert(true);
          return;
        }
      }

      if (anniversaryMonth && anniversaryDay && anniversaryYear) {
        anniversary = parseDateValue(anniversaryMonth, anniversaryDay, anniversaryYear);
        if (!anniversary) {
          setAlertMessage('Please provide valid anniversary date.');
          setShowAlert(true);
          return;
        }
      } else {
        if (!isAnniversaryMonthEmpty || !isAnniversaryDayEmpty || !isAnniversaryYearEmpty) {
          setAlertMessage('Please fill in all anniversary fields.');
          setShowAlert(true);
          return;
        }
      }

      if (contractRenewalMonth && contractRenewalDay && contractRenewalYear) {
        contractRenewal = parseDateValue(contractRenewalMonth, contractRenewalDay, contractRenewalYear);

        if (!contractRenewal) {
          setAlertMessage('Please provide valid contract renewal date.');
          setShowAlert(true);
          return;
        } else {
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          contractRenewal.setHours(23, 59, 59, 999);
          if (contractRenewal.getTime() < today.getTime()) {
            setAlertMessage('Please provide valid contract renewal date.');
            setShowAlert(true);
            return;
          }
        }
      } else {
        if (!isContractRenewalMonthEmpty || !isContractRenewalDayEmpty || !isContractRenewalYearEmpty) {
          setAlertMessage('Please fill in all contract renewal fields.');
          setShowAlert(true);
          return;
        }
      }

      const newContact = {
        first_name: firstName,
        last_name: lastName,
        linkedin_url: linkedin,
        twitter_url: twitter,
        company: companyName,
        birthday: birthday ?? null,
        anniversary: anniversary ?? null,
        contractRenewal: contractRenewal ?? null,
        title: role,
        email,
        phoneNumber,
      };
      resContacts = [...savedContacts, newContact];
      setSavedContacts(resContacts);

      if (resetValues) {
        resetFields();
      }
    } else {
      if (
        forContinue &&
        !firstName.length &&
        !lastName.length
        // Temporarily removing email as a required field
        // && !email.length
      ) {
        resContacts = savedContacts.length > 0 ? [...savedContacts] : null;
      } else {
        setAlertMessage(
          // Temporarily removing email as a required field
          email && !emailRegex.test(email)
            ? 'Please enter a valid email address.'
            : 'Please fill in all required fields.',
        );
        setShowAlert(true);
      }
    }

    return resContacts;
  };

  const handleSaveAndAddAnother = () => {
    validatedContacts();
  };

  const handleDeleteContact = (index) => {
    const updatedContacts = [...savedContacts];
    updatedContacts.splice(index, 1);
    setSavedContacts(updatedContacts);
  };

  const handleClose = () => {
    //Need to save selection here
    onClose();
  };

  const handleContinue = async () => {
    const updatedContacts = validatedContacts(true);
    if (!updatedContacts) {
      return;
    }

    setSavedContacts(updatedContacts);
    setListItem((prev) => {
      const providers = (prev.providers || []).slice();
      const index = providers.findIndex((provider) => provider.providerType === 'ManualContact');
      if (index !== -1) {
        providers[index] = {
          ...providers[index],
          provider: 'manual',
          isExpanded: false,
        };
      }

      return {
        ...prev,
        providers: providers,
        provider: 'manual',
        selectedContacts: updatedContacts,
      };
    });

    setCreateList(true);
  };

  const delegationAccessId = useSelector((state) => state.auth.delegationAccessId);

  const handleSave = async () => {
    const contacts = validatedContacts(true, false);
    if (!contacts) {
      return;
    }

    setIsUpdateProgress(true);
    try {
      const response = await Http.postData('updateContacts', {
        contactDetails: { id: contact._id, ...contacts[0] },
        delegationAccessId: delegationAccessId || undefined,
      });
      if (response && response.status === true) {
        resetFields();
        handleClose();
        refreshListContacts();
        refreshPosts(dispatch, authSliceSelectedDelegation?.userId);
      } else {
        setAlertMessage('Contact save failed.');
        setShowAlert(true);
        setSavedContacts([]);
      }
    } catch (e) {
      console.error(e);
      setAlertMessage('Failed to save the contact.');
      setShowAlert(true);
      setSavedContacts([]);
    } finally {
      setIsUpdateProgress(false);
    }
  };

  const parseDateValue = (month, day, year) => {
    const parsedMonth = parseInt(month, 10);
    const parsedDay = parseInt(day, 10);
    const parsedYear = parseInt(year, 10);

    if (
      isNaN(parsedMonth) ||
      isNaN(parsedDay) ||
      isNaN(parsedYear) ||
      parsedMonth < 1 ||
      parsedMonth > 12 ||
      parsedDay < 1 ||
      parsedDay > 31 ||
      parsedYear < 1000 ||
      parsedYear > 9999
    ) {
      return null;
    }

    const date = new Date(parsedYear, parsedMonth - 1, parsedDay);
    // validating like if we give 31 Feb
    if (
      date &&
      date.getFullYear() === parsedYear &&
      date.getMonth() + 1 === parsedMonth &&
      date.getDate() === parsedDay
    ) {
      return date;
    }

    return null;
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (createList) {
      setIsUpdateProgress(true);
      handleListCreation()
        .then(() => {
          setIsUpdateProgress(false);
          onClose();
        })
        .catch((error) => {
          console.log(error);
        });

      setCreateList(false);
    }
  }, [createList]);

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
  };

  // NOTE: this is just temporary for surfacing some recent interactions data
  const showLastInteractionDetails = () => {
    return featureFlagService?.isFeatureEnabled('lastInteraction.preview', featureFlags);
  };
  const showMostRecentInteractionDetails = (contact) => {
    if (!contact?.mostRecentInteractions?.mostRecent?.when) return 'Unknown';

    const when = new Date(Date.parse(contact.mostRecentInteractions.mostRecent.when)).toString();
    const whenStr = `on ${moment(when).format('DD MMM YYYY')}`;
    return `Via ${contact.mostRecentInteractions.mostRecent.source} ${whenStr}`;
  };

  const showMostRecentCommunicationDetails = (contact) => {
    if (!contact?.mostRecentCommunications?.mostRecent?.when) return 'Unknown';

    const when = new Date(Date.parse(contact.mostRecentCommunications.mostRecent.when)).toString();
    const sentReceivedStr = contact.mostRecentCommunications.mostRecent.direction === 'incoming' ? 'Received' : 'Sent';
    const whenStr = `on ${moment(when).format('DD MMM YYYY')}`;
    return `${sentReceivedStr} an ${contact.mostRecentCommunications.mostRecent.source} ${whenStr}`;
  };

  return (
    <>
      <Dialog onClose={handleClose} open={open} fullScreen={forSetup ? true : false} maxWidth={'lg'} sx={{ px: 2 }}>
        {alertMessage && (
          <Snackbar open={showAlert} autoHideDuration={6000} onClose={handleCloseAlert}>
            <Alert onClose={handleCloseAlert} severity="error" sx={{ width: '100%' }}>
              {alertMessage}
            </Alert>
          </Snackbar>
        )}
        <DialogTitle>
          <Grid container alignItems="flex-start" justifyContent="space-between">
            <Grid item>
              <IconButton aria-label="close" onClick={handleClose}>
                {forSetup === true ? (
                  <Back fill={theme.palette.primary.grey} />
                ) : (
                  <Delete fill={theme.palette.primary.grey} />
                )}
              </IconButton>
            </Grid>
            {forSetup && (
              <Grid item>
                <Stack direction="row" spacing={10} sx={{ my: 1.5 }}>
                  {setupList?.map((item) => (
                    <Stack justifyContent="center" alignItems="center">
                      <Avatar
                        sx={{
                          fontSize: '12px',
                          fontWeight: 500,
                          bgcolor: item.active ? theme.palette.primary.blue : theme.palette.primary.white,
                          color: item.active ? theme.palette.primary.white : theme.palette.primary.grey,
                          border: item.active ? `none` : `1px solid #E0E0E0`,
                          my: 1,
                        }}
                      >
                        {item.index}
                      </Avatar>
                      <Typography
                        sx={{
                          color: item.active ? theme.palette.primary.black : theme.palette.primary.grey,
                          fontWeight: 500,
                        }}
                      >
                        {item.listText}
                      </Typography>
                    </Stack>
                  ))}
                </Stack>
              </Grid>
            )}
            <Grid item>
              {forSetup === true ? (
                <>
                  <Button
                    onClick={handleSaveAndAddAnother}
                    sx={{
                      'maxWidth': '200px',
                      'backgroundColor': theme.palette.primary.lightBlue,
                      'color': theme.palette.primary.blue,
                      'textTransform': 'none',
                      'width': '167px',
                      'py': 1,
                      'px': 2,
                      'mx': 2,
                      'whiteSpace': 'nowrap',
                      'overflow': 'hidden',
                      'textOverflow': 'ellipsis',
                      'borderRadius': 2,
                      'boxShadow': '0px 4px 8px 0px #2C64E31F',
                      '&:hover': {
                        backgroundColor: theme.palette.primary.white,
                        color: theme.palette.primary.blue,
                      },
                    }}
                  >
                    Save & add another
                  </Button>
                  <Button
                    onClick={handleContinue}
                    sx={{
                      'maxWidth': '200px',
                      'backgroundColor': theme.palette.primary.blue,
                      'color': theme.palette.primary.white,
                      'textTransform': 'none',
                      'py': 1,
                      'px': 2,
                      'whiteSpace': 'nowrap',
                      'overflow': 'hidden',
                      'textOverflow': 'ellipsis',
                      'borderRadius': 2,
                      'boxShadow': '0px 4px 8px 0px #2C64E31F',
                      '&:hover': {
                        backgroundColor: alpha(theme.palette.primary.blue, 0.8),
                      },
                      'width': { xs: '80px', md: '167px' },
                    }}
                  >
                    {isUpdateProgress ? <CircularProgress size={24} color="inherit" /> : 'Continue'}
                  </Button>
                </>
              ) : (
                !authSliceSelectedDelegation?.userId && (
                  <Button
                    onClick={handleSave}
                    sx={{
                      'maxWidth': '200px',
                      'backgroundColor': theme.palette.primary.blue,
                      'color': theme.palette.primary.white,
                      'textTransform': 'none',
                      'width': '167px',
                      'py': 1,
                      'px': 2,
                      'whiteSpace': 'nowrap',
                      'overflow': 'hidden',
                      'textOverflow': 'ellipsis',
                      'borderRadius': 2,
                      'boxShadow': '0px 4px 8px 0px #2C64E31F',
                      '&:hover': {
                        backgroundColor: theme.palette.primary.blue,
                        color: theme.palette.primary.white,
                      },
                    }}
                  >
                    {isUpdateProgress ? <CircularProgress size={24} color="inherit" /> : 'Save'}
                  </Button>
                )
              )}
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: theme.palette.primary.white }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={3} />
            <Grid item xs={12} lg={6}>
              {forSetup ? (
                <>
                  <Typography
                    sx={{
                      fontSize: '32px',
                      fontWeight: 700,
                      color: theme.palette.primary.black,
                      pt: 5,
                      lineHeight: 'normal',
                    }}
                  >
                    Add professionals manually
                  </Typography>
                  <Typography sx={{ fontSize: '14px', fontWeight: 500, color: theme.palette.primary.grey, pb: 4 }}>
                    This will allow you to create a list of people to target.
                  </Typography>

                  {savedContacts.map((contact, index) => (
                    <Chip
                      key={index}
                      label={`${contact.first_name} ${contact.last_name}`}
                      onDelete={() => handleDeleteContact(index)}
                      deleteIcon={<CloseIcon style={{ color: theme.palette.primary.grey }} />}
                      sx={{
                        'mr': 1,
                        'color': theme.palette.primary.grey,
                        'backgroundColor': theme.palette.primary.lightBlue,
                        '& .MuiChip-deleteIcon': {
                          color: theme.palette.primary.grey,
                        },
                      }}
                    />
                  ))}
                </>
              ) : (
                <Typography sx={{ fontSize: '32px', fontWeight: 700, color: theme.palette.primary.black, pt: 2 }}>
                  {firstName + ' ' + lastName}
                </Typography>
              )}

              <Grid container spacing={1} sx={{ mt: 1 }}>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 500,
                      color: theme.palette.primary.black,
                    }}
                  >
                    Name <span style={{ color: theme.palette.primary.orange, marginLeft: '4px' }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={6} paddingLeft="0px !important" marginBottom="16px">
                  <PTextfield
                    type="text"
                    value={firstName}
                    placeholder="First"
                    onChange={(e) => setFirstName(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </Grid>

                <Grid item xs={6} paddingLeft="0px !important" marginBottom="16px">
                  <PTextfield
                    ref={lastNameInputRef}
                    type="text"
                    value={lastName}
                    placeholder="Last"
                    onChange={(e) => setLastName(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 500,
                      color: theme.palette.primary.black,
                    }}
                  >
                    Email
                    {/* Temporarily removing email as required field 
                  {' '}<span style={{ color: theme.palette.primary.orange, marginLeft: '4px' }}>*</span>
                  */}
                  </Typography>
                </Grid>
                <Grid item xs={12} paddingLeft="0px !important" marginBottom="16px">
                  <PTextfield
                    ref={emailInputRef}
                    type="text"
                    value={email}
                    placeholder="Email address"
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 500,
                      color: theme.palette.primary.black,
                    }}
                  >
                    Phone Number
                  </Typography>
                </Grid>
                <Grid item xs={12} paddingLeft="0px !important" marginBottom="16px">
                  <PTextfield
                    ref={phoneNumberInputRef}
                    type="text"
                    value={phoneNumber}
                    placeholder="Phone number"
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 500,
                      color: theme.palette.primary.black,
                    }}
                  >
                    Company & Role
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: 500,
                      color: theme.palette.primary.grey,
                      alignSelf: 'flex-end',
                    }}
                  >
                    Optional
                  </Typography>
                </Grid>
                <Grid item xs={6} paddingLeft="0px !important" marginBottom="16px">
                  <PTextfield
                    ref={companyNameInputRef}
                    type="text"
                    value={companyName}
                    placeholder="Company Name"
                    onChange={(e) => setCompanyName(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </Grid>
                <Grid item xs={6} paddingLeft="0px !important" marginBottom="16px">
                  <PTextfield
                    ref={roleInputRef}
                    type="text"
                    value={role}
                    placeholder="Role"
                    onChange={(e) => setRole(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 500,
                      color: theme.palette.primary.black,
                    }}
                  >
                    Social
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: 500,
                      color: theme.palette.primary.grey,
                      alignSelf: 'flex-end',
                    }}
                  >
                    Optional
                  </Typography>
                </Grid>
                <Grid item xs={6} paddingLeft="0px !important" marginBottom="16px">
                  <PTextfield
                    ref={linkedinInputRef}
                    type="text"
                    value={linkedin}
                    placeholder="LinkedIn profile URL"
                    onChange={(e) => setLinkedin(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </Grid>
                <Grid item xs={6} paddingLeft="0px !important" marginBottom="16px">
                  <PTextfield
                    ref={twitterInputRef}
                    type="text"
                    value={twitter}
                    placeholder="X (Twitter) handle"
                    onChange={(e) => setTwitter(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 500,
                      color: theme.palette.primary.black,
                    }}
                  >
                    Birthday
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: 500,
                      color: theme.palette.primary.grey,
                      alignSelf: 'flex-end',
                    }}
                  >
                    Optional
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex' }}>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: 500,
                      color: theme.palette.primary.grey,
                      alignSelf: 'flex-end',
                    }}
                  >
                    Send messages or gifts on their birthday
                  </Typography>
                </Grid>
                <Grid item xs={4} paddingLeft="0px !important" marginBottom="16px">
                  <PTextfield
                    ref={monthInputRef}
                    type="text"
                    value={month}
                    placeholder="MM"
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (/^\d*$/.test(inputValue) && inputValue.length <= 2) {
                        setMonth(inputValue);
                      }
                    }}
                    onKeyDown={handleKeyDown}
                  />
                </Grid>
                <Grid item xs={4} paddingLeft="0px !important" marginBottom="16px">
                  <PTextfield
                    ref={dayInputRef}
                    type="text"
                    value={day}
                    placeholder="DD"
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (/^\d*$/.test(inputValue) && inputValue.length <= 2) {
                        setDay(inputValue);
                      }
                    }}
                    onKeyDown={handleKeyDown}
                  />
                </Grid>
                <Grid item xs={4} paddingLeft="0px !important" marginBottom="16px">
                  <PTextfield
                    ref={yearInputRef}
                    type="text"
                    value={year}
                    placeholder="YYYY"
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (/^\d*$/.test(inputValue) && inputValue.length <= 4) {
                        setYear(inputValue);
                      }
                    }}
                    onKeyDown={handleKeyDown}
                  />
                </Grid>

                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 500,
                      color: theme.palette.primary.black,
                    }}
                  >
                    Anniversary
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: 500,
                      color: theme.palette.primary.grey,
                      alignSelf: 'flex-end',
                    }}
                  >
                    Optional
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex' }}>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: 500,
                      color: theme.palette.primary.grey,
                      alignSelf: 'flex-end',
                    }}
                  >
                    Send messages or gifts on their anniversary
                  </Typography>
                </Grid>
                <Grid item xs={4} paddingLeft="0px !important" marginBottom="16px">
                  <PTextfield
                    ref={anniversaryMonthInputRef}
                    type="text"
                    value={anniversaryMonth}
                    placeholder="MM"
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (/^\d*$/.test(inputValue) && inputValue.length <= 2) {
                        setAnniversaryMonth(inputValue);
                      }
                    }}
                    onKeyDown={handleKeyDown}
                  />
                </Grid>
                <Grid item xs={4} paddingLeft="0px !important" marginBottom="16px">
                  <PTextfield
                    ref={anniversaryDayInputRef}
                    type="text"
                    value={anniversaryDay}
                    placeholder="DD"
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (/^\d*$/.test(inputValue) && inputValue.length <= 2) {
                        setAnniversaryDay(inputValue);
                      }
                    }}
                    onKeyDown={handleKeyDown}
                  />
                </Grid>
                <Grid item xs={4} paddingLeft="0px !important" marginBottom="16px">
                  <PTextfield
                    ref={anniversaryYearInputRef}
                    type="text"
                    value={anniversaryYear}
                    placeholder="YYYY"
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (/^\d*$/.test(inputValue) && inputValue.length <= 4) {
                        setAnniversaryYear(inputValue);
                      }
                    }}
                    onKeyDown={handleKeyDown}
                  />
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 500,
                      color: theme.palette.primary.black,
                    }}
                  >
                    Contract Renewal Date
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: 500,
                      color: theme.palette.primary.grey,
                      alignSelf: 'flex-end',
                    }}
                  >
                    Optional
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex' }}>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: 500,
                      color: theme.palette.primary.grey,
                      alignSelf: 'flex-end',
                    }}
                  >
                    Send messages or gifts on their contract renewal
                  </Typography>
                </Grid>
                <Grid item xs={4} paddingLeft="0px !important" marginBottom="16px">
                  <PTextfield
                    ref={contractRenewalMonthInputRef}
                    type="text"
                    value={contractRenewalMonth}
                    placeholder="MM"
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (/^\d*$/.test(inputValue) && inputValue.length <= 2) {
                        setContractRenewalMonth(inputValue);
                      }
                    }}
                    onKeyDown={handleKeyDown}
                  />
                </Grid>
                <Grid item xs={4} paddingLeft="0px !important" marginBottom="16px">
                  <PTextfield
                    ref={contractRenewalDayInputRef}
                    type="text"
                    value={contractRenewalDay}
                    placeholder="DD"
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (/^\d*$/.test(inputValue) && inputValue.length <= 2) {
                        setContractRenewalDay(inputValue);
                      }
                    }}
                    onKeyDown={handleKeyDown}
                  />
                </Grid>
                <Grid item xs={4} paddingLeft="0px !important" marginBottom="16px">
                  <PTextfield
                    ref={contractRenewalYearInputRef}
                    type="text"
                    value={contractRenewalYear}
                    placeholder="YYYY"
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (/^\d*$/.test(inputValue) && inputValue.length <= 4) {
                        setContractRenewalYear(inputValue);
                      }
                    }}
                    onKeyDown={handleKeyDown}
                  />
                </Grid>

                {showLastInteractionDetails() && (
                  <>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontWeight: 500,
                          color: theme.palette.primary.black,
                        }}
                      >
                        Last Contacted
                      </Typography>
                      {/* <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: 500,
                      color: theme.palette.primary.grey,
                      alignSelf: 'flex-end',
                    }}>
                    Optional
                  </Typography> */}
                    </Grid>
                    {/* <Grid item xs={12} sx={{ display: 'flex' }}>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: 500,
                      color: theme.palette.primary.grey,
                      alignSelf: 'flex-end',
                    }}>
                    Your last known interaction with this contact.
                  </Typography>
                </Grid> */}
                    <Grid item xs={12} sx={{ display: 'flex' }}>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          fontWeight: 500,
                          color: theme.palette.primary.black,
                          alignSelf: 'flex-end',
                        }}
                      >
                        {showMostRecentCommunicationDetails(contact)}
                      </Typography>
                    </Grid>
                  </>
                )}

                {showLastInteractionDetails() && (
                  <>
                    <Grid item xs={3} />
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontWeight: 500,
                          color: theme.palette.primary.black,
                        }}
                      >
                        Last Known Interaction
                      </Typography>
                      {/* <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: 500,
                      color: theme.palette.primary.grey,
                      alignSelf: 'flex-end',
                    }}>
                    Optional
                  </Typography> */}
                    </Grid>
                    {/* <Grid item xs={12} sx={{ display: 'flex' }}>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: 500,
                      color: theme.palette.primary.grey,
                      alignSelf: 'flex-end',
                    }}>
                    Your last known interaction with this contact.
                  </Typography>
                </Grid> */}
                    <Grid item xs={12} sx={{ display: 'flex' }}>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          fontWeight: 500,
                          color: theme.palette.primary.black,
                          alignSelf: 'flex-end',
                        }}
                      >
                        {showMostRecentInteractionDetails(contact)}
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid item xs={3} />
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
