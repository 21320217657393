const { IceSheet } = require('../utils/objectUtils');

const INTERACTION_PLATFORM = new IceSheet({
  CALENDAR: 'calendar',
  EMAIL: 'email',
  LINKEDIN: 'linkedin',
  NONE: 'none',
  SOCIAL: 'social',
  SMS: 'sms',
});

const INTERACTION_TRAFFIC_DIRECTION = Object.freeze({
  INCOMING: 'incoming',
  OUTGOING: 'outgoing',
  INTERNAL: 'internal',
});

const INTERACTION_TYPE = new IceSheet({
  INTERNAL_ADDED_CONTACT: 'added_contact',
  CALENDAR_INCOMING: 'calendar_incoming',
  CALENDAR_OUTGOING: 'calendar_outgoing',
  EMAIL_INCOMING: 'email_incoming',
  EMAIL_OUTGOING: 'email_outgoing',
  LINKEDIN_INCOMING: 'linkedin_incoming',
  LINKEDIN_OUTGOING: 'linkedin_outgoing',
});

module.exports = Object.freeze({
  INTERACTION_TYPE,
  INTERACTION_PLATFORM,
  INTERACTION_TRAFFIC_DIRECTION,
});
