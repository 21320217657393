import React, { useRef } from 'react';
import { ButtonGroup } from '@mui/material';
import PButton from '@Library/PButton';
import PToolTip from '@Library/PToolTip';
import ClearIcon from '@mui/icons-material/Clear';
import { CheckCircle } from 'lucide-react';
import { ReactComponent as CheckIcon } from '@Assets/Icons/Check.svg';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import SendIcon from '@mui/icons-material/Send';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
const options = {
  recommendApprove: 'Recommend Approve',
  recommendRemove: 'Recommend Remove',
};

const PMultiRecommendButton = ({ onRecommendApprove, onRecommendRemove, recommendation, pVariant }) => {
  const anchorRef = useRef(null);
  return (
    <ButtonGroup ref={anchorRef} aria-label="recommend button group">
      <PToolTip title={options.recommendRemove} pVariant="black" placement="bottom">
        <PButton
          pVariant="red"
          onClick={onRecommendRemove}
          Icon={CancelScheduleSendIcon}
          iconProps={{ sx: { fontSize: '15px' } }}
          isIconicButton
        />
      </PToolTip>
      <PToolTip title={options.recommendApprove} pVariant="black" placement="bottom">
        <PButton
          pVariant="green"
          onClick={onRecommendApprove}
          Icon={TaskAltIcon}
          iconProps={{ sx: { fontSize: '15px' } }}
          isIconicButton
        />
      </PToolTip>
    </ButtonGroup>
  );
};

export default PMultiRecommendButton;
