import React, { useState } from 'react';
import { Button, TextField, Box, Grid } from '@mui/material';

// import Http from '../http/httpClient';
import Http from '../../../http/httpClient';

function FundingForm() {
  const [funding, setFunding] = useState({
    company: 'Quanta',
    moneyRaised: '60000000',
    currency: 'USD',
    moneyRaisedUSD: '60000000',
    roundType: 'Late Stage Venture',
    companyDescription: 'Quanta develops an advanced haemodialysis systems for use in the home and clinic.',
    domain: 'http://www.quantadt.com',
    industry: 'Medical Device',
    relatedPersons: 'b2venture, Glenview Capital Management, Novo Holdings',
    linkedIn: 'http://www.quantadt.com',
    location: 'Alcester, Warwickshire, United Kingdom, Europe',
    companyUUID: '411749120',
    date: new Date(),
  });

  const handleChange = (event) => {
    setFunding({
      ...funding,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Call API to save funding
    try {
      const response = await Http.postData('fundings/admin/create', {
        funding,
      });
      if (response && response.status) {
        console.log(response);
      }
    } catch (error) {
      console.error(error);
    }
    console.log(funding);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <TextField name="company" placeholder="Company Name" value={funding.company} onChange={handleChange} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="moneyRaised"
            placeholder="Money Raised"
            value={funding.moneyRaised}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name="currency" placeholder="Currency" value={funding.currency} onChange={handleChange} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="moneyRaisedUSD"
            placeholder="Money Raised USD"
            value={funding.moneyRaisedUSD}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name="roundType" placeholder="Round Type" value={funding.roundType} onChange={handleChange} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="companyDescription"
            placeholder="Company Description"
            value={funding.companyDescription}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name="domain" placeholder="Domain" value={funding.domain} onChange={handleChange} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name="industry" placeholder="Industry" value={funding.industry} onChange={handleChange} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="relatedPersons"
            placeholder="Related Persons"
            value={funding.relatedPersons}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name="linkedIn" placeholder="LinkedIn" value={funding.linkedIn} onChange={handleChange} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name="location" placeholder="Location" value={funding.location} onChange={handleChange} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="companyUUID"
            placeholder="Company UUID"
            value={funding.companyUUID}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Box sx={{ mt: 2 }}>
        <Button type="submit" variant="contained" color="primary">
          Create Funding
        </Button>
      </Box>
    </Box>
  );
}

export default FundingForm;
