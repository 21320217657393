import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import type { Middleware } from 'redux';
import {
  getHighestCompanyClientRelationshipScore,
  getHighestCompanyClientContactRelationshipScore,
  getCompanyClients,
  updateCompanyClientStage,
  setCompanyClientVisibilityForUser,
  getErmStages,
  updateErmStages,
} from '@/helper/apiHelper';
import { debounce } from 'lodash'; // Import debounce utility
import { Stage } from '@/v0/ERMDashboard/components/types';
import { INTERACTION_FILTER_OPTIONS } from '@postilize/shared/constants/ERM';
import { setPipelineStages } from './authSlice'; // Import the action
import { NO_STAGE_DEFINITION } from '@postilize/shared/constants/ERM';

const SEARCH_TERM_DEBOUNCE_DELAY_MS = 300;

/*****************************************************************************************
 *                                   Utility Functions                                   *
 *****************************************************************************************/

// Initializes filter options by setting each option to true by default
const initializeFilters = (options: readonly string[]) =>
  options.reduce<Record<string, boolean>>((acc, option) => {
    acc[option] = true;
    return acc;
  }, {});

const initializeStageFilters = (stages: Stage[]) => {
  const stageNames = stages.map((stage) => stage.name);
  const allStages = [...stageNames, NO_STAGE_DEFINITION.name];
  return initializeFilters(allStages);
};

const buildSubmittableQueryParams = (clientTableState) => {
  const { filters } = clientTableState.queryParams;

  // Extract the selected stages based on filters
  const selectedStages = Object.keys(filters.stages).filter((key) => filters.stages[key]);

  // Extract the selected interaction types based on filters
  const selectedInteractionLabels = Object.keys(filters.lastInteraction).filter((key) => filters.lastInteraction[key]);

  // Map interaction labels to their corresponding types (assuming mapping is defined elsewhere)
  const filterLatestInteractionType = selectedInteractionLabels.flatMap(
    (label) => INTERACTION_FILTER_OPTIONS[label as keyof typeof INTERACTION_FILTER_OPTIONS] || [],
  );

  return {
    ...clientTableState.queryParams,
    filters: { ...filters, stages: selectedStages, lastInteraction: filterLatestInteractionType },
  };
};

const debouncedFetchClients = debounce((dispatch) => {
  dispatch(fetchClients());
}, SEARCH_TERM_DEBOUNCE_DELAY_MS); // Adjust the delay as needed

/*****************************************************************************************
 *                              Validation Utility Functions                             *
 *****************************************************************************************/

// Utility function to validate and fix filter options
const validateAndFixFilterOptions = (filterOptions, availableOptions) => {
  const validFilterOptions = initializeFilters(availableOptions);

  if (typeof filterOptions !== 'object' || filterOptions === null) {
    return validFilterOptions;
  }

  for (const option of availableOptions) {
    if (typeof filterOptions[option] === 'boolean') {
      validFilterOptions[option] = filterOptions[option];
    }
  }
  return validFilterOptions;
};

// Utility function to validate and fix sort criteria
const validateAndFixSortCriteria = (sortCriteria, defaultSortCriteria) => {
  const validatedSortCriteria = { ...defaultSortCriteria };

  if (typeof sortCriteria?.key === 'string') {
    validatedSortCriteria.key = sortCriteria.key;
  }
  if (sortCriteria?.direction === 'asc' || sortCriteria?.direction === 'desc') {
    validatedSortCriteria.direction = sortCriteria.direction;
  }
  return validatedSortCriteria;
};

// Utility function to validate and fix query parameters
const validateAndFixQueryParams = (queryParams, defaultQueryParams) => {
  const validatedQueryParams = { ...defaultQueryParams };

  if (typeof queryParams === 'object' && queryParams !== null) {
    // Validate filters
    if (typeof queryParams.filters === 'object' && queryParams.filters !== null) {
      validatedQueryParams.filters = { ...defaultQueryParams.filters };

      // Validate searchTerm
      if (typeof queryParams.filters.searchTerm === 'string') {
        validatedQueryParams.filters.searchTerm = queryParams.filters.searchTerm;
      }
      // Validate lastInteraction using keys from INTERACTION_FILTER_OPTIONS
      validatedQueryParams.filters.lastInteraction = validateAndFixFilterOptions(
        queryParams.filters.lastInteraction,
        Object.keys(INTERACTION_FILTER_OPTIONS),
      );

      // Validate showHidden
      if (typeof queryParams.filters.showHidden === 'boolean') {
        validatedQueryParams.filters.showHidden = queryParams.filters.showHidden;
      }
    }

    // Validate sortCriteria
    if (typeof queryParams.sortCriteria === 'object' && queryParams.sortCriteria !== null) {
      validatedQueryParams.sortCriteria = validateAndFixSortCriteria(
        queryParams.sortCriteria,
        defaultQueryParams.sortCriteria,
      );
    }

    // Validate page and limit
    if (typeof queryParams.page === 'number') {
      validatedQueryParams.page = queryParams.page;
    }
    if (typeof queryParams.limit === 'number') {
      validatedQueryParams.limit = queryParams.limit;
    }
  }

  return validatedQueryParams;
};

/*****************************************************************************************
 *                                 State initialization                                  *
 *****************************************************************************************/

// Assuming initialStages are available here or fetched synchronously.
// If not, you might need to dispatch an action to fetch stages before initializing.
const initialStages: Stage[] = []; // Replace with actual stages or mock data if necessary.

const defaultErmState = {
  selectedClient: {},

  clientTable: {
    data: {
      cacheByPage: {} as Record<number, any[]>,
      total: 0,
      highestRelationshipScore: null as number | null,
    },
    queryParams: {
      page: 1,
      limit: 10,
      filters: {
        searchTerm: '',
        stages: initializeStageFilters(initialStages),
        lastInteraction: initializeFilters(Object.keys(INTERACTION_FILTER_OPTIONS)),
        showHidden: false,
      },
      sortCriteria: { key: 'relationshipScore', direction: 'desc' as 'asc' | 'desc' },
    },
    loading: {
      loadingStateByPage: { 1: { loading: false, error: null } } as Record<
        number,
        { loading: boolean; error: string | null }
      >,
      stageManagement: { loading: false, error: null },
    },
    ui: {
      editingStageClientId: null as string | null,
      isClientSettingStage: {} as Record<string, boolean>,
    },
  },
  relationshipTable: {
    data: {
      cacheById: {} as Record<string, any[]>,
      total: 0,
      highestRelationshipScore: null as number | null,
    },
    queryParams: {
      page: 1,
      limit: 10,
      filters: {
        searchTerm: '',
        stages: [],
        lastInteraction: [],
        showHidden: false,
      },
      sortCriteria: { key: 'relationshipScore', direction: 'desc' as 'asc' | 'desc' },
    },
    loading: {
      loadingStateByPage: { 1: { loading: false, error: null } } as Record<
        number,
        { loading: boolean; error: string | null }
      >,
      stageManagement: { loading: false, error: null },
    },
  },
};

const savedState = (() => {
  try {
    return JSON.parse(localStorage.getItem('ermDashboardState') || '{}');
  } catch (error) {
    console.error('Failed to parse saved state from localStorage:', error);
    return {}; // Fallback to an empty object if parsing fails
  }
})();

// Load saved query parameters from localStorage and validate them
const initialState = {
  ...defaultErmState,
  clientTable: {
    ...defaultErmState.clientTable,
    queryParams: validateAndFixQueryParams(savedState.clientQueryParams || {}, defaultErmState.clientTable.queryParams),
  },
  relationshipTable: {
    ...defaultErmState.relationshipTable,
    queryParams: validateAndFixQueryParams(
      savedState.relationshipQueryParams || {},
      defaultErmState.relationshipTable.queryParams,
    ),
  },
};

/*****************************************************************************************
 *                                      Middleware                                       *
 *****************************************************************************************/

/**
 * Middleware to persist specific parts of the Redux state to localStorage.
 * This middleware listens for specific actions and saves the relevant query parameters
 * to localStorage, ensuring that user preferences are maintained across sessions.
 *
 * @param {Object} storeAPI - The Redux store API, providing access to dispatch and getState.
 * @returns {Function} A function that takes the next middleware in the chain.
 */
export const saveStateMiddleware: Middleware = (storeAPI) => (next) => (action) => {
  const result = next(action);

  // List of actions that should trigger state saving
  const actionsToSaveState = [
    setClientFilterSearchTerm.type,
    setClientFilterStages.type,
    setClientFilterLastInteraction.type,
    setClientSortCriteria.type,
    setClientShowHidden.type,
  ];

  // Check if the action type is one of those that require saving state
  if (actionsToSaveState.includes(action.type)) {
    // Retrieve the current state from the store
    const state = storeAPI.getState();

    // Extract query parameters for client and relationship tables,
    // but only filters and sortCriteria
    const clientQueryParams = {
      filters: state.ermDashboard.clientTable.queryParams.filters,
      sortCriteria: state.ermDashboard.clientTable.queryParams.sortCriteria,
    };
    const relationshipQueryParams = {
      filters: state.ermDashboard.relationshipTable.queryParams.filters,
      sortCriteria: state.ermDashboard.relationshipTable.queryParams.sortCriteria,
    };

    // Save the extracted query parameters to localStorage
    localStorage.setItem('ermDashboardState', JSON.stringify({ clientQueryParams, relationshipQueryParams }));
  }

  return result;
};

// Middleware to listen for ermStages changes
export const ermStagesMiddleware: Middleware = (storeAPI) => {
  // Debounce the dispatch of updateErmStagesThunk
  const debouncedUpdateErmStages = debounce((ermStages) => {
    storeAPI.dispatch(updateErmStagesThunk(ermStages));
  }, 300); // Adjust the delay as needed

  return (next) => (action) => {
    const result = next(action);

    // List of actions that should trigger ermStages update
    const actionsToUpdateErmStages = [
      'ermDashboard/setStageManagementStages', // Add any other actions that modify ermStages
    ];

    if (actionsToUpdateErmStages.includes(action.type)) {
      const state = storeAPI.getState();
      const ermStages = state.ermDashboard.stageManagement.ermStages;

      // Use the debounced function to dispatch the thunk
      debouncedUpdateErmStages(ermStages);
    }

    return result;
  };
};

/*****************************************************************************************
 *                                     Async Thunks                                      *
 *****************************************************************************************/

/**
 * Async thunk to fetch the highest relationship scores for companies and contacts.
 * This function dispatches an asynchronous request to retrieve the highest scores
 * and sets them in the state.
 *
 * @param {void} _ - No parameters are passed; scores are fetched from the API.
 * @returns {Promise<void>} No payload is returned; the scores are set in the state.
 */
export const fetchAndSetHighestScores = createAsyncThunk(
  'ermDashboard/fetchAndSetHighestScores',
  async (_, { getState }) => {
    // Fetch the highest relationship score for a company
    const companyScore = await getHighestCompanyClientRelationshipScore();

    // Fetch the highest relationship score for a contact
    const contactScore = await getHighestCompanyClientContactRelationshipScore();

    return { companyScore, contactScore };
  },
);

/**
 * Async thunk to fetch clients with applied filters and sorting.
 * This function dispatches an asynchronous request to retrieve clients
 * and handles caching to optimize API calls.
 *
 * The thunk retrieves its parameters directly from the store state using getState().
 * It is designed to be dispatched whenever any relevant parameters change.
 *
 * @param {void} _ - No parameters are passed; parameters are retrieved from state.
 * @param {Object} thunkAPI - The thunk API, providing access to getState and rejectWithValue.
 * @returns {Promise<{augmentedClients: any[], total: number, page: number} | {cached: boolean, page: number}>}
 *          Returns either cached data or fetched client data with total count and page number.
 */
export const fetchClients = createAsyncThunk('ermDashboard/fetchClients', async (_, { getState, rejectWithValue }) => {
  const state: any = getState();

  const clientTable = state.ermDashboard.clientTable;
  const { page, limit, sortCriteria, filters } = clientTable.queryParams;

  const clientCache = clientTable.data.cacheByPage;

  // Check if the requested page is already cached and up-to-date
  const isPageCached = !!clientCache[page];

  if (isPageCached) {
    // Return cached data to avoid unnecessary API calls
    return { cached: true, page };
  }

  try {
    // Fetch clients from the API with the applied filters and sorting
    const submittableQueryParams = buildSubmittableQueryParams(clientTable);
    const data = await getCompanyClients(submittableQueryParams);
    if (!data?.clients) {
      return rejectWithValue('No clients found');
    }

    // Augment the fetched clients with default values and timestamps
    const augmentedClients = data.clients.map((client: any) => {
      const stage =
        client?.stage &&
        state.auth.company.company.ermPreference.pipelineStages.some((stage: any) => stage._id === client.stage)
          ? client.stage
          : NO_STAGE_DEFINITION._id;
      return {
        ...client,
        name: client?.name || 'Unnamed Client',
        stage,
      };
    });

    // Return the augmented clients along with total count and page number
    return { augmentedClients, total: data.total, page };
  } catch (error) {
    console.error('error fetching clients', error);
    // Handle any errors during the fetch operation
    return rejectWithValue('Failed to load clients');
  }
});

/**
 * Async thunk to update the stage of a client.
 * This function dispatches an asynchronous request to update the stage of a client
 * and handles caching to optimize API calls.
 *
 * @param {Object} thunkAPI - The thunk API, providing access to getState and rejectWithValue.
 * @returns {Promise<{clientId: string, newStageId: string} | {error: string}>}
 *          Returns either the updated client info or an error message.
 */
export const updateClientStage = createAsyncThunk(
  'ermDashboard/updateClientStage',
  async ({ clientId, newStageId }: { clientId: string; newStageId: string }, { getState, rejectWithValue }) => {
    try {
      const result = await updateCompanyClientStage(clientId, newStageId);
      if (!result?.success) {
        throw new Error(result?.message || 'Failed to update stage');
      }

      // Return the updated client info
      return { clientId, newStageId };
    } catch (error) {
      return rejectWithValue(error.message || 'Failed to update client stage');
    }
  },
);

/**
 * Async thunk to toggle the visibility of a client.
 * This function dispatches an asynchronous request to toggle the visibility of a client
 * and handles caching to optimize API calls.
 *
 * @param {Object} thunkAPI - The thunk API, providing access to getState and rejectWithValue.
 * @returns {Promise<{clientId: string, action: 'hide' | 'show'} | {error: string}>}
 *          Returns either the updated client info or an error message.
 */
export const toggleClientVisibility = createAsyncThunk(
  'ermDashboard/toggleClientVisibility',
  async ({ clientId, action }: { clientId: string; action: 'hide' | 'show' }, { dispatch, rejectWithValue }) => {
    try {
      const result = await setCompanyClientVisibilityForUser(clientId, action);
      if (!result?.success) {
        throw new Error(result?.message || 'Failed to toggle visibility');
      }

      return { clientId, action };
    } catch (error) {
      return rejectWithValue(error.message || `Failed to ${action} client`);
    }
  },
);

/**
 * Async thunk to fetch the ERM stages.
 * This function dispatches an asynchronous request to retrieve the ERM stages
 * and sets them in the state.
 *
 * @param {void} _ - No parameters are passed to the endpoint.
 * @returns {Promise<Stage[]>} - Returns the fetched ERM stages.
 */
export const fetchErmStagesThunk = createAsyncThunk<void, void, { rejectValue: string }>(
  'ermDashboard/fetchErmStages',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const stages = await getErmStages();
      dispatch(setPipelineStages(stages));
    } catch (error) {
      return rejectWithValue('Failed to fetch ERM stages.');
    }
  },
);

// Update ERM Stages
export const updateErmStagesThunk = createAsyncThunk<Stage[], Stage[], { rejectValue: string }>(
  'ermDashboard/updateErmStages',
  async (stages, { dispatch, rejectWithValue }) => {
    try {
      const response = await updateErmStages(stages);
      dispatch(setPipelineStages(response.data));
      return response.data;
    } catch (error) {
      return rejectWithValue('Failed to update ERM stages.');
    }
  },
);

/*****************************************************************************************
 *                                    Redux Slice                                        *
 *****************************************************************************************/

const ermDashboardSlice = createSlice({
  name: 'ermDashboard',
  initialState,
  reducers: {
    // Client table reducers
    setClientFilterSearchTerm(state, action: PayloadAction<string>) {
      state.clientTable.queryParams.filters.searchTerm = action.payload;
    },
    setClientFilterStages(state, action: PayloadAction<Record<string, boolean>>) {
      state.clientTable.queryParams.filters.stages = {
        ...state.clientTable.queryParams.filters.stages,
        ...action.payload,
      };
    },
    setClientFilterLastInteraction(state, action: PayloadAction<Record<string, boolean>>) {
      state.clientTable.queryParams.filters.lastInteraction = validateAndFixFilterOptions(
        action.payload,
        Object.keys(INTERACTION_FILTER_OPTIONS),
      );
    },
    setClientSortCriteria(state, action: PayloadAction<{ key: string; direction: 'asc' | 'desc' }>) {
      state.clientTable.queryParams.sortCriteria = validateAndFixSortCriteria(
        action.payload,
        defaultErmState.clientTable.queryParams.sortCriteria,
      );
    },
    setClientShowHidden(state, action: PayloadAction<boolean>) {
      state.clientTable.queryParams.filters.showHidden = action.payload;
    },
    // Pagination reducer for client table
    setClientPage(state, action: PayloadAction<number>) {
      state.clientTable.queryParams.page = action.payload;
    },
    setSelectedClient(state, action: PayloadAction<any>) {
      state.selectedClient = action.payload;
    },
    setClientHighestRelationshipScore(state, action: PayloadAction<number>) {
      state.clientTable.data.highestRelationshipScore = action.payload;
    },
    // Action to invalidate client cache
    invalidateClientCacheForAllPages(state) {
      state.clientTable.data.cacheByPage = {};
    },
    invalidateClientCacheForPage(state, action: PayloadAction<number>) {
      state.clientTable.data.cacheByPage[action.payload] = null;
    },
    // Relationship table reducers
    setRelationshipFilterName(state, action: PayloadAction<string>) {
      state.relationshipTable.queryParams.filters.name = action.payload;
      // Invalidate cache if necessary (similar logic can be applied)
    },
    setRelationshipFilterStages(state, action: PayloadAction<Record<string, boolean>>) {
      state.relationshipTable.queryParams.filters.stages = action.payload;
      // Invalidate cache if necessary
    },
    setRelationshipFilterLastInteraction(state, action: PayloadAction<Record<string, boolean>>) {
      state.relationshipTable.queryParams.filters.lastInteraction = action.payload;
      // Invalidate cache if necessary
    },
    setRelationshipSortCriteria(state, action: PayloadAction<{ key: string; direction: 'asc' | 'desc' }>) {
      state.relationshipTable.queryParams.sortCriteria = action.payload;
      // Invalidate cache if necessary
    },
    setRelationshipShowHidden(state, action: PayloadAction<boolean>) {
      state.relationshipTable.queryParams.filters.showHidden = action.payload;
      // Invalidate cache if necessary
    },
    setRelationshipHighestRelationshipScore(state, action: PayloadAction<number>) {
      state.relationshipTable.data.highestRelationshipScore = action.payload;
    },
    setERMRelationshipCache(state, action: PayloadAction<Record<string, any[]>>) {
      state.relationshipTable.data.cacheById = action.payload;
    },
    setERMTotalRelationships(state, action: PayloadAction<number>) {
      state.relationshipTable.data.total = action.payload;
    },
    setEditingStageClientId(state, action: PayloadAction<string | null>) {
      state.clientTable.ui.editingStageClientId = action.payload;
    },
    setLoadingStage(state, action: PayloadAction<{ clientId: string; isLoading: boolean }>) {
      const { clientId, isLoading } = action.payload;
      state.clientTable.ui.isClientSettingStage = {
        ...state.clientTable.ui.isClientSettingStage,
        [clientId]: isLoading,
      };
    },
    toggleEditMode(state) {
      state.clientTable.stageManagement.isEditModeActive = !state.clientTable.stageManagement.isEditModeActive;
    },
    initiateStageEdit(state, action: PayloadAction<Stage>) {
      state.clientTable.stageManagement.editState.editedStages[action.payload.id] = action.payload;
      state.clientTable.stageManagement.editState.pendingChanges.add(action.payload.id);
    },
    cancelStageEdit(state, action: PayloadAction<string>) {
      delete state.clientTable.stageManagement.editState.editedStages[action.payload];
      state.clientTable.stageManagement.editState.pendingChanges.delete(action.payload);
    },
  },
  extraReducers: (builder) => {
    // Handle the fulfilled state of fetchAndSetHighestScores thunk
    builder.addCase(fetchAndSetHighestScores.fulfilled, (state, action) => {
      // Update the highest relationship scores in the state with the fetched data
      state.clientTable.data.highestRelationshipScore = action.payload.companyScore;
      state.relationshipTable.data.highestRelationshipScore = action.payload.contactScore;
    });

    builder.addCase(fetchAndSetHighestScores.rejected, (state, action) => {
      console.error('error fetching highest relationship scores', action.error);
      state.clientTable.data.highestRelationshipScore = null;
      state.relationshipTable.data.highestRelationshipScore = null;
    });

    // Handle the pending state of fetchClients thunk
    builder.addCase(fetchClients.pending, (state, action) => {
      const page = state.clientTable.queryParams.page; // Assuming page number is passed as an argument
      state.clientTable.loading.loadingStateByPage[page] = { loading: true, error: null };
    });

    // Handle the fulfilled state of fetchClients thunk
    builder.addCase(fetchClients.fulfilled, (state, action) => {
      const { page } = action.payload;
      if (action.payload.cached) {
        state.clientTable.loading.loadingStateByPage[page] = { loading: false, error: null };
        return;
      }
      const { augmentedClients, total } = action.payload;
      state.clientTable.data = {
        ...state.clientTable.data,
        cacheByPage: {
          ...state.clientTable.data.cacheByPage,
          [page]: augmentedClients,
        },
        total,
      };
      state.clientTable.loading.loadingStateByPage[page] = { loading: false, error: null };
    });

    // Handle the rejected state of fetchClients thunk
    builder.addCase(fetchClients.rejected, (state, action) => {
      const page = state.clientTable.queryParams.page; // Assuming page number is passed as an argument
      state.clientTable.loading.loadingStateByPage[page] = { loading: false, error: action.payload as string };
    });

    // Update the updateClientStage cases
    builder.addCase(updateClientStage.pending, (state, action) => {
      const { clientId } = action.meta.arg;
      state.clientTable.ui.editingStageClientId = null;
      state.clientTable.ui.isClientSettingStage[clientId] = true;

      // update the client stage in the state
    });

    builder.addCase(updateClientStage.fulfilled, (state, action) => {
      const { clientId, newStageId } = action.payload;

      // Update the client stage in all cached pages
      Object.keys(state.clientTable.data.cacheByPage).forEach((pageKey) => {
        const page = state.clientTable.data.cacheByPage[pageKey];

        if (page) {
          const clientIndex = page.findIndex((client) => client._id === clientId);

          if (clientIndex !== -1) {
            state.clientTable.data.cacheByPage[pageKey] = [
              ...page.slice(0, clientIndex),
              { ...page[clientIndex], stage: newStageId },
              ...page.slice(clientIndex + 1),
            ];
          }
        }
      });

      // Update selected client if it's the one being modified
      if (state.selectedClient?._id === clientId) {
        state.selectedClient = {
          ...state.selectedClient,
          stage: newStageId,
        };
      }

      // Clear UI states
      state.clientTable.ui.isClientSettingStage[clientId] = false;
      state.clientTable.ui.editingStageClientId = null;
    });

    builder.addCase(updateClientStage.rejected, (state, action) => {
      const { clientId } = action.meta.arg;
      state.clientTable.ui.isClientSettingStage[clientId] = false;
    });

    // Handle the fulfilled state of toggleClientVisibility thunk
    builder.addCase(toggleClientVisibility.fulfilled, (state, action) => {
      // if there was only 1 item on this page, go to previous page
      if (state.clientTable.data.cacheByPage[state.clientTable.queryParams.page]?.length === 1) {
        state.clientTable.queryParams.page = Math.max(state.clientTable.queryParams.page - 1, 1);
      } else {
        // Otherwise, clear the cache for the current page and force a refresh
        state.clientTable.data.cacheByPage[state.clientTable.queryParams.page] = null;
      }
    });

    // Handle fetchErmStages
    builder.addCase(fetchErmStagesThunk.pending, (state) => {
      state.clientTable.loading.stageManagement = { loading: true, error: null };
    });
    builder.addCase(fetchErmStagesThunk.fulfilled, (state) => {
      state.clientTable.loading.stageManagement = { loading: false, error: null };
    });
    builder.addCase(fetchErmStagesThunk.rejected, (state, action) => {
      state.clientTable.loading.stageManagement = {
        loading: false,
        error: action.payload || 'Unknown error',
      };
    });

    // Handle updateErmStages
    builder.addCase(updateErmStagesThunk.pending, (state) => {
      state.clientTable.loading.stageManagement = { loading: true, error: null };
    });
    builder.addCase(updateErmStagesThunk.fulfilled, (state, action: PayloadAction<Stage[]>) => {
      state.clientTable.loading.stageManagement = { loading: false, error: null };
    });
    builder.addCase(updateErmStagesThunk.rejected, (state, action) => {
      state.clientTable.loading.stageManagement = {
        loading: false,
        error: action.payload || 'Unknown error',
      };
    });
  },
});

export const {
  setClientFilterSearchTerm,
  setClientFilterStages,
  setClientFilterLastInteraction,
  setClientSortCriteria,
  setClientShowHidden,
  setClientPage,
  setSelectedClient,
  invalidateClientCacheForAllPages,
  invalidateClientCacheForPage,
  setRelationshipFilterName,
  setRelationshipFilterStages,
  setRelationshipFilterLastInteraction,
  setRelationshipSortCriteria,
  setRelationshipShowHidden,
  setERMRelationshipCache,
  setERMTotalRelationships,
  setEditingStageClientId,
  setLoadingStage,
} = ermDashboardSlice.actions;

export default ermDashboardSlice.reducer;

/*****************************************************************************************
 *                                     Action Creators                                    *
 *****************************************************************************************/

// Utility function to create filter change actions
const createFilterChangeAction =
  (setFilterAction) =>
  (...args) =>
  (dispatch) => {
    dispatch(setFilterAction(...args));
    dispatch(setClientPage(1));
    dispatch(invalidateClientCacheForAllPages());
    dispatch(fetchClients());
  };

// Specific actions for each filter change
export const changeClientFilterStages = createFilterChangeAction(setClientFilterStages);
export const changeClientFilterLastInteraction = createFilterChangeAction(setClientFilterLastInteraction);
export const changeClientSortCriteria = createFilterChangeAction(setClientSortCriteria);
export const changeClientShowHidden = createFilterChangeAction(setClientShowHidden);
export const changeClientPage = (newPage) => (dispatch) => {
  // sometimes 0 comes in and instead of finding out why, `|| 1` is easier lol
  dispatch(setClientPage(newPage || 1));
  dispatch(setSelectedClient(null));
  dispatch(fetchClients());
};

// Create a debounced function to dispatch actions
const debouncedDispatchActions = debounce((dispatch, name) => {
  dispatch(setClientPage(1));
  dispatch(invalidateClientCacheForAllPages());
}, SEARCH_TERM_DEBOUNCE_DELAY_MS);

// This needs its own separate definition because it needs to debounce
export const changeClientFilterSearchTerm = (name) => (dispatch) => {
  dispatch(setClientFilterSearchTerm(name));
  debouncedDispatchActions(dispatch, name);
  debouncedFetchClients(dispatch);
};

export const changeClientVisibility = (clientId: string, action: 'hide' | 'show') => async (dispatch) => {
  const result = await dispatch(toggleClientVisibility({ clientId, action }));

  // Check if the request was successful
  if (toggleClientVisibility.fulfilled.match(result)) {
    dispatch(fetchClients());
  }
};
