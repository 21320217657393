import moment from 'moment';
import { formatDate, compareDates, getMostRecentDate, isValidDate } from '@/helper/utilities';
import React from 'react';
import { Mail, Linkedin, ArrowDownLeft, ArrowUpRight } from 'lucide-react';
import { Badge } from '@/components/ui';

export const isValidClient = (client: any): boolean => {
  return client && typeof client === 'object' && typeof client._id === 'string' && typeof client.name === 'string';
};

export const isValidRelationship = (relationship: any): boolean => {
  return (
    relationship &&
    typeof relationship === 'object' &&
    Array.isArray(relationship.contacts) &&
    relationship.contacts.length > 0
  );
};

export const getContactId = (relationship: any) => {
  if (!relationship?.contacts || !Array.isArray(relationship.contacts)) {
    return null;
  }
  const contact = relationship.contacts[0];
  return contact?._id || null;
};

export const getSortValue = (obj: any, key: string) => {
  const value = obj?.[key];
  if (typeof value === 'string') return value.toLowerCase();
  if (typeof value === 'number') return value;
  return '';
};

export const compareByCriteria = (a: any, b: any, criteria: { key: string; direction: string }) => {
  if (criteria?.key === 'lastInteraction') {
    return compareDates(a?.lastInteraction?.actionDate, b?.lastInteraction?.actionDate, criteria?.direction);
  }

  const valueA = getSortValue(a, criteria?.key);
  const valueB = getSortValue(b, criteria?.key);

  if (valueA < valueB) return criteria?.direction === 'asc' ? -1 : 1;
  if (valueA > valueB) return criteria?.direction === 'asc' ? 1 : -1;
  return 0;
};

export const getLastInteraction = (interactionObj: any) => {
  if (
    !interactionObj ||
    (!isValidDate(interactionObj.lastOutgoingInteraction?.actionDate) &&
      !isValidDate(interactionObj.lastIncomingInteraction?.actionDate))
  )
    return null;

  const mostRecentDate = getMostRecentDate(
    interactionObj.lastOutgoingInteraction?.actionDate,
    interactionObj.lastIncomingInteraction?.actionDate,
  );

  return mostRecentDate === interactionObj.lastOutgoingInteraction?.actionDate
    ? interactionObj.lastOutgoingInteraction
    : interactionObj.lastIncomingInteraction;
};

export const renderInteractionDate = (interaction: Interaction, relative = true): React.ReactNode => {
  if (!interaction?.actionDate && !interaction?.user?.name) return 'No interaction recorded';

  const dateValue = interaction?.actionDate
    ? relative
      ? moment(interaction.actionDate).fromNow()
      : formatDate(interaction.actionDate)
    : '';

  const userValue = interaction?.user?.name ? (
    <span className="text-muted-foreground ml-1">with {interaction.user.name}</span>
  ) : null;

  return (
    <React.Fragment>
      {dateValue}
      {userValue}
    </React.Fragment>
  );
};

export const renderInteractionTooltip = (interaction: Interaction): React.ReactNode => {
  if (!interaction?.actionDate && !interaction?.user?.name) return 'No interaction recorded';

  return (
    <span>
      <span className="font-semibold">{interaction?.user?.name}</span>{' '}
      <span className="text-muted-foreground">
        {interaction?.messagingTraffic === 'incoming'
          ? 'received'
          : interaction?.messagingTraffic === 'outgoing'
            ? 'sent'
            : 'communicated internally'}
      </span>{' '}
      <span className="font-semibold">
        {interaction?.messagingPlatform === 'linkedin'
          ? 'LinkedIn message'
          : interaction?.messagingPlatform === 'email'
            ? 'Email'
            : 'on an unspecified platform'}
      </span>{' '}
      <span className="text-muted-foreground">on</span>{' '}
      <span className="font-semibold">{formatDate(interaction?.actionDate)}</span>
    </span>
  );
};

export const getInteractionIcon = (messagingPlatform: string) => {
  const validTypes = ['email', 'linkedin'];

  if (!messagingPlatform || !validTypes.includes(messagingPlatform)) {
    return null;
  }
  switch (messagingPlatform) {
    case 'email':
      return <Mail className="h-4 w-4 text-gray-500" />;
    case 'linkedin':
      return <Linkedin className="h-4 w-4 text-gray-500" />;
    default:
      return <span className="ml-12" />;
  }
};

export const getDirectionIcon = (direction: string) => {
  if (direction === 'incoming') {
    return <ArrowDownLeft className="h-4 w-4 text-green-500" />;
  } else if (direction === 'outgoing') {
    return <ArrowUpRight className="h-4 w-4 text-blue-500" />;
  }
  return <span className="h-12 w-12 inline-block" />;
};

export const getStageBadge = (stage) => {
  const colorMap = {
    'lead': 'bg-blue-100 text-blue-800 hover:bg-blue-200',
    'positive meeting': 'bg-yellow-100 text-yellow-800 hover:bg-yellow-200',
    'signed client': 'bg-green-100 text-green-800 hover:bg-green-200',
    'lost': 'bg-red-100 text-red-800 hover:bg-red-200',
  };
  return (
    <Badge
      className={`${colorMap[stage] || 'bg-gray-100 text-gray-800 hover:bg-gray-200'} font-semibold cursor-pointer whitespace-nowrap overflow-hidden`}
    >
      {stage}
    </Badge>
  );
};

export const getTierBadge = (tier) => {
  const colorMap = {
    'Tier 1': 'bg-green-100 text-green-800',
    'Tier 2': 'bg-yellow-100 text-yellow-800',
    'Tier 3': 'bg-red-100 text-red-800',
  };
  return <Badge className={`${colorMap[tier]} font-semibold cursor-pointer`}>{tier}</Badge>;
};
