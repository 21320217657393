'use client';

import React from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Button } from '@/components/ui/button';
import { StageBadge } from './stage-badge';
import { ChevronDown, Pencil } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import * as ScrollArea from '@/components/ui/scroll-area';
import { Stage } from './types';
import { NO_STAGE_DEFINITION } from '@postilize/shared/constants/ERM';
import { useSelector } from 'react-redux';
import { RootState } from '@/store'; // Adjust the import path as necessary

interface StagePickerProps {
  ref: React.RefObject<HTMLDivElement>;
  stages: Stage[];
  client: any;
  currentStageId: string;
  onStageSelect: (clientId: string, stageId: string) => void;
  onEditStagesClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  open: boolean;
  onBaseBadgeClick: () => void;
  onClose: () => void;
  onOpenChange: (open: boolean) => void;
}

export const StagePicker: React.FC<StagePickerProps> = ({
  ref,
  stages,
  client,
  currentStageId,
  onStageSelect,
  onEditStagesClick,
  open,
  onBaseBadgeClick,
  onClose,
  onOpenChange,
}) => {
  console.log('stagepicker currentStageId', currentStageId);
  console.log('stagepicker stages', stages);
  const currentStage = stages.find((stage) => stage._id === currentStageId) || NO_STAGE_DEFINITION;
  console.log('stagepicker currentStage', currentStage);

  const handleEditStagesClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    onEditStagesClick(e);
  };

  const currentUserId = useSelector((state: RootState) => state.auth.user._id);
  const adminIds = useSelector((state: RootState) => state.auth.company.company.admins);

  const isAdmin = adminIds.includes(currentUserId);

  return (
    <div ref={ref}>
      <TooltipProvider>
        <Popover open={open} onOpenChange={onOpenChange}>
          <PopoverTrigger asChild>
            <div
              className="flex items-center cursor-pointer"
              role="combobox"
              aria-label="Select a stage"
              aria-expanded="false"
              onClick={onBaseBadgeClick}
            >
              <StageBadge
                stageId={currentStage._id}
                initialText={currentStage.name}
                backgroundColor={currentStage.backgroundColor}
                textColor={currentStage.textColor}
                isEditing={false}
                editable={false}
                onBlur={() => {}}
                onChange={() => {}}
                onKeyDown={() => {}}
              />
              <ChevronDown className="ml-1 h-4 w-4 shrink-0 opacity-50" />
            </div>
          </PopoverTrigger>
          <PopoverContent className="w-[200px] p-0" align="start">
            <div className="relative">
              <div
                className="max-h-[300px] overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100"
                style={{
                  scrollbarWidth: 'thin',
                  scrollbarColor: '#D1D5DB #F3F4F6',
                }}
              >
                <div className="p-1">
                  {[...stages].map((stage) => (
                    <Tooltip key={stage._id}>
                      <TooltipTrigger asChild>
                        <Button
                          variant="ghost"
                          className="w-full justify-start mb-1 py-1 px-2"
                          onClick={() => onStageSelect(client._id, stage._id)}
                        >
                          <StageBadge
                            initialText={stage.name}
                            backgroundColor={stage.backgroundColor}
                            textColor={stage.textColor}
                          />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent side="right">
                        Assign the pipeline stage <b>{stage.name}</b> to <b>{client.name}</b>
                      </TooltipContent>
                    </Tooltip>
                  ))}
                </div>
              </div>
            </div>
            {isAdmin && (
              <div className="p-1 border-t">
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      variant="ghost"
                      className="w-full justify-start font-normal"
                      onClick={handleEditStagesClick}
                    >
                      <Pencil className="mr-2 h-4 w-4" />
                      Edit Stages
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent side="right">Open stage manager</TooltipContent>
                </Tooltip>
              </div>
            )}
          </PopoverContent>
        </Popover>
      </TooltipProvider>
    </div>
  );
};
