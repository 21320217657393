import React from 'react';
import { Button } from '@/components/ui/button';
import { motion, AnimatePresence } from 'framer-motion';
import { cn } from '@/lib/utils';

interface IconButtonProps {
  icon: React.ReactNode;
  color: string;
  hoverColor: string;
  circleColor: string;
  hoverCircleColor: string;
  onClick?: () => void;
  disabled?: boolean;
  isActive?: boolean;
}

export const IconButton: React.FC<IconButtonProps> = ({
  icon,
  color,
  hoverColor,
  circleColor,
  hoverCircleColor,
  onClick,
  disabled = false,
  isActive = false,
}) => {
  return (
    <div className="relative group">
      <Button
        variant="ghost"
        size="icon"
        onClick={onClick}
        disabled={disabled}
        className={cn(
          'relative z-10 h-8 w-8 rounded-full transition-all',
          isActive
            ? `text-${color} hover:text-${hoverColor} hover:bg-${hoverCircleColor} group-hover:h-6 group-hover:w-6 group-hover:m-1`
            : `text-muted-foreground hover:text-foreground hover:bg-muted`,
        )}
      >
        <span className="p-0.5">{icon}</span>
      </Button>
      <AnimatePresence>
        {isActive && (
          <motion.div
            initial={{ scale: 0, opacity: 0 }}
            animate={{
              scale: [0, 0.8, 0.7],
              opacity: [0, 1, 0.8],
            }}
            exit={{ scale: 0, opacity: 0 }}
            transition={{
              duration: 0.3,
              times: [0, 0.8, 1],
              ease: 'easeInOut',
            }}
            className={`absolute inset-0 bg-${circleColor} rounded-full`}
            style={{ zIndex: 0 }}
          />
        )}
      </AnimatePresence>
    </div>
  );
};
