import React from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';

interface ColorPickerProps {
  color: string;
  onChange: (color: string) => void;
}

const colorPairs = [
  { light: '#E0F2FE', dark: '#0369A1' }, // Sky Blue
  { light: '#FEF3C7', dark: '#B45309' }, // Amber
  { light: '#ECFCCB', dark: '#4D7C0F' }, // Lime
  { light: '#D1FAE5', dark: '#047857' }, // Emerald
  { light: '#E0E7FF', dark: '#4338CA' }, // Indigo
  { light: '#FCE7F3', dark: '#BE185D' }, // Pink
  { light: '#FFE4E6', dark: '#BE123C' }, // Rose
  { light: '#F3E8FF', dark: '#7E22CE' }, // Purple
  { light: '#FFEDD5', dark: '#C2410C' }, // Orange
  { light: '#DBEAFE', dark: '#1D4ED8' }, // Blue
  { light: '#F0FDF4', dark: '#15803D' }, // Green
  { light: '#FDF2F8', dark: '#9D174D' }, // Fuchsia
  { light: '#F0FDFA', dark: '#0F766E' }, // Teal
  { light: '#FEF2F2', dark: '#B91C1C' }, // Red
  { light: '#F8FAFC', dark: '#334155' }, // Slate
];

const lightColors = colorPairs.map((pair) => pair.light);
const darkColors = colorPairs.map((pair) => pair.dark);
const allColors = [...lightColors, ...darkColors];

const isLightColor = (color: string) => {
  const hex = color.replace('#', '');
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness > 128;
};

export const ColorPicker: React.FC<ColorPickerProps> = ({ color, onChange }) => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" className="w-8 h-8 rounded-full p-0 border-2" style={{ backgroundColor: color }} />
      </PopoverTrigger>
      <PopoverContent className="w-[270px] p-2">
        <div className="grid grid-cols-5 gap-1">
          {allColors.map((c) => (
            <button
              key={c}
              className={cn(
                'w-12 h-12 rounded-full border focus:outline-none focus:ring-2 focus:ring-gray-500 transition-shadow hover:shadow-md',
                c === color ? 'ring-2 ring-gray-500' : 'ring-0',
              )}
              style={{
                backgroundColor: c,
              }}
              onClick={() => onChange(c)}
            >
              <span
                className={cn(
                  'block w-full text-center text-xs font-bold',
                  isLightColor(c) ? 'text-gray-800' : 'text-white',
                )}
              >
                Aa
              </span>
            </button>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
};
