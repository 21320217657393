import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import { combineReducers } from 'redux';
import authReducer from './authSlice';
import sequenceReviewReducer from './sequenceReviewSlice';
import ermDashboardReducer, { saveStateMiddleware } from './ermDashboardSlice';
import outreachListsReducer from './outreachListsSlice';
import { pipelineStagesMiddleware } from './authSlice';

const persistConfig = {
  key: 'auth',
  storage,
  whitelist: ['selectedDelegation', 'user'],
};

const reducers = combineReducers({
  auth: authReducer,
  sequenceReview: sequenceReviewReducer,
  ermDashboard: ermDashboardReducer,
  outreachLists: outreachListsReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(saveStateMiddleware, pipelineStagesMiddleware),
});

export const persistor = persistStore(store);
