const { IceSheet } = require('../utils/objectUtils');
const { INTERACTION_TYPE } = require('./interactions');

const INTERACTION_FILTER_OPTION_LABELS = new IceSheet({
  LINKEDIN: {
    INCOMING: 'Incoming LinkedIn InMails',
    OUTGOING: 'Outgoing LinkedIn InMails',
  },
  EMAIL: {
    INCOMING: 'Incoming Emails',
    OUTGOING: 'Outgoing Emails',
  },
  CALENDAR: {
    INCOMING: 'Calendar Meetings',
    OUTGOING: 'Calendar Meetings',
  },
  ADDED_CONTACT: 'Added as Contact',
});

const INTERACTION_FILTER_OPTIONS = {
  [INTERACTION_FILTER_OPTION_LABELS.LINKEDIN.INCOMING]: [INTERACTION_TYPE.LINKEDIN_INCOMING],
  [INTERACTION_FILTER_OPTION_LABELS.LINKEDIN.OUTGOING]: [INTERACTION_TYPE.LINKEDIN_OUTGOING],
  [INTERACTION_FILTER_OPTION_LABELS.EMAIL.INCOMING]: [INTERACTION_TYPE.EMAIL_INCOMING],
  [INTERACTION_FILTER_OPTION_LABELS.EMAIL.OUTGOING]: [INTERACTION_TYPE.EMAIL_OUTGOING],
  [INTERACTION_FILTER_OPTION_LABELS.CALENDAR.INCOMING]: [INTERACTION_TYPE.CALENDAR_INCOMING],
  [INTERACTION_FILTER_OPTION_LABELS.CALENDAR.OUTGOING]: [INTERACTION_TYPE.CALENDAR_OUTGOING],
  [INTERACTION_FILTER_OPTION_LABELS.ADDED_CONTACT]: [INTERACTION_TYPE.INTERNAL_ADDED_CONTACT],
};

const ENGAGEMENT_TYPES = new IceSheet({
  CHECKIN: 'checkin',
  MEETING: 'meeting',
});

const SORT_KEYS = new IceSheet({
  LAST_INTERACTION: 'lastInteraction',
  NAME: 'name',
  RELATIONSHIP_SCORE: 'relationshipScore',
  STAGE: 'stage',
  COMMUNICATIONS_COUNT: 'commsCount',
});

const VISIBILITY_ACTIONS = new IceSheet({
  HIDE: 'hide',
  SHOW: 'show',
});

const NO_STAGE_DEFINITION = {
  _id: 'no-stage',
  name: 'No stage',
  textColor: 'white',
  backgroundColor: 'black',
};

module.exports = Object.freeze({
  INTERACTION_FILTER_OPTIONS,
  INTERACTION_FILTER_OPTION_LABELS,
  ENGAGEMENT_TYPES,
  SORT_KEYS,
  VISIBILITY_ACTIONS,
  NO_STAGE_DEFINITION,
});
