import React, { useState, ReactNode, useCallback, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult, DragStart } from '@hello-pangea/dnd';
import { GripVertical } from 'lucide-react';

interface DraggableItem<T> {
  id: string;
  data: T;
  content: ReactNode;
}

interface AnimatedDraggableListProps<T> {
  items: DraggableItem<T>[];
  onDragStateChange?: (isDragging: boolean, draggedId?: string, orderedItems?: T[]) => void;
  onItemDelete?: (id: string) => void;
}

const AnimatedDraggableList = <T,>({
  items,
  onDragStateChange,
  onItemDelete,
}: AnimatedDraggableListProps<T>): React.ReactElement => {
  const [draggableItems, setDraggableItems] = useState<DraggableItem<T>[]>([]);

  useEffect(() => {
    setDraggableItems(
      items.map((item, index) => ({
        ...item,
        id: `draggable-item-${index}`,
      })),
    );
  }, [items]);

  const handleDragStart = useCallback(
    (start: DragStart) => {
      onDragStateChange?.(true, start.draggableId);
    },
    [onDragStateChange],
  );

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        onDragStateChange?.(false, null);
        return;
      }

      const updatedItems = Array.from(draggableItems);
      const [reorderedItem] = updatedItems.splice(result.source.index, 1);
      updatedItems.splice(result.destination.index, 0, reorderedItem);
      setDraggableItems(updatedItems);

      const orderedDataItems = updatedItems.map((item) => item.data);
      onDragStateChange?.(false, null, orderedDataItems);
    },
    [draggableItems, onDragStateChange],
  );

  return (
    <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
      <Droppable droppableId="list">
        {(provided) => (
          <ul {...provided.droppableProps} ref={provided.innerRef} className="space-y-2.5 w-full">
            {draggableItems.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <li
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    className={`bg-gray-50 flex items-center py-2 px-3 rounded-lg min-w-0 ${
                      snapshot.isDragging ? 'opacity-50' : ''
                    }`}
                  >
                    <span
                      {...provided.dragHandleProps}
                      className="mr-3 shrink-0 cursor-move text-gray-400 hover:text-gray-600 transition-colors"
                    >
                      <GripVertical size={14} />
                    </span>
                    <div className="flex-grow min-w-0 w-full">{item.content}</div>
                  </li>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default AnimatedDraggableList;
