import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Box from '@mui/material/Box';

import Sidebar from '../components/Sidebar';
import Settings from '../pages/settings/settings';
import ClientAcquisition from '../pages/ClientAcquisition/clientAcquisition';
import ClientRetention from '../pages/ClientRetention/clientRetention';
import ClientGrowth from '../pages/ClientGrowth/clientGrowth';
import Teams from '../pages/teams/teams';
import MobileNav from '../components/MobileNav';

import { SetCookie, GetCookie } from '../helper/cookieManager';
import { setPage, setFeatureFlags } from '../redux/authSlice';
import { SetupConst } from '../constants/constant';
import featureFlagService from '../services/featureFlagService';
import { useMediaQuery, useTheme, Typography } from '@mui/material';

import ERMDashboard from '@/v0/ERMDashboard';
import OutreachListBuilder from '@/v0/OutreachLists/components/OutreachListBuilder';

const Main = React.memo(() => {
  const [fullSidebar, setFullSidebar] = React.useState(true);
  const [featureFlags, setFeatureFlagsLocal] = React.useState(null);
  const [content, setContent] = React.useState(<div></div>);

  const dispatch = useDispatch();
  const page = useSelector((state) => state.auth.page);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const view = urlParams.get('view');
    const tab = urlParams.get('tab');

    if (view === 'settings' && tab === 'social') {
      dispatch(setPage('settings'));
      // dispatch(selectSettingsTab('social'));
    } else if (view === 'sequence') {
      dispatch(setPage('acquisition'));
    }
  }, [dispatch]);

  React.useEffect(() => {
    const fetchFeatureFlags = async () => {
      try {
        const featureFlags = await featureFlagService.getFeatureFlags();
        setFeatureFlagsLocal(featureFlags);
      } catch (error) {
        console.error('Failed to get feature flags:', error);
      }
    };

    fetchFeatureFlags();
  }, []);

  React.useEffect(() => {
    if (featureFlags) {
      dispatch(setFeatureFlags(featureFlags));
    }
  }, [featureFlags]);

  if (page === 'setup') {
    const luser = GetCookie('user');
    if (luser && luser.redirected) {
      if (luser.callbackStep === SetupConst.Steps.IMPORT_LIST) {
        page = 'importList';
      } else if (luser.callbackStep === SetupConst.Steps.CONNECT) {
        page = 'connectAcc';
      }

      luser.callbackStep = SetupConst.Steps.TONES;
      luser.redirected = false;
      SetCookie('user', JSON.stringify(luser));
    }
    dispatch(setPage(page));
  }

  React.useEffect(() => {
    const checkScreenSize = () => {
      // setFullSidebar(window.innerWidth >= 1280);
      setFullSidebar(true);
    };
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  React.useEffect(() => {
    let newContent;
    switch (page) {
      case 'settings':
        newContent = <Settings />;
        break;
      case 'acquisition':
        newContent = <ClientAcquisition />;
        break;
      case 'retention':
        newContent = <ClientRetention />;
        break;
      case 'clients':
        newContent = <ERMDashboard />;
        break;
      case 'outreachLists':
        // Temporarily jumping straight to the list builder
        newContent = <OutreachListBuilder />;
        break;
      case 'growth':
        newContent = <ClientGrowth />;
        break;
      case 'teams':
        newContent = <Teams />;
        break;
      default:
        newContent = <ClientRetention />;
    }

    setContent(newContent);
  }, [page]);

  return (
    <>
      {isMobile ? (
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              p: 2,
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
          >
            <Typography sx={{ fontSize: '21px', fontWeight: '900' }}>Postilize</Typography>
            <MobileNav />
          </Box>
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            {content}
          </Box>
        </>
      ) : (
        <Box sx={{ display: 'flex', minHeight: '100vh' }}>
          <Sidebar full={fullSidebar} />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              pl: fullSidebar ? '300px' : '88px',
              bgcolor: '#ffffff',
            }}
          >
            {content}
          </Box>
        </Box>
      )}
    </>
  );
});

export default Main;
