import React, { useMemo } from 'react';
import { Box, Link } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import PTypography from '@/components/library/PTypography';
import PCollapsableContainer from '@/components/library/PCollapsableContainer';
import { isBrowserGoogleChrome } from '@/helper/utilities';
import { useChromeExtensionInstalled } from '@/hooks/useChromeExtensionInstalled';
import { fontSize } from '@mui/system';
import { useIsFeatureFlagEnabled } from '@Hooks/useFeatureFlags';

export default function SetupCards() {
  const user = useSelector((state) => state.auth.user);
  const theme = useTheme();
  const isGoogleChrome = isBrowserGoogleChrome();
  const isExtensionInstalled = useChromeExtensionInstalled({ delay: 200, maxAttempts: 50 });
  const setupCardEnabled = useIsFeatureFlagEnabled('ui.setupCards');

  const tasks = useMemo(
    () => [
      {
        name: 'Install Google Chrome',
        description: 'Postilize works best with Google Chrome.',
        link: 'https://www.google.com/chrome/',
        completed: isGoogleChrome,
      },
      {
        name: 'Install iOS App',
        description: 'Download and install the iOS app from the App Store.',
        link: 'https://apps.apple.com/ph/app/postilize/id6503707086',
        completed: user.setupSteps?.connectMobileApp,
      },
      {
        name: 'Install Chrome Extension',
        description: 'Download and install the Chrome extension.',
        link: 'https://chromewebstore.google.com/detail/postilize-digital-assista/eheabolgahkaiemegedcpfeklmdmmbgb',
        completed: isExtensionInstalled && user.setupSteps?.connectLinkedInExtension,
      },
      {
        name: 'Connect LinkedIn',
        description: 'Connect your LinkedIn account to Postilize in Settings > Social.',
        completed: user.providers?.some((provider) => provider.provider === 'linkedin' && provider.active),
      },
      // Add more tasks as needed
    ],
    [user, isExtensionInstalled],
  );

  // Filter tasks that are not completed
  const incompleteTasks = useMemo(() => tasks.filter((task) => !task.completed), [tasks]);

  const containerColor = alpha(theme.palette.primaryCL.Orange100, 0.08);
  const linkColor = theme.palette.primaryCL.Orange100;

  const renderedTasks = useMemo(
    () =>
      incompleteTasks.map((task, index) => (
        <Box key={index} sx={{ paddingBottom: 1.5, paddingTop: 1 }}>
          <PTypography
            size="body2"
            color="textSecondary"
            sx={{ marginBottom: '0.35em', fontWeight: 400, fontSize: '0.95rem' }}
          >
            {task.description}
          </PTypography>
          {task.link && (
            <Link href={task.link} target="_blank" rel="noopener" sx={{ color: linkColor }}>
              {`Go to ${task.name}`}
            </Link>
          )}
        </Box>
      )),
    [incompleteTasks],
  );
  if (incompleteTasks.length === 0) {
    return null; // No incomplete tasks to show
  }
  return setupCardEnabled ? (
    <PCollapsableContainer title={'Finish Your Setup'} backgroundColor={containerColor}>
      {renderedTasks}
    </PCollapsableContainer>
  ) : null;
}
