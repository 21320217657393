// Turns any nested object of objects into an array of all 'leaf' values inside the nested structure
const flattenObjectValues = (obj) => {
  return Object.values(obj).flatMap((val) => (typeof val === 'object' ? flattenObjectValues(val) : val));
};

// Freezes any nested object of objects
const deepFreeze = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object' && obj[key] !== null) deepFreeze(obj[key]);
  });
  return Object.freeze(obj);
};

// A class that allows for direct access to properties of an object and a flattened array of its values
// It also freezes the object and prevents modification
// Since it is both frozen and flattened, it is called an 'IceSheet'
// We don't store the keys because they are the same as the object keys
class IceSheet {
  constructor(obj) {
    this.data = deepFreeze(obj);
    this.flatValues = flattenObjectValues(obj);

    // Return a proxy to allow direct access to properties
    return new Proxy(this, {
      get(target, prop) {
        if (prop === 'flatValues') {
          return target.getFlatValues();
        }
        return target.data[prop];
      },
      set() {
        throw new Error('Cannot modify a frozen object');
      },
    });
  }

  getFlatValues() {
    return this.flatValues;
  }
}

module.exports = {
  deepFreeze,
  flattenObjectValues,
  IceSheet,
};
