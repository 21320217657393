'use client';

import * as React from 'react';
import { Users, Linkedin, Puzzle, Apple, List, ArrowUpRight, Check, ExternalLink } from 'lucide-react';
import { Progress } from '@/components/ui/progress';
import { cn } from '@/lib/utils';
import { useDispatch, useSelector } from 'react-redux';
import { setPage, setSettingsPageOverride } from '@/redux/authSlice';
import { useChromeExtensionInstalled } from '@/hooks/useChromeExtensionInstalled';

export function VZeroOnboardingCompletionPanel() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const isExtensionInstalled = useChromeExtensionInstalled({ delay: 200, maxAttempts: 50 });

  const isLinkedInConnected = user.providers?.some((provider) => provider.provider === 'linkedin' && provider.active);

  const progressItems = React.useMemo(
    () => [
      {
        text: 'Account created',
        completed: true,
        icon: Users,
      },
      {
        text: 'LinkedIn connected',
        completed: isLinkedInConnected,
        icon: Linkedin,
        onClick: () => {
          dispatch(setSettingsPageOverride('Social'));
          dispatch(setPage({ payload: 'settings' }));
        },
      },
      {
        text: 'Chrome extension',
        completed: isExtensionInstalled && user.setupSteps?.connectLinkedInExtension,
        icon: Puzzle,
        onClick: () => {
          window.open(
            'https://chromewebstore.google.com/detail/postilize-digital-assista/eheabolgahkaiemegedcpfeklmdmmbgb',
            '_blank',
          );
        },
        extraIcon: true,
      },
      {
        text: 'iOS app',
        completed: user.setupSteps?.connectMobileApp,
        icon: Apple,
        onClick: () => {
          window.open('https://apps.apple.com/ph/app/postilize/id6503707086', '_blank');
        },
        extraIcon: true,
      },
      {
        text: 'Social topics',
        completed: (user.topics?.length ?? 0) > 0,
        icon: List,
        onClick: () => {
          dispatch(setSettingsPageOverride('Topics'));
          dispatch(setPage({ payload: 'settings' }));
        },
      },
    ],
    [isLinkedInConnected, user.setupSteps, user.topics, isExtensionInstalled, dispatch],
  );

  const progress = React.useMemo(() => {
    const completedItems = progressItems.filter((item) => item.completed).length;
    return Math.round((completedItems / progressItems.length) * 100);
  }, [progressItems]);

  if (progress === 100) {
    return null;
  }

  return (
    <div className="rounded-xl border bg-card p-4">
      <div className="space-y-6">
        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <h3 className="text-base font-semibold tracking-tight">Setup Progress</h3>
            <span className="font-semibold text-base">{progress}%</span>
          </div>
          <Progress value={progress} className="h-2" />
        </div>

        <div className="space-y-4">
          <h4 className="text-base font-bold text-muted-foreground">Required Steps:</h4>
          <div className="space-y-4">
            {progressItems.map((item, index) => {
              const ItemIcon = item.icon;
              return (
                <div
                  key={index}
                  className={cn(
                    'grid grid-cols-[24px_1fr_24px] items-center gap-2',
                    item.completed || !item.onClick ? 'cursor-default' : 'cursor-pointer',
                  )}
                  onClick={() => !item.completed && item.onClick?.()}
                >
                  <ItemIcon className="h-5 w-5 text-muted-foreground" />
                  <span
                    className={cn(
                      'text-sm font-semibold',
                      item.completed ? 'text-muted-foreground line-through' : 'text-foreground',
                    )}
                  >
                    {item.text}
                  </span>
                  {item.completed ? (
                    <Check className="h-5 w-5 text-green-500 justify-self-end" />
                  ) : item.extraIcon ? (
                    <ExternalLink className="h-5 w-5 text-muted-foreground justify-self-end" />
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
