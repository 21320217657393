'use client';

import { useDispatch, useSelector } from 'react-redux';
import { Search, Send, Linkedin, MapPin, Building2, Globe } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Badge } from '@/components/ui/badge';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Checkbox } from '@/components/ui/checkbox';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import {
  searchContacts,
  setQuery,
  setSelectedContacts,
  setSelectedTitles,
  setLocation,
  setSelectedIndustries,
  setSelectedSize,
  setKnownBy,
  setCompanyRevenue,
  setTenure,
  setCompany,
  addAvailableTitle,
  addAvailableIndustry,
  performSearch,
  selectAllContacts,
} from '@/redux/outreachListsSlice';
import React, { useState } from 'react';
import { getCountryFlag } from './mockdataHelpers';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

// Add this CSS animation to your global styles or component
const newItemAnimation = `
  @keyframes popIn {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
  }
`;

// Add this helper function at the top of the file
function parseLocation(location: string) {
  const parts = location.split(',').map((part) => part.trim());
  const parsedLocation = {
    city: parts[0],
    state: parts.length > 2 ? parts[1] : null,
    country: parts[parts.length - 1],
  };
  return parsedLocation;
}

function formatSizeDisplay(sizeRange: string): string {
  // Just return the size range as is, with proper number formatting
  if (sizeRange.endsWith('+')) {
    const size = parseInt(sizeRange.slice(0, -1));
    return `${size.toLocaleString()}+`;
  } else {
    const [min, max] = sizeRange.split('-').map((num) => parseInt(num));
    return `${min.toLocaleString()}-${max.toLocaleString()}`;
  }
}

// SearchAndFilter Component
function SearchAndFilter({ onCriteriaChange }: { onCriteriaChange: () => void }) {
  const dispatch = useDispatch();
  const { searchCriteria, availableOptions } = useSelector((state) => state.outreachLists);

  // State for new title and industry inputs
  const [newTitle, setNewTitle] = useState('');
  const [newIndustry, setNewIndustry] = useState('');
  const [showTitleInput, setShowTitleInput] = useState(false);
  const [showIndustryInput, setShowIndustryInput] = useState(false);

  const handleTitleChange = (titles: string[]) => {
    dispatch(setSelectedTitles(titles));
    onCriteriaChange();
  };

  const handleLocationChange = (location: string) => {
    dispatch(setLocation(location));
    onCriteriaChange();
  };

  const handleIndustryChange = (industries: string[]) => {
    dispatch(setSelectedIndustries(industries));
    onCriteriaChange();
  };

  const handleSizeChange = (sizes: string[]) => {
    dispatch(setSelectedSize(sizes));
    onCriteriaChange();
  };

  const addNewTitle = () => {
    if (newTitle.trim()) {
      const displayTitle = newTitle.trim();
      dispatch(addAvailableTitle({ displayTitle, variations: [] }));
      handleTitleChange([...searchCriteria.selectedTitles, displayTitle]);
      setNewTitle('');
      setShowTitleInput(false);
    }
  };

  const addNewIndustry = () => {
    if (newIndustry.trim()) {
      dispatch(addAvailableIndustry(newIndustry.trim()));
      handleIndustryChange([...searchCriteria.selectedIndustries, newIndustry.trim()]);
      setNewIndustry('');
      setShowIndustryInput(false);
    }
  };

  return (
    <div className="space-y-4">
      {/* Titles */}
      <div>
        <h3 className="text-sm font-medium mb-2">Title</h3>
        <div className="flex flex-wrap gap-2 items-center">
          {Object.keys(availableOptions.titles).map((displayTitle) => (
            <Badge
              key={displayTitle}
              variant={searchCriteria.selectedTitles.includes(displayTitle) ? 'default' : 'outline'}
              className={`cursor-pointer ${searchCriteria.selectedTitles.includes(displayTitle) ? '' : 'hover:bg-slate-100 hover:text-slate-900'}`}
              title={[displayTitle, ...availableOptions.titles[displayTitle]].join(', ')}
              onClick={() =>
                handleTitleChange(
                  searchCriteria.selectedTitles.includes(displayTitle)
                    ? searchCriteria.selectedTitles.filter((t) => t !== displayTitle)
                    : [...searchCriteria.selectedTitles, displayTitle],
                )
              }
            >
              {displayTitle}
            </Badge>
          ))}

          {showTitleInput ? (
            <div className="flex gap-2 items-center">
              <Input
                value={newTitle}
                onChange={(e) => setNewTitle(e.target.value)}
                className="h-8 w-40"
                placeholder="New title..."
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    addNewTitle();
                  }
                }}
              />
              <Button variant="ghost" size="sm" onClick={() => setShowTitleInput(false)} className="h-8">
                Cancel
              </Button>
            </div>
          ) : (
            <Button
              variant="outline"
              size="sm"
              onClick={() => setShowTitleInput(true)}
              className="rounded-full h-6 w-6 p-0"
            >
              +
            </Button>
          )}
        </div>
      </div>

      {/* Location */}
      <div>
        <h3 className="text-sm font-medium mb-2">Location</h3>
        <Input
          placeholder="e.g., San Francisco, CA"
          value={searchCriteria.location}
          onChange={(e) => handleLocationChange(e.target.value)}
        />
      </div>

      {/* Industries */}
      <div>
        <h3 className="text-sm font-medium mb-2">Industry</h3>
        <div className="flex flex-wrap gap-2 items-center">
          {Object.entries(availableOptions.industries).map(([industry, variations]) => (
            <Badge
              key={industry}
              variant={searchCriteria.selectedIndustries.includes(industry) ? 'default' : 'outline'}
              className={`cursor-pointer ${searchCriteria.selectedIndustries.includes(industry) ? '' : 'hover:bg-slate-100 hover:text-slate-900'}`}
              title={[industry, ...variations].join(', ')}
              onClick={() =>
                handleIndustryChange(
                  searchCriteria.selectedIndustries.includes(industry)
                    ? searchCriteria.selectedIndustries.filter((i) => i !== industry)
                    : [...searchCriteria.selectedIndustries, industry],
                )
              }
            >
              {industry}
            </Badge>
          ))}

          {showIndustryInput ? (
            <div className="flex gap-2 items-center">
              <Input
                value={newIndustry}
                onChange={(e) => setNewIndustry(e.target.value)}
                className="h-8 w-40"
                placeholder="New industry..."
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    addNewIndustry();
                  }
                }}
              />
              <Button variant="ghost" size="sm" onClick={() => setShowIndustryInput(false)} className="h-8">
                Cancel
              </Button>
            </div>
          ) : (
            <Button
              variant="outline"
              size="sm"
              onClick={() => setShowIndustryInput(true)}
              className="rounded-full h-6 w-6 p-0"
            >
              +
            </Button>
          )}
        </div>
      </div>

      {/* Company Size */}
      <div>
        <h3 className="text-sm font-medium mb-2">Company Size</h3>
        <div className="flex flex-wrap gap-2">
          {availableOptions.companySizes.map((size) => (
            <Badge
              key={size}
              variant={searchCriteria.selectedSize.includes(size) ? 'default' : 'outline'}
              className="cursor-pointer"
              onClick={() =>
                handleSizeChange(
                  searchCriteria.selectedSize.includes(size)
                    ? searchCriteria.selectedSize.filter((s) => s !== size)
                    : [...searchCriteria.selectedSize, size],
                )
              }
            >
              {formatSizeDisplay(size)}
            </Badge>
          ))}
        </div>
      </div>

      {/* Known By */}
      <div>
        <h3 className="text-sm font-medium mb-2">Known By</h3>
        <div className="flex flex-wrap gap-2">
          <Badge
            key="nobody"
            variant={searchCriteria.knownBy.includes('Nobody') ? 'default' : 'gray'}
            className="cursor-pointer"
            onClick={() => {
              // If Nobody is being selected, clear other selections
              if (!searchCriteria.knownBy.includes('Nobody')) {
                dispatch(setKnownBy(['Nobody']));
              } else {
                dispatch(setKnownBy([]));
              }
              onCriteriaChange();
            }}
          >
            Nobody
          </Badge>
          {availableOptions.knownByOptions.map((person) => (
            <Badge
              key={person}
              variant={searchCriteria.knownBy.includes(person) ? 'default' : 'gray'}
              className="cursor-pointer"
              onClick={() => {
                let newSelection: string[];
                if (searchCriteria.knownBy.includes(person)) {
                  // Remove person if already selected
                  newSelection = searchCriteria.knownBy.filter((p) => p !== person);
                } else {
                  // Add person and remove 'Nobody' if it was selected
                  newSelection = [...searchCriteria.knownBy.filter((p) => p !== 'Nobody'), person];
                }
                dispatch(setKnownBy(newSelection));
                onCriteriaChange();
              }}
            >
              {person}
            </Badge>
          ))}
        </div>
      </div>
    </div>
  );
}

// SearchResults Component
function SearchResults({ isSearching }: { isSearching: boolean }) {
  const dispatch = useDispatch();
  const { searchResults, selectedContacts } = useSelector((state) => ({
    searchResults: state.outreachLists.data?.searchResults || [],
    selectedContacts: state.outreachLists?.ui?.selectedContacts || [],
  }));

  const allSelected = searchResults.length > 0 && selectedContacts.length === searchResults.length;

  const handleSelectAll = (checked: boolean) => {
    dispatch(selectAllContacts(checked));
  };

  const handleContactSelection = (checked: boolean, contactId: string) => {
    dispatch(
      setSelectedContacts(
        checked ? [...selectedContacts, contactId] : selectedContacts.filter((id) => id !== contactId),
      ),
    );
  };

  if (isSearching) {
    return (
      <div className="flex justify-center items-center py-8">
        <div className="flex flex-col items-center space-y-4">
          <svg className="animate-spin h-8 w-8 text-primary" /* ... */ />
          <span className="text-sm text-muted-foreground">Searching contacts...</span>
        </div>
      </div>
    );
  }

  if (searchResults?.length === 0) return null;

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-[20px]">
            <div className="flex items-center justify-center w-4 h-4">
              <Checkbox checked={allSelected} onCheckedChange={(checked) => handleSelectAll(checked as boolean)} />
            </div>
          </TableHead>
          <TableHead className="w-[300px]">Contact</TableHead>
          <TableHead className="w-[200px]">Company</TableHead>
          <TableHead className="w-[140px]">Size</TableHead>
          <TableHead className="w-[100px]">Network</TableHead>
          <TableHead className="w-[50px]">Location</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {searchResults?.map((contact) => (
          <TableRow key={contact.id} className="items-center">
            <TableCell className="h-full">
              <div className="flex items-center justify-center w-4 h-4">
                <Checkbox
                  checked={selectedContacts.includes(contact.id)}
                  onCheckedChange={(checked) => {
                    handleContactSelection(checked as boolean, contact.id);
                  }}
                />
              </div>
            </TableCell>
            <TableCell>
              <div className="space-y-0.5">
                <div className="flex items-center gap-1">
                  <span className="font-medium">{contact.name}</span>
                  {contact.linkedin_url && (
                    <a
                      href={contact.linkedin_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="LinkedIn Profile"
                    >
                      <Linkedin className="h-4 w-4 text-primary" />
                    </a>
                  )}
                </div>
                <div className="text-xs text-gray-500">{contact.title}</div>
                <div className="text-xs text-gray-400">Score: {contact.relationshipScore}</div>
              </div>
            </TableCell>
            <TableCell>
              <div className="space-y-0.5">
                <div className="font-medium">{contact.company}</div>
                {contact.companyData?.industry && (
                  <div className="text-xs text-gray-500">{contact.companyData.industry}</div>
                )}
                {contact.companyData && (
                  <div className="text-xs text-gray-400">Score: {contact.companyData.relationshipScore}</div>
                )}
              </div>
            </TableCell>
            <TableCell>{contact.companyData?.employees?.toLocaleString()} employees</TableCell>
            <TableCell>
              <div className="flex flex-wrap gap-1">
                {contact.knownBy.map((person) => (
                  <Badge key={person} variant="secondary" className="text-xs">
                    {person}
                  </Badge>
                ))}
              </div>
            </TableCell>
            <TableCell>
              {contact.location &&
                (() => {
                  const { city, state, country } = parseLocation(contact.location);
                  return (
                    <div className="flex flex-col gap-1">
                      <div className="flex items-start gap-1.5">
                        <MapPin className="h-4 w-4 text-primary/70 mt-0.5" />
                        <div className="flex flex-col">
                          <div className="font-medium text-sm leading-tight">{city}</div>
                          {state && <div className="text-sm text-muted-foreground leading-tight">{state}</div>}
                          <div className="flex items-center gap-1 mt-1">
                            <span className="text-xs font-medium bg-primary/10 text-primary rounded-full px-2 py-0.5 flex items-center gap-1">
                              <span className="text-base leading-none">{getCountryFlag(country)}</span>
                              {country}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })()}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

// Main Component
export default function OutreachListBuilder() {
  const dispatch = useDispatch();
  const { query, showCriteria, isSearching, searchResults, selectedContacts } = useSelector((state) => ({
    query: state.outreachLists.searchCriteria.query,
    showCriteria: state.outreachLists.ui.showCriteria,
    isSearching: state.outreachLists.ui.isSearching,
    searchResults: state.outreachLists.data?.searchResults || [],
    selectedContacts: state.outreachLists.ui.selectedContacts || [],
  }));

  const handleSearch = async () => {
    if (query.trim()) {
      try {
        await dispatch(searchContacts(query)).unwrap();
        // Immediately perform search with new criteria
        await dispatch(performSearch()).unwrap();
      } catch (error) {
        console.error('Search failed:', error);
      }
    }
  };

  const handleCriteriaChange = () => {
    dispatch(searchContacts()); // Trigger search with updated criteria
  };

  return (
    <div className="container w-full mx-4 py-6 space-y-8">
      <h1 className="text-2xl font-bold">Client Lists</h1>

      <Card>
        <CardHeader>
          <CardTitle>Search Contacts</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="space-y-6">
            <div className="flex gap-2">
              <div className="relative flex-1">
                <Search className="absolute left-3 top-3 h-4 w-4 text-muted-foreground" />
                <Input
                  placeholder="e.g. Founders based in Toronto focused on biotechnology with employees less than 50"
                  value={query}
                  onChange={(e) => dispatch(setQuery(e.target.value))}
                  className="pl-10"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSearch();
                    }
                  }}
                />
              </div>
              <Button onClick={handleSearch} disabled={!query.trim() || isSearching}>
                {isSearching ? (
                  <div className="flex items-center gap-2">
                    <div className="animate-spin h-4 w-4 border-2 border-current border-t-transparent rounded-full" />
                    Searching...
                  </div>
                ) : (
                  <div className="flex items-center gap-2">
                    <Search className="h-4 w-4" />
                    Search
                  </div>
                )}
              </Button>
            </div>

            <SearchAndFilter onCriteriaChange={handleCriteriaChange} />
          </div>
        </CardContent>
      </Card>

      {searchResults?.length > 0 && (
        <Card>
          <CardHeader>
            <div className="flex justify-between items-center">
              <CardTitle>Results</CardTitle>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="outline" size="sm" disabled={selectedContacts?.length === 0}>
                    <Send className="h-3 w-3 mr-2" /> Engage
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuItem
                    onSelect={() => {
                      /* Handle Request Meeting */
                    }}
                  >
                    Request Meeting
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onSelect={() => {
                      /* Handle Check-in */
                    }}
                  >
                    Check-in
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onSelect={() => {
                      /* Handle Invite to event */
                    }}
                  >
                    Invite to event
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </CardHeader>
          <CardContent>
            <SearchResults isSearching={isSearching} />
          </CardContent>
        </Card>
      )}
    </div>
  );
}
