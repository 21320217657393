import { CircularProgress } from '@mui/material';
import { SortableHeader } from './SortableHeader';
import { Button } from '@/components/ui/button';
import { useClientTable } from './useClientTable';
import { cn } from '@/lib/utils';
import React, { useRef, useEffect, useState, useMemo } from 'react';
import { InteractionsCell } from './InteractionsCell';
import { Users, Eye, EyeOff, LinkedinIcon, Mail, Calendar, UserPlus } from 'lucide-react';
import { Tooltip, TooltipProvider, TooltipTrigger, TooltipContent } from '@/components/ui/tooltip';
import { useIsFeatureFlagEnabled } from '@Hooks/useFeatureFlags';
import useOutsideClick from '@Hooks/useOutsideClick';
import { ERMPagination } from './ERMPagination';
import { Label } from '@/components/ui/label';
import { Switch } from '@/components/ui/switch';
import { StagePicker } from '../../StageManagement/stage-picker';
import { StageManager } from '../../StageManagement/stage-manager';
import { Stage } from '../../StageManagement/types';
import { useSelector } from 'react-redux';
import { NO_STAGE_DEFINITION } from '@postilize/shared/constants/ERM';
import { updateCompanyClientStage } from '@/helper/apiHelper';
import { useDispatch } from 'react-redux';
import { updateClientStage } from '@/redux/ermDashboardSlice';
import { StageBadge } from '../../StageManagement/stage-badge';

const getColor = (progress: number) => {
  if (progress < 0.25) return '#ef4444';
  if (progress < 0.5) return '#f97316';
  if (progress < 0.75) return '#eab308';
  return '#22c55e';
};

export const ClientTable = () => {
  const {
    clients,
    page,
    limit,
    total,
    highestRelationshipScore,
    isLoading,
    isClientSettingStage,
    searchTerm,
    selectedClient,
    showHidden,
    sortCriteria,
    stageFilters,
    lastInteractionFilters,
    userCompany,

    handleSearchTermChange,
    handleStageChange,
    handleToggleClientVisibility,
    onClientSelect,
    onSort,
    toggleLastInteractionFilter,
    toggleStageFilter,
    handlePageChange,
    handleToggleShowHidden,
  } = useClientTable();

  const strengthFeatureEnabled = useIsFeatureFlagEnabled('ui.erm_dashboard.strength');
  const [openPopoverId, setOpenPopoverId] = useState<string | null>(null);

  const [stagePickerOpen, setStagePickerOpen] = useState<string | null>(null);
  const [stageManagerOpen, setStageManagerOpen] = useState<boolean>(false);
  const stagePickerRef = useRef<HTMLDivElement>(null);
  const initialStages = useSelector((state) => state.auth.company?.company?.ermPreference?.pipelineStages);

  const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });

  const dispatch = useDispatch();

  const handleStageSelection = async (clientId: string, stageId: string) => {
    try {
      console.log('handleStageSelection updating', clientId, stageId);
      await dispatch(updateClientStage({ clientId, newStageId: stageId })).unwrap();
    } catch (error) {
      console.error('Error updating client stage:', error);
    } finally {
      setStagePickerOpen(null);
    }
  };

  const calculatePopoverPosition = (element: HTMLElement) => {
    const rect = element?.getBoundingClientRect();
    if (!rect) {
      setPopoverPosition({
        top: window.innerHeight / 2 + window.scrollY,
        left: window.innerWidth / 2 + window.scrollX,
      });
    } else {
      setPopoverPosition({
        top: rect.top + window.scrollY,
        left: rect.left + window.scrollX,
      });
    }
  };

  const availableStages = useMemo(() => {
    const allStages = [NO_STAGE_DEFINITION, ...initialStages];
    return allStages.map((stage) => ({
      ...stage,
      checked: stageFilters[stage._id] || true,
    }));
  }, [initialStages, stageFilters]);

  const stageFilterOptions = availableStages.map((stage) => ({
    label: (
      <StageBadge
        initialText={stage.name}
        backgroundColor={stage.backgroundColor}
        textColor={stage.textColor}
        isEditing={false}
        editable={false}
      />
    ),
    value: stage.name,
    checked: stageFilters[stage.name] || false,
  }));

  return (
    <div className="border shadow-sm rounded-lg p-2">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-lg font-semibold text-muted-foreground">
          Client List for
          <span className="text-black"> {userCompany?.name}</span>
        </h2>
        <div className="flex items-center space-x-2">
          <Switch id="show-hidden" checked={showHidden} onCheckedChange={handleToggleShowHidden} />
          <Label htmlFor="show-hidden" className="text-sm text-muted-foreground">
            {showHidden ? 'Showing Hidden Clients' : 'Showing Active Clients'}
          </Label>
        </div>
      </div>
      <div className="relative w-full overflow-auto" style={{ paddingBottom: '10px' }}>
        <table className="w-full caption-bottom text-sm">
          <thead className="[&_tr]:border-b">
            <tr className="border-b transition-colors data-[state=selected]:bg-muted">
              <SortableHeader
                label="Client Name"
                sortKey="name"
                currentSort={sortCriteria}
                onSort={onSort}
                onNameTextFilterChange={handleSearchTermChange}
                searchValue={searchTerm}
              />
              {!showHidden && strengthFeatureEnabled && (
                <SortableHeader
                  label="Strength"
                  sortKey="relationshipScore"
                  currentSort={sortCriteria}
                  onSort={onSort}
                />
              )}
              {!showHidden && (
                <SortableHeader
                  label="Stage"
                  sortKey="stage"
                  currentSort={sortCriteria}
                  onSort={onSort}
                  filterOptions={stageFilterOptions}
                  onFilterChange={toggleStageFilter}
                />
              )}
              <SortableHeader
                label="Last Interaction"
                sortKey="lastInteraction"
                currentSort={sortCriteria}
                onSort={onSort}
                filterOptions={lastInteractionFilters}
                onFilterChange={toggleLastInteractionFilter}
                filterIcons={{
                  'Incoming LinkedIn InMails': <LinkedinIcon className="h-4 w-4" />,
                  'Outgoing LinkedIn InMails': <LinkedinIcon className="h-4 w-4" />,
                  'Incoming Emails': <Mail className="h-4 w-4" />,
                  'Outgoing Emails': <Mail className="h-4 w-4" />,
                  'Calendar Meetings': <Calendar className="h-4 w-4" />,
                  'Added as Contact': <UserPlus className="h-4 w-4" />,
                }}
              />
              <th
                className="h-10 px-2 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0"
                style={{ width: '150px' }}
              >
                Actions
              </th>
            </tr>
          </thead>
          {!isLoading && clients?.length > 0 && (
            <tbody className="[&_tr:last-child]:border-0">
              {clients.map((client) => (
                <tr
                  key={client?._id}
                  className={cn(
                    'border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted',
                    client?._id === selectedClient?._id && 'bg-gray-200/50',
                  )}
                >
                  <td className="p-2 align-middle [&:has([role=checkbox])]:pr-0">
                    <span
                      className="cursor-pointer font-semibold hover:underline"
                      onClick={() => onClientSelect(client)}
                    >
                      {client?.name}
                    </span>
                  </td>
                  {!showHidden && strengthFeatureEnabled && (
                    <td className="p-2 align-middle [&:has([role=checkbox])]:pr-0">
                      <span
                        style={{
                          marginLeft: '15px',
                          // color: getColor((client?.relationshipScore || 0) / highestRelationshipScore),
                        }}
                      >
                        {client?.relationshipScore || 0}
                      </span>
                    </td>
                  )}
                  {!showHidden && (
                    <td className="p-2 align-middle [&:has([role=checkbox])]:pr-0">
                      <div className="relative">
                        {isClientSettingStage[client?._id] ? (
                          <CircularProgress size={16} color="inherit" />
                        ) : (
                          <span ref={stagePickerOpen === client?._id ? stagePickerRef : null}>
                            <StagePicker
                              open={stagePickerOpen === client?._id && !stageManagerOpen}
                              client={client}
                              stages={availableStages}
                              currentStageId={(() => {
                                console.log('clienttable client', client);
                                console.log('clienttable client?.stage', client?.stage);
                                return client?.stage || NO_STAGE_DEFINITION._id;
                              })()}
                              onStageSelect={handleStageSelection}
                              onBaseBadgeClick={(e) => {
                                const targetElement = e.target as HTMLElement;
                                stagePickerRef.current = targetElement;
                                calculatePopoverPosition(targetElement);
                                setStagePickerOpen(client?._id);
                              }}
                              onEditStagesClick={(e) => {
                                setStageManagerOpen(true);
                              }}
                              onOpenChange={(open) => {
                                if (!open && !stageManagerOpen) {
                                  setStagePickerOpen(null);
                                }
                              }}
                            />
                          </span>
                        )}
                      </div>
                    </td>
                  )}
                  <td className="p-2 align-middle [&:has([role=checkbox])]:pr-0 max-w-xs truncate overflow-ellipsis whitespace-nowrap">
                    <InteractionsCell interaction={client?.lastInteraction} />
                  </td>
                  <td className="p-2 align-middle [&:has([role=checkbox])]:pr-0" style={{ width: '150px' }}>
                    <TooltipProvider>
                      <div className="flex items-center justify-between gap-2 group">
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button variant="outline" size="sm" onClick={() => onClientSelect(client)}>
                              <Users className="h-4 w-4" />
                              <span className="sr-only">Relationships</span>
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>Relationships</TooltipContent>
                        </Tooltip>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              variant="ghost"
                              size="sm"
                              className="opacity-0 group-hover:opacity-100 transition-opacity"
                              onClick={() =>
                                showHidden
                                  ? handleToggleClientVisibility(client?._id, 'show')
                                  : handleToggleClientVisibility(client?._id, 'hide')
                              }
                            >
                              {showHidden ? <Eye className="h-4 w-4" /> : <EyeOff className="h-4 w-4" />}
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>{showHidden ? 'Unhide Client' : 'Hide Client'}</TooltipContent>
                        </Tooltip>
                      </div>
                    </TooltipProvider>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
        {isLoading && (
          <div className="flex justify-center items-center mt-4">
            <CircularProgress size={32} color="inherit" />
          </div>
        )}
        {clients?.length === 0 && !isLoading && (
          <div className="flex justify-center items-center mt-4">
            <p className="text-muted-foreground">
              {showHidden ? 'No hidden clients found' : 'No active clients found'}
            </p>
          </div>
        )}
      </div>

      <StageManager
        open={stageManagerOpen}
        onOpenChange={(open) => {
          if (!open) {
            setStageManagerOpen(false);
          }
        }}
        stages={initialStages}
        onStageSelect={handleStageSelection}
        triggerRef={stagePickerRef}
        top={popoverPosition.top}
        left={popoverPosition.left}
      />
      <ERMPagination currentPage={page} totalPages={Math.ceil(total / limit)} onPageChange={handlePageChange} />
    </div>
  );
};
