import React, { useState, useEffect, useCallback, memo, useRef, useMemo } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Card } from '@/components/ui/card';
import { Avatar } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { Edit, X, Check, ChevronRight, Linkedin, Mail } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { replaceEmailBodyPlaceholders } from '../../../helper/emailUtility';
import { TopicType } from '@/constants/constant';
import { useIsFeatureFlagEnabled } from '@Hooks/useFeatureFlags';

import defaultAvatar from '@/assets/avatar/img.png';

interface EmailType {
  _id?: string;
  subject?: string;
  body?: string;
  isPersonalized?: boolean;
}

interface ContactDetails {
  email?: string;
  name?: string;
  linkedin?: string;
}

interface SequenceAttr {
  contactDetails?: ContactDetails;
  sequenceName?: string;
}

interface PostType {
  personalizedEmails?: EmailType[];
  sequenceAttr?: SequenceAttr;
  messagingPlatform?: string;
  ImageUrl?: string;
  scheduledDate?: Date | string;
  topic_type?: string;
}

interface UpdatedContent {
  message: string;
  subject: string;
}

interface PersonalizedEmailCardProps {
  post: PostType;
  onPostUpdate?: (updatedContent: UpdatedContent) => void;
  onButtonClick: (event: React.MouseEvent<HTMLButtonElement>, post: PostType, options: { id: string }) => void;
  handleSendToMessage: (
    postData: PostType,
    platform: string,
    setPost: React.Dispatch<React.SetStateAction<PostType>>,
  ) => void;
  setPost: React.Dispatch<React.SetStateAction<PostType>>;
  onContactClick?: () => void;
}

interface ContactOption {
  isEnabled: boolean;
  name: string;
  icon: React.ComponentType<{ className?: string }>;
  key: string;
}

const PersonalizedEmailCard: React.FC<PersonalizedEmailCardProps> = memo(
  ({ post, onPostUpdate, onButtonClick, handleSendToMessage, setPost, onContactClick }) => {
    const messages = useMemo(() => post.personalizedEmails || [], [post.personalizedEmails]);
    const contact = useMemo(() => post.sequenceAttr?.contactDetails || {}, [post.sequenceAttr?.contactDetails]);

    const tabValues = messages.map((_, index) => `message${index}`);

    const [activeTab, setActiveTab] = useState<string>(tabValues[0] || 'message0');

    const contactOptions: ContactOption[] = useMemo(
      () => [
        {
          key: 'email',
          name: 'Email',
          icon: Mail,
          isEnabled: !!contact.email,
        },
        {
          key: 'linkedin',
          name: 'LinkedIn',
          icon: Linkedin,
          isEnabled: !!contact.linkedin,
        },
      ],
      [contact.email, contact.linkedin],
    );

    const defaultPlatform = useMemo(() => {
      if (
        post.messagingPlatform &&
        contactOptions.some((option) => option.name === post.messagingPlatform && option.isEnabled)
      ) {
        return post.messagingPlatform;
      } else {
        const firstEnabledOption = contactOptions.find((option) => option.isEnabled);
        return firstEnabledOption ? firstEnabledOption.name : contactOptions[0].name;
      }
    }, [post.messagingPlatform, contact.email, contact.linkedin]);

    const [selectedPlatform, setSelectedPlatform] = useState<string>(defaultPlatform);

    const userSelectedPlatform = useRef(false);

    useEffect(() => {
      const isValidPlatform = contactOptions.some((option) => option.name === selectedPlatform && option.isEnabled);
      if (!isValidPlatform) {
        if (!userSelectedPlatform.current) {
          if (selectedPlatform !== defaultPlatform) {
            setSelectedPlatform(defaultPlatform);
          }
        } else {
          const firstEnabledOption = contactOptions.find((option) => option.isEnabled);
          const newSelectedPlatform = firstEnabledOption ? firstEnabledOption.name : contactOptions[0].name;

          if (selectedPlatform !== newSelectedPlatform) {
            setSelectedPlatform(newSelectedPlatform);
          }
        }
      }
    }, [contact.email, contact.linkedin]);

    const messagePlatformEnabled = useIsFeatureFlagEnabled('ui.sendToMessagePlatform');
    const handleDropdownClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
    }, []);

    const handleDirectSendToMessage = useCallback(
      (postData: PostType, platform: string) => {
        handleSendToMessage(postData, platform, setPost);
      },
      [handleSendToMessage, setPost],
    );

    useEffect(() => {
      const messageIndex = tabValues.indexOf(activeTab);
      if (messages[messageIndex]) {
        const updatedContent = {
          message: messages[messageIndex].body || '',
          subject: messages[messageIndex].subject || '',
        };
        onPostUpdate && onPostUpdate(updatedContent);
      }
    }, [activeTab, messages]);

    const [imgSrc, setImgSrc] = useState<string>(post.ImageUrl || defaultAvatar);
    useEffect(() => {
      setImgSrc(post.ImageUrl || defaultAvatar);
    }, [post.ImageUrl]);

    if (messages.length === 0) {
      return <Card className="p-4">No personalized emails available.</Card>;
    }

    const handlePlatformSelect = (optionName: string) => {
      userSelectedPlatform.current = true;
      setSelectedPlatform(optionName);
      handleDirectSendToMessage(post, optionName);
    };

    return (
      <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
        <TabsList className="grid w-full" style={{ gridTemplateColumns: `repeat(${messages.length}, 1fr)` }}>
          {messages.map((email, index) => (
            <TabsTrigger key={index} value={`message${index}`}>
              {email.isPersonalized === false ? 'Unpersonalized' : `Personalized #${index + 1}`}
            </TabsTrigger>
          ))}
        </TabsList>

        {messages.map((email, index) => (
          <TabsContent key={email._id || index} value={`message${index}`}>
            <Card className="p-4">
              <div className="flex items-start gap-3">
                <Avatar className="h-8 w-8 cursor-pointer" onClick={onContactClick}>
                  <img src={imgSrc} alt="Profile" className="rounded-full" onError={() => setImgSrc(defaultAvatar)} />
                </Avatar>
                <div className="flex-1">
                  <div className="flex items-center justify-between">
                    <div className="cursor-pointer" onClick={onContactClick}>
                      <h3 className="font-semibold">
                        {contact.name} - {contact.email}
                      </h3>
                      <p className="text-sm font-medium">{email.subject}</p>
                    </div>
                    {post?.scheduledDate &&
                      messagePlatformEnabled &&
                      (post.topic_type === TopicType.LINKEDIN_EMAIL || post.topic_type === TopicType.MEETING) && (
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button variant="ghost" size="sm" onClick={handleDropdownClick}>
                              {contactOptions.find((opt) => opt.name === selectedPlatform)?.icon &&
                                React.createElement(contactOptions.find((opt) => opt.name === selectedPlatform)!.icon, {
                                  className: 'h-5 w-5',
                                })}
                              <div className="ml-2">{selectedPlatform}</div>
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent>
                            {contactOptions.map((option) => (
                              <DropdownMenuItem
                                key={option.name}
                                onClick={() => {
                                  if (option.isEnabled) {
                                    setSelectedPlatform(option.name);
                                    handleDirectSendToMessage(post, option.name);
                                  }
                                }}
                                disabled={!option.isEnabled}
                              >
                                <div className="flex items-center w-full">
                                  {React.createElement(option.icon, {
                                    className: 'mr-2 h-5 w-5',
                                  })}
                                  <div>{option.name}</div>
                                </div>
                                {selectedPlatform === option.name && <Check className="ml-auto h-4 w-4" />}
                              </DropdownMenuItem>
                            ))}
                          </DropdownMenuContent>
                        </DropdownMenu>
                      )}
                  </div>
                  <div className="mt-2 space-y-2">
                    {email.body ? (
                      email.body.split('\n').map((line, idx) => <p key={idx}>{line}</p>)
                    ) : (
                      <p>No message available.</p>
                    )}
                  </div>
                  {/* Uncomment and adjust the following section if needed */}
                  {/* <div className="mt-4 flex items-center gap-2">
                    <span className="text-sm text-muted-foreground">
                    {post.sequenceAttr?.sequenceName || 'No Tag'}
                    </span>
                    <Button
                    variant="ghost"
                    size="icon"
                    onClick={(e) => onButtonClick(e, post, { id: 'Edit' })}
                    >
                    <Edit className="h-4 w-4" />
                    </Button>
                    <Button
                    variant="ghost"
                    size="icon"
                    onClick={(e) => onButtonClick(e, post, { id: 'Delete' })}
                    >
                    <X className="h-4 w-4" />
                    </Button>
                    <Button
                    variant="ghost"
                    size="icon"
                    className="bg-green-50"
                    onClick={(e) => onButtonClick(e, post, { id: 'Approve' })}
                    >
                    <Check className="h-4 w-4 text-green-600" />
                    </Button>
                    <Button
                    variant="ghost"
                    size="icon"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        const currentIndex = tabValues.indexOf(activeTab);
                        const nextIndex = (currentIndex + 1) % tabValues.length;
                        setActiveTab(tabValues[nextIndex]);
                    }}
                    >
                    <ChevronRight className="h-4 w-4" />
                    </Button>
                </div> */}
                </div>
              </div>
            </Card>
          </TabsContent>
        ))}
      </Tabs>
    );
  },
);

export default PersonalizedEmailCard;
