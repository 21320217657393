'use client';

import * as React from 'react';
import { X, Save } from 'lucide-react';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from '@/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import { ProfileList } from './profile-list';
import { useState, useEffect } from 'react';
import { toast } from '@/hooks/use-toast';
import {
  getIdealCustomerProfileList,
  createIdealCustomerProfile,
  updateIdealCustomerProfile,
  deleteIdealCustomerProfile,
  getSearchResults,
} from '@/helper/apiHelper';
import { LocationAutocomplete } from './location-autocomplete';

interface Profile {
  _id: string;
  name: string;
  jobTitles: string[];
  locations: string[];
  industries: string[];
  employeeSize: string[];
  departments: string[];
}

const GOOGLE_MAPS_API_KEY = process.env.GOOGLE_PLACE_API_KEY;

export default function IdealCustomerProfile() {
  const [profiles, setProfiles] = React.useState<Profile[]>([]);
  const [selectedProfileId, setSelectedProfileId] = React.useState<string | null>(null);
  const [jobTitles, setJobTitles] = React.useState<string[]>([]);
  const [industries, setIndustries] = React.useState<string[]>([]);
  const [locations, setLocations] = React.useState<string[]>([]);
  const [employeeSize, setEmployeeSize] = React.useState<string[]>([]);
  const [isEditingName, setIsEditingName] = useState(false);
  const [editedName, setEditedName] = useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [jobTitleOptions, setJobTitleOptions] = useState<string[]>([]);
  const [industryOptions, setIndustryOptions] = useState<string[]>([]);
  const [departments, setDepartments] = useState<string[]>([]);
  const [departmentOptions, setDepartmentOptions] = useState<string[]>([]);

  const companySizeOptions = [
    '1 - 10',
    '11 - 50',
    '51 - 200',
    '201 - 500',
    '501 - 1000',
    '1001 - 5000',
    '5001 - 10000',
    '10000+',
  ];

  useEffect(() => {
    const fetchProfiles = async () => {
      setIsLoading(true);
      try {
        const fetchedProfiles = await getIdealCustomerProfileList();
        if (Array.isArray(fetchedProfiles) && fetchedProfiles.length > 0) {
          const mappedProfiles = fetchedProfiles.map((profile) => ({
            _id: profile._id,
            name: profile.name,
            jobTitles: profile.jobTitles || [],
            locations: profile.locations || [],
            industries: profile.industries || [],
            employeeSize: profile.employeeSize || [],
            departments: profile.departments || [],
          }));

          setProfiles(mappedProfiles);
          setSelectedProfileId(mappedProfiles[0]._id);
          const firstProfile = mappedProfiles[0];
          setJobTitles(firstProfile.jobTitles);
          setLocations(firstProfile.locations);
          setIndustries(firstProfile.industries);
          setEmployeeSize(firstProfile.employeeSize);
          if (firstProfile) {
            setDepartments(firstProfile.departments || []);
          }
        }
      } catch (error) {
        console.error('Error fetching profiles:', error);
        toast({
          title: 'Error',
          description: 'Failed to load profiles',
          variant: 'destructive',
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchProfiles();
  }, []);

  useEffect(() => {
    // Load Google Maps API script
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);

    return () => {
      // Cleanup
      document.head.removeChild(script);
    };
  }, []);

  const handleAddProfile = async (createdProfile: Profile) => {
    try {
      console.log('createdProfile', createdProfile);
      const newProfileData = {
        name: `Profile ${profiles.length + 1}`,
        jobTitles: [],
        locations: [],
        industries: [],
        employeeSize: [],
        departments: [],
      };

      //const createdProfile = await createIdealCustomerProfile(newProfileData);

      const mappedProfile = {
        _id: createdProfile._id,
        name: createdProfile.name,
        jobTitles: createdProfile.jobTitles || [],
        locations: createdProfile.locations || [],
        industries: createdProfile.industries || [],
        employeeSize: createdProfile.employeeSize || [],
        departments: createdProfile.departments || [],
      };

      setProfiles((prevProfiles) => [...prevProfiles, mappedProfile]);
      setSelectedProfileId(mappedProfile._id);

      setJobTitles([]);
      setLocations([]);
      setIndustries([]);
      setEmployeeSize([]);
      setDepartments([]);

      toast({
        title: 'Success',
        description: 'Profile created successfully',
      });
    } catch (error) {
      console.error('Error creating profile:', error);
      toast({
        title: 'Error',
        description: 'Failed to create profile',
        variant: 'destructive',
      });
    }
  };

  const handleSelectProfile = (id: string) => {
    setSelectedProfileId(id);
    setIsEditingName(false);
    const profile = profiles.find((p) => p._id === id);
    if (profile) {
      setJobTitles(profile.jobTitles);
      setLocations(profile.locations || []);
      setIndustries(profile.industries);
      setEmployeeSize(profile.employeeSize);
      setDepartments(profile.departments || []);
    }
  };

  const handleDeleteProfile = async (id: string) => {
    try {
      const success = await deleteIdealCustomerProfile(id);
      if (success) {
        const updatedProfiles = profiles.filter((profile) => profile._id !== id);
        setProfiles(updatedProfiles);
        if (selectedProfileId === id) {
          if (updatedProfiles.length > 0) {
            handleSelectProfile(updatedProfiles[0]._id);
          } else {
            setSelectedProfileId('');
            setJobTitles([]);
            setLocations([]);
            setIndustries([]);
            setEmployeeSize([]);
            setDepartments([]);
          }
        }
        toast({
          title: 'Success',
          description: 'Profile deleted successfully',
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to delete profile',
        variant: 'destructive',
      });
    }
  };

  const handleShareProfile = (id: string) => {
    // Implement sharing functionality here
    toast({
      title: 'Profile Shared',
      description: `Profile ${id} has been shared.`,
    });
  };

  const handleSave = async (newName?: string) => {
    if (!selectedProfileId) {
      toast({
        title: 'Error',
        description: 'No profile selected',
        variant: 'destructive',
      });
      return;
    }

    setIsSaving(true);
    try {
      const profileData = {
        name: newName || profiles.find((p) => p._id === selectedProfileId)?.name || '',
        jobTitles,
        locations,
        industries,
        employeeSize,
        departments,
      };

      const updatedProfile = await updateIdealCustomerProfile(selectedProfileId, profileData);

      if (updatedProfile) {
        setProfiles((prevProfiles) =>
          prevProfiles.map((p) =>
            p._id === selectedProfileId
              ? {
                  ...p,
                  name: updatedProfile.name,
                  jobTitles: updatedProfile.jobTitles || [],
                  locations: updatedProfile.locations || [],
                  industries: updatedProfile.industries || [],
                  employeeSize: updatedProfile.employeeSize || [],
                  departments: updatedProfile.departments || [],
                }
              : p,
          ),
        );

        if (isEditingName) {
          setIsEditingName(false);
        }

        toast({
          title: 'Success',
          description: 'Profile saved successfully',
        });
      }
    } catch (error) {
      console.error('Error saving profile:', error);
      toast({
        title: 'Error',
        description: 'Failed to save profile',
        variant: 'destructive',
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleJobTitleSearch = async (searchTerm: string) => {
    try {
      console.log('Searching for:', searchTerm);
      const response = await getSearchResults('searchJobTitles', searchTerm);
      console.log('Search response:', response);
      if (response.jobTitles) {
        // Filter out the already selected job titles
        const filteredJobTitles = response.jobTitles.filter((title: string) => !jobTitles.includes(title));
        setJobTitleOptions(filteredJobTitles);
      }
    } catch (error) {
      console.error('Error fetching job titles:', error);
      toast({
        title: 'Error',
        description: 'Failed to fetch job titles',
        variant: 'destructive',
      });
    }
  };

  const handleIndustrySearch = async (searchTerm: string) => {
    try {
      console.log('Searching for industry:', searchTerm);
      const response = await getSearchResults('searchIndustries', searchTerm);
      console.log('Industry search response:', response);
      if (response.industries) {
        // Filter out the already selected industries
        const filteredIndustries = response.industries.filter((industry: string) => !industries.includes(industry));
        setIndustryOptions(filteredIndustries);
      }
    } catch (error) {
      console.error('Error fetching industries:', error);
      toast({
        title: 'Error',
        description: 'Failed to fetch industries',
        variant: 'destructive',
      });
    }
  };

  const handleEmployeeSizeClick = (size: string) => {
    setEmployeeSize((prev) => {
      if (prev.includes(size)) {
        return prev.filter((s) => s !== size);
      }
      return [...prev, size];
    });
  };

  const handleDepartmentSearch = async (searchTerm: string) => {
    try {
      console.log('Searching for department:', searchTerm);
      const response = await getSearchResults('searchDepartments', searchTerm);
      console.log('Department search response:', response);
      if (response.departments) {
        const filteredDepartments = response.departments.filter(
          (department: string) => !departments.includes(department),
        );
        setDepartmentOptions(filteredDepartments);
      }
    } catch (error) {
      console.error('Error fetching departments:', error);
      toast({
        title: 'Error',
        description: 'Failed to fetch departments',
        variant: 'destructive',
      });
    }
  };

  if (isLoading) {
    return <div className="flex h-screen items-center justify-center">Loading...</div>;
  }

  return (
    <div className="flex h-screen">
      <ProfileList
        profiles={profiles || []}
        selectedProfileId={selectedProfileId}
        onSelectProfile={handleSelectProfile}
        onAddProfile={handleAddProfile}
        onDeleteProfile={handleDeleteProfile}
        onShareProfile={handleShareProfile}
      />
      <div className="flex-1 flex flex-col">
        <div className="p-4 border-b flex justify-between items-center">
          {isEditingName ? (
            <input
              type="text"
              value={editedName}
              onChange={(e) => setEditedName(e.target.value)}
              onBlur={() => {
                if (editedName.trim()) {
                  handleSave(editedName.trim());
                }
                setIsEditingName(false);
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault(); // Prevent form submission if inside a form
                  if (editedName.trim()) {
                    handleSave(editedName.trim());
                  }
                  setIsEditingName(false);
                }
              }}
              className="text-2xl font-bold border-b-2 border-primary focus:outline-none"
              autoFocus
            />
          ) : (
            <h1
              className="text-2xl font-bold cursor-pointer"
              onClick={() => {
                setEditedName(profiles.find((p) => p._id === selectedProfileId)?.name || '');
                setIsEditingName(true);
              }}
            >
              {profiles.find((p) => p._id === selectedProfileId)?.name || 'Ideal Customer Profile'}
            </h1>
          )}
          <Button onClick={() => handleSave()} disabled={isSaving || !selectedProfileId}>
            <Save className="mr-2 h-4 w-4" />
            {isSaving ? 'Saving...' : 'Save'}
          </Button>
        </div>
        <div className="flex-1 p-6 space-y-8 overflow-y-auto">
          <div className="space-y-4">
            <h2 className="text-lg font-semibold">Job Titles</h2>
            <div className="space-y-4">
              <div className="flex flex-wrap gap-2">
                {jobTitles.map((title) => (
                  <Badge key={title} variant="secondary" className="flex items-center gap-1">
                    {title}
                    <button
                      onClick={() => setJobTitles(jobTitles.filter((t) => t !== title))}
                      className="ml-1 ring-offset-background rounded-full outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2"
                    >
                      <X className="h-3 w-3" />
                      <span className="sr-only">Remove {title}</span>
                    </button>
                  </Badge>
                ))}
              </div>
              <Popover>
                <PopoverTrigger asChild>
                  <Button variant="outline" role="combobox" className="w-full justify-start text-left font-normal">
                    <span className="text-muted-foreground">E.g., Sales Representative</span>
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-[400px] p-0">
                  <Command>
                    <CommandInput
                      placeholder="Search job title..."
                      onValueChange={(search) => handleJobTitleSearch(search)}
                    />
                    <CommandEmpty>No job title found.</CommandEmpty>
                    <CommandGroup>
                      {jobTitleOptions
                        .filter((title) => !jobTitles.includes(title))
                        .map((title) => (
                          <CommandItem
                            key={title}
                            onSelect={() => {
                              setJobTitles((prev) => [...prev, title]);
                            }}
                          >
                            {title}
                          </CommandItem>
                        ))}
                    </CommandGroup>
                  </Command>
                </PopoverContent>
              </Popover>
            </div>
          </div>

          <div className="space-y-4">
            <h2 className="text-lg font-semibold">Locations</h2>
            <div className="space-y-4">
              <div className="flex flex-wrap gap-2">
                {locations.map((loc) => (
                  <Badge key={loc} variant="secondary" className="flex items-center gap-1">
                    {loc}
                    <button
                      onClick={async () => {
                        const updatedLocations = locations.filter((l) => l !== loc);
                        setLocations(updatedLocations);

                        // Save immediately after removing a location
                        if (selectedProfileId) {
                          setIsSaving(true);
                          try {
                            const profileData = {
                              name: profiles.find((p) => p._id === selectedProfileId)?.name || '',
                              jobTitles,
                              locations: updatedLocations,
                              industries,
                              employeeSize,
                              departments,
                            };

                            const updatedProfile = await updateIdealCustomerProfile(selectedProfileId, profileData);

                            if (updatedProfile) {
                              setProfiles((prevProfiles) =>
                                prevProfiles.map((p) =>
                                  p._id === selectedProfileId
                                    ? {
                                        ...p,
                                        locations: updatedProfile.locations || [],
                                        departments: updatedProfile.departments || [],
                                      }
                                    : p,
                                ),
                              );

                              toast({
                                title: 'Success',
                                description: 'Location removed successfully',
                              });
                            }
                          } catch (error) {
                            console.error('Error removing location:', error);
                            toast({
                              title: 'Error',
                              description: 'Failed to remove location',
                              variant: 'destructive',
                            });
                          } finally {
                            setIsSaving(false);
                          }
                        }
                      }}
                      className="ml-1 ring-offset-background rounded-full outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2"
                    >
                      <X className="h-3 w-3" />
                      <span className="sr-only">Remove {loc}</span>
                    </button>
                  </Badge>
                ))}
              </div>
              <LocationAutocomplete
                onSelect={async (newLocation) => {
                  if (!locations.includes(newLocation)) {
                    const updatedLocations = [...locations, newLocation];
                    setLocations(updatedLocations);

                    // Save immediately after adding a location
                    if (selectedProfileId) {
                      setIsSaving(true);
                      try {
                        const profileData = {
                          name: profiles.find((p) => p._id === selectedProfileId)?.name || '',
                          jobTitles,
                          locations: updatedLocations, // Use the updated locations
                          industries,
                          employeeSize,
                          departments,
                        };

                        const updatedProfile = await updateIdealCustomerProfile(selectedProfileId, profileData);

                        if (updatedProfile) {
                          setProfiles((prevProfiles) =>
                            prevProfiles.map((p) =>
                              p._id === selectedProfileId
                                ? {
                                    ...p,
                                    locations: updatedProfile.locations || [],
                                    departments: updatedProfile.departments || [],
                                  }
                                : p,
                            ),
                          );

                          toast({
                            title: 'Success',
                            description: 'Location added successfully',
                          });
                        }
                      } catch (error) {
                        console.error('Error saving location:', error);
                        toast({
                          title: 'Error',
                          description: 'Failed to save location',
                          variant: 'destructive',
                        });
                      } finally {
                        setIsSaving(false);
                      }
                    }
                  }
                }}
              />
            </div>
          </div>

          <div className="space-y-4">
            <h2 className="text-lg font-semibold">Industries</h2>
            <div className="space-y-4">
              <div className="flex flex-wrap gap-2">
                {industries.map((industry) => (
                  <Badge key={industry} variant="secondary" className="flex items-center gap-1">
                    {industry}
                    <button
                      onClick={() => setIndustries(industries.filter((i) => i !== industry))}
                      className="ml-1 ring-offset-background rounded-full outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2"
                    >
                      <X className="h-3 w-3" />
                      <span className="sr-only">Remove {industry}</span>
                    </button>
                  </Badge>
                ))}
              </div>
              <Popover>
                <PopoverTrigger asChild>
                  <Button variant="outline" role="combobox" className="w-full justify-start text-left font-normal">
                    <span className="text-muted-foreground">E.g., Technology</span>
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-[400px] p-0">
                  <Command>
                    <CommandInput
                      placeholder="Search industry..."
                      onValueChange={(search) => handleIndustrySearch(search)}
                    />
                    <CommandEmpty>No industry found.</CommandEmpty>
                    <CommandGroup>
                      {industryOptions
                        .filter((industry) => !industries.includes(industry))
                        .map((industry) => (
                          <CommandItem
                            key={industry}
                            onSelect={() => {
                              setIndustries((prev) => [...prev, industry]);
                            }}
                          >
                            {industry}
                          </CommandItem>
                        ))}
                    </CommandGroup>
                  </Command>
                </PopoverContent>
              </Popover>
            </div>
          </div>

          <div className="space-y-4">
            <h2 className="text-lg font-semibold">Number of employees</h2>
            <div className="flex flex-wrap gap-2">
              {companySizeOptions.map((size) => (
                <Button
                  key={size}
                  variant={employeeSize.includes(size) ? 'default' : 'outline'}
                  onClick={() => handleEmployeeSizeClick(size)}
                  className={cn(
                    'flex items-center gap-1',
                    employeeSize.includes(size) && 'bg-primary text-primary-foreground',
                  )}
                >
                  {size}
                </Button>
              ))}
            </div>
          </div>
          {/* 
          <div className="space-y-4">
            <h2 className="text-lg font-semibold">Departments</h2>
            <div className="space-y-4">
              <div className="flex flex-wrap gap-2">
                {departments.map((department) => (
                  <Badge key={department} variant="secondary" className="flex items-center gap-1">
                    {department}
                    <button
                      onClick={() => setDepartments(departments.filter((d) => d !== department))}
                      className="ml-1 ring-offset-background rounded-full outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2"
                    >
                      <X className="h-3 w-3" />
                      <span className="sr-only">Remove {department}</span>
                    </button>
                  </Badge>
                ))}
              </div>
              <Popover>
                <PopoverTrigger asChild>
                  <Button variant="outline" role="combobox" className="w-full justify-start text-left font-normal">
                    <span className="text-muted-foreground">E.g., Sales</span>
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-[400px] p-0">
                  <Command>
                    <CommandInput
                      placeholder="Search department..."
                      onValueChange={(search) => handleDepartmentSearch(search)}
                    />
                    <CommandEmpty>No department found.</CommandEmpty>
                    <CommandGroup>
                      {departmentOptions
                        .filter((department) => !departments.includes(department))
                        .map((department) => (
                          <CommandItem
                            key={department}
                            onSelect={() => {
                              setDepartments((prev) => [...prev, department]);
                            }}
                          >
                            {department}
                          </CommandItem>
                        ))}
                    </CommandGroup>
                  </Command>
                </PopoverContent>
              </Popover>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
