const TITLES = {
  'CEO': ['Chief Executive Officer', 'Chief Officer'],
  'CTO': ['Chief Technology Officer', 'Chief Technological Officer'],
  'Founder': ['Co-Founder', 'Founding Partner'],
  'Director': [],
  'President': [],
  'VP': ['Vice President', 'V.P.'],
  'VP Engineering': ['Vice President Engineering', 'Vice President of Engineering'],
  'VP Sales': ['Vice President Sales', 'Vice President of Sales'],
  'VP Marketing': ['Vice President Marketing', 'Vice President of Marketing'],
};

const INDUSTRIES = {
  'Legal': ['Law', 'Legal Services', 'Law Firm'],
  'Accounting': ['Professional Services', 'Audit', 'Tax', 'Advisory'],
  'Technology': ['Tech', 'Software', 'IT', 'Information Technology'],
  'Finance': ['Financial Services', 'Banking', 'Financial Institution'],
  'Real Estate': ['Property', 'Commercial Real Estate', 'Real Estate Services'],
  'Consulting': ['Management Consulting', 'Business Consulting', 'Advisory Services'],
};

const COMPANY_SIZES = ['0-1000', '1000-5000', '5000+'];

module.exports = {
  TITLES,
  INDUSTRIES,
  COMPANY_SIZES,
};
