import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, Stack } from '@mui/system';
import { styled } from '@mui/material/styles';
import WarningIcon from '@mui/icons-material/Warning';
import PButton from '@Library/PButton';
import PToolTip from '@Library/PToolTip';
import PTypography from '@Library/PTypography';
import PBadge from '@Library/PBadge';
import { Providers, TopicType, Milestones } from '@/constants/constant';
import { ReactComponent as EditIcon } from '@Assets/Icons/Edit.svg';
import { ReactComponent as CheckIcon } from '@Assets/Icons/Check.svg';
import { ReactComponent as ClockIcon } from '@Assets/Icons/Clock.svg';
import ClearIcon from '@mui/icons-material/Clear';
import SocialMediaReplyCard from '@Library/PPostCard/SocialMediaReplyCard';
import SocialMediaPostCard from '@Library/PPostCard/SocialMediaPostCard';
import StockSalesCard from '@Library/PPostCard/StockSalesCard';
import FundzCard from '@Library/PPostCard/FundzCard';
import EmailCard from '@Library/PPostCard/EmailCard';
import AwardsCard from '@Library/PPostCard/AwardsCard';
import PMultiApproveButton from '@Library/PMultiApproveButton';
import PersonalizedEmailCard from '@Library/PPostCard/PersonalizedEmailCard';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import PMultiRecommendButton from '@Library/PMultiRecommendButton';
import { CheckCircle } from 'lucide-react';
import Badge from '@mui/material/Badge';
const PButtonStyle = {
  display: 'flex',
  width: '40px',
  height: '40px',
  minWidth: '0',
  padding: '0',
};
const stackStyle = { mt: '12px' };
const boxStyle = { display: 'flex', gap: '8px' };
const PcardStyle = { cursor: 'pointer' };

import ManualListModal from '@/pages/setup/simpleSetupComponents/Lists/ManualListModal';
import { useSelector, useDispatch } from 'react-redux';
import { refreshSequencesAndContacts } from '@/App';
import { setActionFlag } from '@/redux/authSlice';

const getVariantStyles = (theme, pVariant) => {
  let colorScheme = {};

  switch (pVariant) {
    case 'greenBorder':
      colorScheme = {
        text: theme.palette.primaryCL.Black110,
        background: theme.palette.primaryCL.White100,
        border: theme.palette.primaryCL.Green100,
        activeBorder: theme.palette.primaryCL.Blue100,
        button: theme.palette.success.main,
        buttonBG: theme.palette.primaryCL.White100,
        disabledText: theme.palette.primaryCL.Black100,
        disabledBackground: theme.palette.primaryCL.Black50,
        disabledButton: theme.palette.primaryCL.Black70,
        disabledButtonBG: theme.palette.primaryCL.White110,
      };
      break;

    case 'redBorder':
      colorScheme = {
        text: theme.palette.primaryCL.Black110,
        background: theme.palette.primaryCL.White100,
        border: theme.palette.primaryCL.Red100,
        activeBorder: theme.palette.primaryCL.Blue100,
        button: theme.palette.error.main,
        buttonBG: theme.palette.primaryCL.White100,
        disabledText: theme.palette.primaryCL.Black100,
        disabledBackground: theme.palette.primaryCL.Black50,
        disabledButton: theme.palette.primaryCL.Black70,
        disabledButtonBG: theme.palette.primaryCL.White110,
      };
      break;
    case 'primary':
      colorScheme = {
        text: theme.palette.primaryCL.Black110,
        background: theme.palette.primaryCL.White100,
        hoverBackground: theme.palette.primaryCL.White100,
        border: theme.palette.primaryCL.Black70,
        activeBorder: theme.palette.primaryCL.Blue100,
        button: theme.palette.primaryCL.Blue100,
        buttonBG: theme.palette.primaryCL.White100,
        disabledText: theme.palette.primaryCL.Black100,
        disabledBackground: theme.palette.primaryCL.Black50,
        disabledButton: theme.palette.primaryCL.Black70,
        disabledButtonBG: theme.palette.primaryCL.White110,
      };
      break;

    default: // Same as primary
      colorScheme = {
        text: theme.palette.primaryCL.Black110,
        background: theme.palette.primaryCL.White100,
        hoverBackground: theme.palette.primaryCL.White100,
        border: theme.palette.primaryCL.Black70,
        activeBorder: theme.palette.primaryCL.Blue100,
        button: theme.palette.primaryCL.Blue100,
        buttonBG: theme.palette.primaryCL.White100,
        disabledText: theme.palette.primaryCL.Black100,
        disabledBackground: theme.palette.primaryCL.Black50,
        disabledButton: theme.palette.primaryCL.Black70,
        disabledButtonBG: theme.palette.primaryCL.White110,
      };
      break;

    // Add more cases for 'outlined', 'plain', etc.
  }

  return colorScheme;
};

const StyledPCard = styled('div', {
  shouldForwardProp: (prop) => prop !== 'pVariant',
})(({ theme, pVariant, disabled }) => {
  const { background, text, border, activeBorder, disabledBackground, disabledText } = getVariantStyles(
    theme,
    pVariant,
  );

  return {
    'borderRadius': '14px',
    'border': pVariant === 'greenBorder' || pVariant === 'redBorder' ? '1.5px solid' : '1px solid',
    'borderColor': border,
    'padding': '16px',
    'width': '100%',
    'height': 'auto',
    'fontFamily': 'Inter',
    'justifyContent': 'flex-start',
    // overflow: "wrap",
    'color': disabled ? disabledText : text,
    'backgroundColor': disabled ? disabledBackground : background,
    '&:hover': {
      boxShadow:
        'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',
    },
  };
});

// Heuristic to determine if the "post" is an email
const isEmail = (post) => {
  return Boolean(
    (post?.source === 'sequence' &&
      !!post?.subject &&
      ['meeting', 'followup', 'email', 'checkin.recurring', 'gift', 'linkedInEmail', Milestones.sec144].includes(
        post?.topic_type,
      )) ||
      (post?.source === 'chrome-extension' && !!post?.subject && post?.topic_type === TopicType.ADHOC) ||
      (post?.source === 'ERM' && !!post?.subject && post?.topic_type === TopicType.ADHOC),
  );
};

// Heuristic to determine if the "post" is a social post
const isSocialPost = (post) => {
  return Boolean(
    !!post?.topic &&
      !!post?.source &&
      post?.authorType === 'user' &&
      post?.topic_type === TopicType.USER_POST &&
      (post.source === Providers.TWITTER || post.source === Providers.LINKED_IN),
  );
};

// Heuristic to determine if the "post" is a social reply
const isSocialReply = (post) => {
  return Boolean(post?.authorType === 'contact' && post?.topic_type === TopicType.USER_POST && post?.responseTo);
};

const SourceContent = ({ sourceLink, sourceStyle }) => {
  const viewSource = (event) => {
    event.stopPropagation();
    window.open(sourceLink, '_blank');
  };
  return (
    <Box onClick={viewSource}>
      <PTypography size="footnote" color={sourceStyle} weight="regular">
        See source
      </PTypography>
    </Box>
  );
};

const PPostCard = ({ post, pVariant, disabled, onClick, theme, handleSendToMessage, updatePostCallback }) => {
  const authSliceSelectedDelegation = useSelector((state) => state.auth.selectedDelegation);
  const userAuth = useSelector((state) => state.auth.user);
  const sourceStyle = theme.palette.primaryCL.Black100;
  const dispatch = useDispatch();
  const allKnownSequences = useSelector((state) => state.auth.sequences);
  const [editContactModalOpen, setEditContactModalOpen] = React.useState(false);
  const [editContact, setEditContact] = React.useState(null);

  const handleUpdatePostPersonalizedContent = useCallback(
    (updatedContent) => {
      const updatedPost = { ...post, ...updatedContent };
      updatePostCallback(updatedPost);
    },
    [post, updatePostCallback],
  );
  const delegationAccessId = authSliceSelectedDelegation?.userId || null;
  const latestRecommendation = post?.recommendationList?.[post?.recommendationList?.length - 1];
  const userHasRecommended = post.recommendationList?.some((rec) => rec.userId === userAuth?._id);
  const recommendationText = userHasRecommended
    ? `Reviewed and recommended to be ${latestRecommendation?.recommendation === 'approve' ? 'approved' : 'removed'}`
    : latestRecommendation
      ? `Reviewed and recommended to be ${latestRecommendation.recommendation === 'approve' ? 'approved' : 'removed'} by ${latestRecommendation.name}`
      : '';
  let dynamicPVariant = pVariant;
  const recommendation = post.recommendationList?.find(
    (rec) => rec.recommendation === 'approve' || rec.recommendation === 'decline',
  );
  if (userHasRecommended || !delegationAccessId) {
    if (latestRecommendation?.recommendation === 'approve') {
      dynamicPVariant = 'greenBorder';
    } else if (latestRecommendation?.recommendation === 'decline') {
      dynamicPVariant = 'redBorder';
    }
  }
  const featureFlags = useSelector((state) => state.auth.featureFlags);
  const isDelegationRecommendApproveDeclineEnabled = featureFlags?.find(
    (featureFlag) => featureFlag.name === 'ui.delegationRecommendApproveDecline',
  )?.isFeatureEnabled;
  const buttonActions = [
    {
      id: 'Schedule',
      tooltipText: 'Schedule',
      pVariant: 'plainBlack',
      tooltipPlacement: 'bottom',
      icon: ClockIcon,
      // This is a custom svg, no need for fontsize
    },
    {
      id: 'Edit',
      tooltipText: 'Edit',
      pVariant: 'plainBlack',
      tooltipPlacement: 'bottom',
      icon: EditIcon,
      // This is a custom svg, no need for fontsize
    },
    {
      id: 'Delete',
      tooltipText: 'Remove',
      pVariant: 'plainRed',
      tooltipPlacement: 'bottom',
      icon: ClearIcon,
      // MUI icons need to have their sizes specified to match our design
      iconProps: { sx: { fontSize: '20.5349021815px' } },
    },
    {
      id: 'Approve',
      tooltipText: 'Approve',
      pVariant: 'plainGreen',
      tooltipPlacement: 'bottom',
      icon: CheckIcon,
      // This is a custom svg, no need for fontsize
    },
  ];

  if (isDelegationRecommendApproveDeclineEnabled) {
    buttonActions.splice(3, 0, {
      id: 'RecommendApprove',
      tooltipText: 'Recommend Approve',
      pVariant: 'plainGreen',
      tooltipPlacement: 'bottom',
      icon: CheckIcon,
    });
  }

  const getFilteredActions = (authSliceSelectedDelegation) => {
    return buttonActions
      .map((action) => {
        // Hide "Edit" if permission is "all"
        if (authSliceSelectedDelegation?.permission === 'all' && action.id === 'Edit') {
          return null;
        }

        // Change "Delete" variant to 'red' if permission is "all"
        if (authSliceSelectedDelegation?.permission === 'all' && action.id === 'Delete') {
          return { ...action, pVariant: 'red' };
        }

        // Change "Approve" variant to 'darkGreen' if permission is "all"
        if (authSliceSelectedDelegation?.permission === 'all' && action.id === 'Approve') {
          return { ...action, pVariant: 'darkGreen' };
        }

        if (action.id === 'RecommendApprove' && !authSliceSelectedDelegation?.userId) {
          return null;
        }

        return action;
      })
      .filter(Boolean);
  };
  React.useEffect(() => {
    if (
      (!post?.sequenceAttr?.sequenceId && !post?.extraData?.sequenceId) ||
      (!post?.sequenceAttr?.contactId && !post?.extraData?.contactId)
    ) {
      return;
    }

    const sequenceId = post.sequenceAttr?.sequenceId || post?.extraData?.sequenceId;
    const contactId = post.sequenceAttr?.contactId || post.extraData?.contactId;

    const mySequence = allKnownSequences[sequenceId];
    const myContact = mySequence?.contacts?.[contactId];

    setEditContact(myContact);
  }, [allKnownSequences, post]);

  const badgeElement = React.useMemo(() => {
    let badgeText;

    if (isSocialPost(post)) {
      badgeText = post?.topic;
    }

    if (isEmail(post) || isSocialReply(post)) {
      badgeText = post?.sequenceAttr?.sequenceName;
    }

    if (badgeText) {
      return <PBadge pVariant="black">{badgeText}</PBadge>;
    }

    return <div></div>;
  }, [post]);

  const handleCloseModal = React.useCallback(
    (event, refresh) => {
      console.log('event: ', event);
      setEditContactModalOpen(false);

      if (refresh) {
        setTimeout(() => {
          refreshSequencesAndContacts(dispatch);
          dispatch(setActionFlag({ flag: 'refreshPosts', value: true }));
        }, 250);
      }
    },
    [dispatch],
  );

  const editContactModalElement = React.useMemo(() => {
    return editContactModalOpen ? (
      <ManualListModal
        open={true}
        onClose={(e) => handleCloseModal(e, false)}
        contact={editContact}
        post={post}
        forSetup={false}
        refreshListContacts={(e) => handleCloseModal(e, true)}
      />
    ) : (
      <></>
    );
  }, [editContactModalOpen, handleCloseModal, editContact]);

  const handleButtonClick = React.useCallback(
    (event, post, action) => {
      event.stopPropagation();

      const payload = { ...post };

      if (action.id === 'Approve') {
        if (post.source !== 'sequence' && post.topic_type !== TopicType.ADHOC) {
          payload.action = 'Publish';
        } else {
          payload.action = 'Approve';
          if (action.subAction) {
            payload.subAction = action.subAction;
          }
        }
      } else if (action.id === 'RecommendApprove') {
        payload.action = 'RecommendApprove';
      } else if (action.id === 'RecommendRemove') {
        payload.action = 'RecommendRemove';
      } else {
        payload.action = action.id;
      }

      if (onClick) {
        onClick(payload);
      }
    },
    [onClick],
  );

  const handleMessageBodyClick = React.useCallback(
    (event) => {
      console.log('message body clicked: ', event.target.tagName);
      const allowedTags = ['P', 'SPAN']; // Add more tags if necessary

      if (event.target.closest('.MuiPopover-root')) {
        return;
      }
      if (!allowedTags.includes(event?.target?.tagName)) {
        return;
      }
      if (editContactModalOpen) {
        return;
      }
      handleButtonClick(event, post, { id: 'Edit' });
    },
    [handleButtonClick, post, editContactModalOpen],
  );

  const handleContactClick = React.useCallback(() => {
    setEditContactModalOpen(true);
  }, []);

  const errorNote = React.useMemo(() => {
    if (post.lastErrorMessage) {
      return (
        <Stack
          direction="row"
          spacing="12px"
          justifyContent="space-between"
          width="100%"
          sx={{
            p: 1,
            borderRadius: 1,
            border: '1px solid',
            borderColor: '#ffc107',
            mt: 1,
            mb: 1,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <WarningIcon sx={{ color: '#856404', fontSize: '20px' }} />
            <PTypography size="footnote" sx={{ color: '#856404' }}>
              {post.lastErrorMessage}
              {post.lastErrorDate && ` (${new Date(post.lastErrorDate).toLocaleString()})`}
            </PTypography>
          </Box>
        </Stack>
      );
    }
    return null;
  }, [post.lastErrorMessage, post.lastErrorDate]);

  const content = React.useMemo(() => {
    if (post.personalizedEmails && post.personalizedEmails.length > 0) {
      return (
        <>
          {errorNote}
          <PersonalizedEmailCard
            post={post}
            onContactClick={handleContactClick}
            onButtonClick={handleButtonClick}
            onPostUpdate={handleUpdatePostPersonalizedContent}
            handleSendToMessage={handleSendToMessage}
          />
        </>
      );
    }

    if (isSocialPost(post)) {
      return <SocialMediaPostCard post={post} onContactClick={handleContactClick} />;
    }

    if (isSocialReply(post)) {
      return <SocialMediaReplyCard post={post} onContactClick={handleContactClick} />;
    }

    if (isEmail(post)) {
      if (post?.sequenceAttr?.milestone === Milestones.SEC144) {
        return <StockSalesCard post={post} onContactClick={handleContactClick} />;
      } else if (post?.sequenceAttr?.milestone === Milestones.FUNDING) {
        return <FundzCard post={post} onContactClick={handleContactClick} />;
      } else if (post?.sequenceAttr?.milestone === Milestones.AWARDS) {
        return <AwardsCard post={post} onContactClick={handleContactClick} />;
      } else {
        return (
          <>
            {errorNote}
            <EmailCard post={post} onContactClick={handleContactClick} handleSendToMessage={handleSendToMessage} />
          </>
        );
      }
    }
    return null;
  }, [post, handleUpdatePostPersonalizedContent, handleButtonClick, handleSendToMessage]);

  const sources = React.useMemo(() => {
    const isValidLink = (link) => link && link.trim() !== '';

    if (isSocialReply(post)) {
      const link = post?.extraData?.linkedInPostUrl;
      if (isValidLink(link)) {
        return <SourceContent sourceLink={link} sourceStyle={sourceStyle} />;
      }
    }

    if (isEmail(post)) {
      switch (post?.sequenceAttr?.milestone) {
        case Milestones.SEC144: {
          const source = post.sequenceAttr?.milestoneDetails?.source;
          const sourceUrl = source;
          if (isValidLink(sourceUrl)) {
            const baseUrl = sourceUrl.replace('/primary_doc.xml', '');
            return <SourceContent sourceLink={baseUrl} sourceStyle={sourceStyle} />;
          }
          break;
        }
        case Milestones.FUNDING: {
          const source = post.sequenceAttr?.milestoneDetails?.source;
          const sourceUrl = source;
          if (isValidLink(sourceUrl)) {
            return <SourceContent sourceLink={sourceUrl} sourceStyle={sourceStyle} />;
          }
          break;
        }
        case Milestones.JOB_CHANGE:
        case Milestones.JOB_PROMOTION: {
          const link = post?.linkedInData?.linkedInPostUrl || post?.sequenceAttr?.contactDetails?.linkedin;
          if (isValidLink(link)) {
            return <SourceContent sourceLink={link} sourceStyle={sourceStyle} />;
          }
          break;
        }
        case Milestones.ACQUISITION: {
          const source = post.sequenceAttr?.milestoneDetails?.source;
          const sourceUrl = source;
          if (isValidLink(sourceUrl)) {
            return <SourceContent sourceLink={sourceUrl} sourceStyle={sourceStyle} />;
          }
          break;
        }
        case Milestones.AWARDS: {
          const source = post.sequenceAttr?.milestoneDetails?.source;
          const sourceUrl = source;
          if (isValidLink(sourceUrl)) {
            return <SourceContent sourceLink={sourceUrl} sourceStyle={sourceStyle} />;
          }
          break;
        }
        default: {
          const link = post?.linkedInData?.linkedInPostUrl;
          if (isValidLink(link)) {
            return <SourceContent sourceLink={link} sourceStyle={sourceStyle} />;
          }
          return false;
        }
      }
    }

    return null;
  }, [handleContactClick, post]);

  return (
    <StyledPCard
      disableElevation
      pVariant={dynamicPVariant}
      disabled={disabled}
      onClick={!editContactModalOpen ? handleMessageBodyClick : undefined}
      sx={PcardStyle}
    >
      {editContactModalElement}

      {/* Support clicking the message body to open the editor */}
      <Box>{content}</Box>
      {/* This is the footer */}
      <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center" sx={stackStyle}>
        <Box display="flex">
          <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
            {badgeElement}
            <Box>{sources}</Box>
          </Stack>
        </Box>
        <Box sx={boxStyle}>
          {getFilteredActions(authSliceSelectedDelegation)
            .map((action) => {
              if (post.topic_type !== 'userPost' && action.id === 'Schedule') {
                return null;
              }
              const recommendation = post.recommendationList?.find(
                (rec) => rec.recommendation === 'approve' || rec.recommendation === 'decline',
              );

              if (action.id === 'Approve') {
                const pVariant =
                  latestRecommendation?.recommendation === 'approve' && !delegationAccessId ? 'darkGreen' : '';
                return (
                  <PMultiApproveButton
                    key={action.id}
                    pVariant={pVariant}
                    onApprove={(event) => {
                      event.stopPropagation();
                      handleButtonClick(event, post, action);
                    }}
                    onApproveAndSend={(event) => {
                      event.stopPropagation();
                      handleButtonClick(event, post, { ...action, subAction: 'SendNow' });
                    }}
                    // onApproveWithCustomTime={(event) => {
                    //   event.stopPropagation();
                    //   handleButtonClick(event, post, { ...action, subAction: 'SendLater' })
                    // }}
                  />
                );
              }

              if (action.id === 'RecommendApprove') {
                return (
                  <PMultiRecommendButton
                    onRecommendApprove={(event) => handleButtonClick(event, post, { id: 'RecommendApprove' })}
                    onRecommendRemove={(event) => handleButtonClick(event, post, { id: 'RecommendRemove' })}
                  />
                );
              }
              if (action.id === 'Delete' && latestRecommendation?.recommendation === 'decline' && !delegationAccessId) {
                const pVariant = latestRecommendation?.recommendation === 'decline' ? 'darkRed' : '';
                const removeButtonColor = recommendation?.recommendation === 'reject' ? 'red' : 'default';
                return (
                  <PToolTip key={action.id} title={'Recommend to be Removed'} placement={action.tooltipPlacement}>
                    <PButton
                      key={action.id}
                      pVariant={pVariant}
                      Icon={action.icon}
                      iconProps={action.iconProps}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleButtonClick(event, post, action);
                      }}
                      sx={{ color: removeButtonColor }}
                      additionalStyles={PButtonStyle}
                      isIconicButton
                    />
                  </PToolTip>
                );
              }
              return (
                <PToolTip
                  key={action.id}
                  title={action.id === 'Schedule' ? 'Schedule for later' : action.tooltipText}
                  placement={action.tooltipPlacement}
                >
                  <PButton
                    pVariant={action.pVariant}
                    Icon={action.icon}
                    iconProps={action.iconProps}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleButtonClick(event, post, action);
                    }}
                    additionalStyles={PButtonStyle}
                    isIconicButton
                  />
                </PToolTip>
              );
            })
            .filter((p) => p)}
        </Box>
      </Stack>
      {latestRecommendation && (
        <Stack direction="row" spacing={1} justifyContent="end" alignItems="center" sx={stackStyle}>
          <PBadge pVariant="black">{recommendationText}</PBadge>
        </Stack>
      )}
    </StyledPCard>
  );
};

PPostCard.propTypes = {
  /** Postilize design variant. So far, all we have is primary, keeping for posterity */
  pVariant: PropTypes.oneOf(['primary']),

  /** Disabled state of the button */
  disabled: PropTypes.bool,

  /** Click handler */
  onClick: PropTypes.func.isRequired,

  /** Post object. See `postDetailsSchema` in /models/userPostQueue.js */
  post: PropTypes.object.isRequired,

  /** Update post handler */
  updatePostCallback: PropTypes.func,
};

PPostCard.defaultProps = {
  pVariant: 'primary',
  disabled: false,
};

export default PPostCard;
