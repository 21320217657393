'use client';

import { useState, useMemo, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useToast } from '@Hooks/use-toast.tsx';
import { useIsFeatureFlagEnabled } from '@Hooks/useFeatureFlags';
import {
  getContactInteractions,
  getCompanyClients,
  getCompanyRelationships,
  updateCompanyClientStage,
} from '@/helper/apiHelper';
import { compareDates } from '@/helper/utilities';
import { ClientTable } from './ERMDashboard/components/ClientTable';
import { RelationshipTable } from './ERMDashboard/components/RelationshipTable';
import { InteractionsModal } from './ERMDashboard/components/InteractionsModal';
import { ERMPagination } from './ERMDashboard/components/ERMPagination';
import { CssBaseline } from '@mui/material';
import useOutsideClick from '@Hooks/useOutsideClick';
/*  setClientFilterSearchTerm,
  setClientFilterStages,
  setClientFilterLastInteraction,
  setClientSortCriteria,
  setClientShowHidden,
  setClientPage,
  invalidateClientCache,
  setRelationshipFilterName,
  setRelationshipFilterStages,
  setRelationshipFilterLastInteraction,
  setRelationshipSortCriteria,
  setRelationshipShowHidden,*/
import {
  setClientPage,
  setClientFilterSearchTerm,
  setClientFilterStages,
  setClientFilterLastInteraction,
  setClientShowHidden,
  setClientSortCriteria,
  changeClientFilterStages,
  changeClientFilterLastInteraction,
  changeClientSortCriteria,
  changeClientShowHidden,
  fetchClients,
  fetchAndSetHighestScores,
  setERMRelationshipCache,
  setERMTotalRelationships,
  fetchErmStagesThunk,
} from '../redux/ermDashboardSlice';
import { isValidRelationship, getContactId, compareByCriteria } from './ERMDashboard/utils/helpers';
import { debounce } from 'lodash';
import { Switch } from '@/components/ui/switch';
import { Label } from '@/components/ui/label';

export default function ERMDashboard() {
  const relationshipsRef = useRef(null);

  const { toast } = useToast();
  const dispatch = useDispatch();
  const clientPage = useSelector((state) => state.ermDashboard.clientTable.queryParams.page);
  const clientLimit = useSelector((state) => state.ermDashboard.clientTable.queryParams.limit);
  const clientTotal = useSelector((state) => state.ermDashboard.clientTable.data.total);
  const clientCache = useSelector((state) => state.ermDashboard.clientTable.data.cacheByPage);
  const relationshipCache = useSelector((state) => state.ermDashboard.relationshipTable.data.cacheById);
  const user = useSelector((state) => state.auth.user);
  const userCompany = useSelector((state) => state.auth.company?.company);
  const teamMembers = useSelector((state) => state.auth.company?.users);
  const clientStageFilters = useSelector((state) => state.ermDashboard.clientTable.queryParams.filters.stages);
  const clientNameSearchTerm = useSelector((state) => state.ermDashboard.clientTable.queryParams.filters.name);
  const clientStrengthFilters = useSelector((state) => state.ermDashboard.clientTable.queryParams.filters.strengths);
  const clientSortCriteria = useSelector((state) => state.ermDashboard.clientTable.queryParams.sortCriteria);
  const relationshipSortCriteria = useSelector(
    (state) => state.ermDashboard.relationshipTable.queryParams.sortCriteria,
  );
  const clientLastInteractionFilters = useSelector(
    (state) => state.ermDashboard.clientTable.queryParams.filters.lastInteraction,
  );
  const selectedClient = useSelector((state) => state.ermDashboard.selectedClient);
  const strengthFeatureEnabled = useIsFeatureFlagEnabled('ui.erm_dashboard.strength');
  const notesFeatureEnabled = useIsFeatureFlagEnabled('ui.erm_dashboard.notes');

  const [contactInteractions, setContactInteractions] = useState([]);
  const [isLoadingRelationships, setIsLoadingRelationships] = useState(false);
  const [isLoadingClients, setIsLoadingClients] = useState(false);
  const [editingStageClientId, setEditingStageClientId] = useState(null);
  const [isClientSettingStage, setisClientSettingStage] = useState({});
  const [interactionsModalState, setInteractionsModalState] = useState({
    open: false,
    relationship: null,
  });
  const [showHidden, setShowHidden] = useState(false);

  useEffect(() => {
    dispatch(fetchClients());
    dispatch(fetchErmStagesThunk());
  }, [dispatch]);

  // Add a timestamp to track cache validity
  let cacheTimestamp = Date.now();
  const INTERACTION_TYPE_MAP = {
    'Incoming LinkedIn InMails': ['linkedin_incoming'],
    'Outgoing LinkedIn InMails': ['linkedin_outgoing'],
    'Incoming Emails': ['email_incoming'],
    'Outgoing Emails': ['email_outgoing'],
    'Calendar Meetings': ['calendar_incoming', 'calendar_outgoing'],
    'Added as Contact': ['added_contact'],
  };

  const filteredAndSortedRelationships = useMemo(() => {
    if (!selectedClient?._id || !relationshipCache[selectedClient._id]) {
      return [];
    }

    const relationships = relationshipCache[selectedClient?._id];
    if (!Array.isArray(relationships)) return [];

    const mostRecentInteractionsMap = new Map();

    relationships.filter(isValidRelationship).forEach((relationship) => {
      const contactId = getContactId(relationship);
      if (!contactId) return;

      const currentMostRecent = mostRecentInteractionsMap.get(contactId);
      const newInteractionDate = relationship?.lastInteraction?.actionDate;

      if (
        !currentMostRecent ||
        compareDates(newInteractionDate, currentMostRecent?.lastInteraction?.actionDate, 'desc') > 0
      ) {
        mostRecentInteractionsMap.set(contactId, relationship);
      }
    });

    return Array.from(mostRecentInteractionsMap.values()).sort((a, b) =>
      compareByCriteria(a, b, relationshipSortCriteria),
    );
  }, [relationshipSortCriteria, selectedClient, relationshipCache]);

  const handleRelationshipSort = (key: string, direction: string) => {
    dispatch(setRelationshipSortCriteria({ key, direction }));
  };

  useEffect(() => {
    if (selectedClient?._id && relationshipsRef?.current) {
      relationshipsRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectedClient]);

  const handleInteractionsClick = async (relationship) => {
    try {
      const contactId = relationship?.contacts[0]?._id;
      const response = await getContactInteractions(
        contactId,
        1, // page
        100, // limit
        true, // successOnly
        false, // ownInteractionsOnly
      );
      setContactInteractions(response?.interactions);
      setInteractionsModalState({
        open: true,
        relationship,
      });
    } catch (error) {
      console.error('Error fetching contact interactions:', error);
    }
  };

  const fetchRelationships = async (clientId) => {
    setIsLoadingRelationships(true);
    try {
      const response = await getCompanyRelationships(clientId);
      const relationships = response?.relationships || [];
      const augmentedRelationships = relationships.filter(isValidRelationship).map((relationship) => {
        return {
          ...relationship,
          name: relationship?.contacts[0]?.name || 'Unknown Contact',
          position: relationship?.contacts[0]?.title || 'Unknown Position',
        };
      });

      dispatch(setERMRelationshipCache({ ...relationshipCache, [clientId]: augmentedRelationships }));
      dispatch(setERMTotalRelationships(relationships.length));
    } catch (error) {
      console.error('Error fetching relationships:', error);
      toast({
        title: 'Error',
        description: 'Failed to load relationships',
      });
    } finally {
      setIsLoadingRelationships(false);
    }
  };

  useEffect(() => {
    if (selectedClient?._id) {
      if (!relationshipCache[selectedClient?._id]) {
        fetchRelationships(selectedClient?._id);
      }
    }
  }, [selectedClient]);

  return (
    <div>
      <CssBaseline />
      <div className="flex flex-col">
        <main className="flex flex-1 flex-col gap-2 p-2 md:gap-4 md:p-4">
          <ClientTable />

          {selectedClient?._id && (
            <div
              ref={relationshipsRef}
              className="border shadow-sm rounded-lg mt-4 transition-all duration-300 ease-in-out"
            >
              <RelationshipTable
                relationships={filteredAndSortedRelationships}
                isLoading={isLoadingRelationships}
                sortCriteria={relationshipSortCriteria}
                onSort={handleRelationshipSort}
                strengthFeatureEnabled={strengthFeatureEnabled}
                onInteractionsClick={handleInteractionsClick}
                showHidden={showHidden}
              />
            </div>
          )}
        </main>
      </div>

      <InteractionsModal
        isOpen={interactionsModalState.open}
        onClose={() => setInteractionsModalState((prev) => ({ ...prev, open: false }))}
        relationship={interactionsModalState.relationship}
        interactions={contactInteractions}
        notesFeatureEnabled={notesFeatureEnabled}
      />
    </div>
  );
}
