import React, { useState } from 'react';
import { StageBadge } from './stage-badge';

interface BadgeEditListItemProps {
  text: string;
  backgroundColor: string;
  textColor: string;
  onTextChange: (newText: string) => void;
}

const MAX_CHARACTERS = 30;
export const BadgeEditListItem: React.FC<BadgeEditListItemProps> = ({
  initialText,
  backgroundColor,
  textColor,
  onTextChange,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(initialText);

  const handleBadgeClick = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  const handleChange = (newText: string) => {
    setText(newText);
    if (onTextChange) {
      onTextChange(newText);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleBlur();
    }
  };

  return (
    <div className="flex items-center justify-between w-full min-w-0">
      <div className="relative flex items-center flex-grow min-w-0 mr-2">
        <StageBadge
          initialText={text}
          backgroundColor={backgroundColor}
          textColor={textColor}
          isEditing={isEditing}
          editable={true}
          onClick={handleBadgeClick}
          onBlur={handleBlur}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          maxLength={MAX_CHARACTERS}
        />
        <span
          className="text-gray-500 ml-1"
          style={{
            fontSize: '0.5rem',
          }}
        >
          {text.length}/{MAX_CHARACTERS}
        </span>
      </div>
      <></>
    </div>
  );
};
