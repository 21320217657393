import { CircularProgress } from '@mui/material';
import { SortableHeader } from './SortableHeader';
import { Button } from '@/components/ui/button';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';
import React, { useState } from 'react';
import { InteractionsCell } from './InteractionsCell';
import VZeroCircularProgress from '../../VZeroCircularProgress';
import { EngageModal } from './EngageModal';
import { RelationshipPayload } from '@/types/relationshipTypes';
import { Handshake, Send } from 'lucide-react';
import { useSelector } from 'react-redux';
import { useIsFeatureFlagEnabled } from '@Hooks/useFeatureFlags';

const getColor = (progress: number) => {
  if (progress < 0.25) return '#ef4444'; // Red for low progress
  if (progress < 0.5) return '#f97316'; // Orange for below average progress
  if (progress < 0.75) return '#eab308'; // Yellow for average progress
  return '#22c55e'; // Green for high progress
};

type RelationshipTableProps = {
  relationships: RelationshipPayload[];
  isLoading: boolean;
  sortCriteria: { key: string; direction: string };
  onSort: (key: string) => void;
  strengthFeatureEnabled: boolean;
  onInteractionsClick: (relationship: RelationshipPayload) => void;
  showHidden: boolean;
};

export const RelationshipTable = ({
  relationships,
  isLoading,
  sortCriteria,
  onSort,
  strengthFeatureEnabled,
  onInteractionsClick,
  showHidden,
}: RelationshipTableProps) => {
  const [isEngageModalOpen, setIsEngageModalOpen] = useState(false);
  const [selectedRelationship, setSelectedRelationship] = useState<RelationshipPayload | null>(null);
  const highestCompanyClientContactRelationshipScore = useSelector(
    (state) => state.ermDashboard.highestCompanyClientContactRelationshipScore,
  );
  const selectedClient = useSelector((state) => state.ermDashboard.selectedClient);

  const handleEngageClick = (relationship: RelationshipPayload) => {
    setSelectedRelationship(relationship);
    setIsEngageModalOpen(true);
  };
  const scaleRelationshipScoresFeatureEnabled = useIsFeatureFlagEnabled('ui.erm_dashboard.scaleRelationshipScores');

  return (
    <div className="p-2">
      <h2 className="text-muted-foreground font-semibold text-lg mb-2">
        Relationships for <span className="text-black font-bold">{selectedClient.name}</span>
      </h2>

      <div className="relative w-full overflow-auto" style={{ paddingBottom: '10px' }}>
        <table className="w-full caption-bottom text-sm">
          <thead className="[&_tr]:border-b">
            <tr className="border-b transition-colors data-[state=selected]:bg-muted">
              <SortableHeader label="Name" sortKey="name" currentSort={sortCriteria} onSort={onSort} />
              <SortableHeader label="Position" sortKey="position" currentSort={sortCriteria} onSort={onSort} />
              {!showHidden && strengthFeatureEnabled && (
                <SortableHeader
                  label="Strength"
                  sortKey="relationshipScore"
                  currentSort={sortCriteria}
                  onSort={onSort}
                />
              )}
              <SortableHeader
                label="Last Interaction"
                sortKey="lastInteraction"
                currentSort={sortCriteria}
                onSort={onSort}
              />
              <th
                className="h-10 px-2 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0"
                style={{ width: '150px' }}
              >
                Actions
              </th>
            </tr>
          </thead>
          {!isLoading && relationships.length > 0 && (
            <tbody className="[&_tr:last-child]:border-0">
              <TooltipProvider>
                {relationships.map((relationship) => (
                  <tr
                    key={relationship?.contacts[0]?._id}
                    className={cn(
                      'border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted',
                      selectedRelationship?._id === relationship?._id && 'bg-gray-200/50',
                    )}
                  >
                    <td className="p-2 align-middle [&:has([role=checkbox])]:pr-0">
                      <span
                        className="cursor-pointer font-semibold hover:underline"
                        onClick={() => onInteractionsClick(relationship)}
                      >
                        {relationship?.contacts[0]?.name}
                      </span>
                    </td>
                    <td className="p-2 align-middle [&:has([role=checkbox])]:pr-0">
                      {relationship?.contacts[0]?.title}
                    </td>
                    {!showHidden && strengthFeatureEnabled && (
                      <td className="p-2 align-middle [&:has([role=checkbox])]:pr-0">
                        <span
                          style={{
                            marginLeft: '15px',
                            color: getColor(
                              (relationship?.relationshipScore || 0) / highestCompanyClientContactRelationshipScore,
                            ),
                          }}
                        >
                          {relationship?.relationshipScore || 0}
                        </span>
                      </td>
                    )}
                    <td className="p-2 align-middle [&:has([role=checkbox])]:pr-0 max-w-xs truncate overflow-ellipsis whitespace-nowrap">
                      <InteractionsCell interaction={relationship?.lastInteraction} />
                    </td>
                    <td className="p-2 align-middle [&:has([role=checkbox])]:pr-0" style={{ width: '150px' }}>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button size="sm" variant="outline" onClick={() => onInteractionsClick(relationship)}>
                            <Handshake className="w-4 h-4" />
                            <span className="sr-only">Interactions</span>
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>Interactions</TooltipContent>
                      </Tooltip>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button size="sm" variant="outline" onClick={() => handleEngageClick(relationship)}>
                            <Send className="w-4 h-4" />
                            <span className="sr-only">Engage</span>
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>Engage</TooltipContent>
                      </Tooltip>
                    </td>
                  </tr>
                ))}
              </TooltipProvider>
            </tbody>
          )}
        </table>

        {isLoading && (
          <div className="flex justify-center items-center mt-4">
            <CircularProgress size={32} color="inherit" />
          </div>
        )}

        {!isLoading && relationships.length === 0 && (
          <div className="flex justify-center items-center mt-4">
            <p className="text-muted-foreground">There are no relationships for this client.</p>
          </div>
        )}

        <EngageModal
          isOpen={isEngageModalOpen}
          onClose={() => setIsEngageModalOpen(false)}
          targetContact={selectedRelationship}
        />
      </div>
    </div>
  );
};
