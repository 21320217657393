import { createSlice } from '@reduxjs/toolkit';
import { setClientFilterStages } from './ermDashboardSlice';

const filterMetrics = [
  { topLeads: true },
  { topAccounts: true },
  { topOpportunities: true },
  { allLeads: false },
  { allAccounts: false },
  { allOpportunities: false },
];

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLoggedIn: false,
    sidebarOpen: false,
    setupStep: 1,
    twitter: false,
    salesforce: false,
    linkedIn: false,
    page: 'retention',
    settingsPageOverride: null,
    support: 'purchaseLicense',
    user: {},
    userTones: [{ Concise: 50 }, { Controversial: 0 }, { Funny: 0 }],
    userBio: {
      companyName: '',
      product: '',
      customerBenefits: '',
    },
    userScrapedBio: {
      companyName: '',
      product: '',
      customerBenefits: '',
    },
    twitterFilterSetupDone: false,
    salesforceFilterSetupDone: false,
    hubspotFilterSetupDone: false,
    googleFilterSetupDone: false,
    tonesSetupDone: false,
    topicsSetupDone: false,
    initialSetupDone: false,
    googleScopeIncluded: false,
    /* filters: [],
    inFilter: false, */
    twitterFilterMetrics: [{ followers: 0 }, { followings: 50 }],
    salesforceFilterMetrics: filterMetrics,
    hubspotFilterMetrics: filterMetrics,
    googleFilterMetrics: [{ allContacts: true }],
    postCount: 0,
    sequenceTwitterActions: { twitter_auto_like: false, twitter_auto_retweet: false, twitter_auto_follow: false },
    filters: {},
    composePostDetails: {},
    contacts: {},
    sequences: {},
    posts: [],
    delegations: [],
    selectedDelegation: null,
    delegationAccessId: null,
    delegationSequences: [],
    delegationLists: [],
    delegationListContacts: [],
    company: {
      company: {
        ermPreference: {
          pipelineStages: [],
          hiddenCompanyClientIds: [],
        },
        adminInvite: null,
        _id: null,
        name: '',
        emailDomains: [],
        websiteUrl: '',
        photoUrl: '',
        connectionsCount: 0,
        createdAt: null,
        updatedAt: null,
        __v: 0,
        admins: [],
        invitations: [],
        goals: {
          grow: false,
          acquire: false,
          retain: false,
        },
        targetAudience: {
          ai: false,
          csv: false,
          manualcontact: false,
          crm: false,
          existing: false,
        },
        maxUsers: 0,
        emailPreference: {
          maxEmailCount: 0,
          emailsSent: 0,
          lastEmailSentDate: null,
          isEmailLimitExceeded: false,
        },
      },
      users: [],
    },
    actionFlags: {
      refreshPosts: false,
      refreshDelegationSequences: false,
      refreshDelegationLists: false,
    },
    // feature flags
    featureFlags: [],
  },
  reducers: {
    setState(state, action) {
      Object.assign(state, action.payload);
    },
    setFeatureFlags(state, action) {
      state.featureFlags = action.payload;
    },
    login(state) {
      state.isLoggedIn = true;
    },
    logout(state) {
      state.isLoggedIn = false;
    },
    openBar(state) {
      state.sidebarOpen = true;
    },
    closeBar(state) {
      state.sidebarOpen = false;
    },
    toggleBar(state) {
      state.sidebarOpen = !state.sidebarOpen;
    },
    incrementSetup(state) {
      state.setupStep += 1;
    },
    navigateSetupStep(state, action) {
      state.setupStep = action.payload;
    },
    salesForceLogIn(state) {
      state.salesforce = true;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setSettingsPageOverride(state, action) {
      state.settingsPageOverride = action.payload;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setUserTones(state, action) {
      state.userTones = action.payload;
    },
    setUserCompanyWebsite(state, action) {
      state.userCompanyWebsite = action.payload;
    },
    setUserScrapedBio(state, action) {
      state.userScrapedBio = action.payload;
    },
    setUserBio(state, action) {
      state.userBio = action.payload;
    },
    setTwitterFilters(state, action) {
      state.twitterFilterMetrics = action.payload;
    },
    setSalesforceFilters(state, action) {
      state.salesforceFilterMetrics = action.payload;
    },
    setHubspotFilters(state, action) {
      state.hubspotFilterMetrics = action.payload;
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setGoogleFilters(state, action) {
      state.googleFilterMetrics = action.payload;
    },
    setPostCount(state, action) {
      state.postCount = action.payload;
    },
    toggleTwitterFilter(state, action) {
      state.twitterFilterSetupDone = action.payload;
    },
    toggleSalesforceFilter(state, action) {
      state.salesforceFilterSetupDone = action.payload;
    },
    toggleHubspotFilter(state, action) {
      state.hubspotFilterSetupDone = action.payload;
    },
    toggleGoogleFilter(state, action) {
      state.googleFilterSetupDone = action.payload;
    },
    toggleToneSetup(state) {
      state.tonesSetupDone = true;
    },
    toggleTopicsSetup(state) {
      state.topicsSetupDone = true;
    },
    toggleInitialSetup(state) {
      state.initialSetupDone = true;
    },
    setSequenceTwitterActions(state, action) {
      state.sequenceTwitterActions = action.payload;
    },
    setComposePostDetails(state, action) {
      state.composePostDetails = action.payload;
    },
    toggleGoogleScopeIncluded(state, action) {
      state.googleScopeIncluded = action.payload;
    },
    setContacts(state, action) {
      state.contacts = action.payload;
    },
    setSequences(state, action) {
      state.sequences = action.payload;
    },
    setPosts(state, action) {
      state.posts = action.payload;
    },
    setDelegations(state, action) {
      state.delegations = action.payload;
    },
    setSelectedDelegation(state, action) {
      state.selectedDelegation = action.payload;
      localStorage.setItem('selectedDelegation', JSON.stringify(action.payload));
    },
    hydrateSelectedDelegation(state) {
      const savedDelegation = localStorage.getItem('selectedDelegation');
      if (savedDelegation) {
        state.selectedDelegation = JSON.parse(savedDelegation);
      }
    },
    setCompany(state, action) {
      state.company = action.payload;
    },
    setActionFlag(state, action) {
      const { flag, value } = action.payload;
      if (state.actionFlags.hasOwnProperty(flag)) {
        state.actionFlags[flag] = value;
      }
    },
    setDelegationAccessId(state, action) {
      state.delegationAccessId = action.payload;
    },
    setDelegationSequences(state, action) {
      state.delegationSequences = action.payload;
    },
    setDelegationLists(state, action) {
      state.delegationLists = action.payload;
    },
    setDelegationListContacts(state, action) {
      state.delegationListContacts = action.payload;
    },
    /*,
    addToFilter(state, action) {
      state.filters.push(action.payload)
    },
    removeFromFilter (state){
      state.filters.shift()
    },
    toggleFilter (state) {
      state.inFilter = !state.inFilter
    },
    setTwitterFilter (state) {
      state.twitterFilter = true
    } */
    addNewSequence: (state, action) => {
      const newSequence = action.payload;
      state.sequences = {
        ...state.sequences,
        [newSequence._id]: newSequence,
      };
    },
    setPipelineStages: {
      reducer(state, action) {
        if (state.company?.company?.ermPreference) {
          console.log('setting pipeline stages:', action.payload);
          state.company.company.ermPreference.pipelineStages = action.payload;
        }
      },
      prepare(stages) {
        console.log('prepare pipeline stages:', stages);
        return {
          payload: stages,
          meta: {
            shouldUpdateErmStages: true,
            stages,
          },
        };
      },
    },
  },
});

export const {
  setState,
  login,
  logout,
  openBar,
  closeBar,
  toggleBar,
  incrementSetup,
  salesForceLogIn,
  setPage,
  setUser,
  setSettingsPageOverride,
  setUserTones,
  setUserCompanyWebsite,
  setUserScrapedBio,
  setUserBio,
  setTwitterFilters,
  setSalesforceFilters,
  setHubspotFilters,
  setGoogleFilters,
  setFilters,
  navigateSetupStep,
  setPostCount,
  toggleTwitterFilter,
  toggleSalesforceFilter,
  toggleHubspotFilter,
  toggleGoogleFilter,
  setUpdateTwitterFilters,
  setUpdateSalesforceFilters,
  setUpdateHubspotFilters,
  setUpdateGoogleFilters,
  setSequenceTwitterActions,
  setComposePostDetails,
  setFeatureFlags,
  toggleGoogleScopeIncluded,
  setContacts,
  setSequences,
  setPosts,
  setDelegations,
  setSelectedDelegation,
  hydrateSelectedDelegation,
  setCompany,
  setActionFlag,
  setDelegationAccessId,
  setDelegationSequences,
  setDelegationLists,
  setDelegationListContacts,
  addNewSequence,
  setPipelineStages,
} = authSlice.actions;

export const pipelineStagesMiddleware = (store) => (next) => (action) => {
  const result = next(action);

  if (action.type === setPipelineStages.type && action.meta?.shouldUpdateErmStages) {
    const stages = action.meta.stages;
    const stagesFilter = stages.reduce((acc, stage) => {
      acc[stage.name] = true;
      return acc;
    }, {});

    store.dispatch(setClientFilterStages(stagesFilter));
  }

  return result;
};

export default authSlice.reducer;
