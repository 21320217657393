import React from 'react';
import { Popover, MenuItem, Divider } from '@mui/material';
import { NestedMenuItem } from 'mui-nested-menu';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as NewArrowRight } from '../../assets/Icons/New-Right-Arrow.svg';
import { ReactComponent as SendEmail } from '../../assets/Icons/SendEmail.svg';
import { ReactComponent as Social } from '../../assets/Icons/Social.svg';
import { ReactComponent as SendGift } from '../../assets/Icons/SendGift.svg';
import { TopicType, Milestones } from '@/constants/constant';

const PNested = ({ data, anchorEl, setAnchorEl, handleAddStep, twitterConnected, steps }) => {
  const theme = useTheme();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const groupIcons = {
    Social: <Social style={{ marginRight: -1, width: '12px', height: '12px' }} />,
    Email: <SendEmail style={{ marginRight: -1, width: '12px', height: '12px' }} />,
    Gift: <SendGift style={{ marginRight: -1, width: '12px', height: '12px' }} />,
  };

  const isRecurringStepIncluded = steps.some((step) => step.id === 'checkin.recurring');
  const isAutoConnectIncluded = steps.some((step) => step.id === 'connect');
  const isAutoFollowIncluded = steps.some((step) => step.id === 'follow');
  const isAutoLikeIncluded = steps.some((step) => step.id === 'like');
  const isAutoCommentIncluded = steps.some((step) => step.id === 'comment');
  const isAutoReshareIncluded = steps.some((step) => step.id === 'share');
  const isLinkedInMailIncluded = steps.some((step) => step.id === TopicType.LINKEDIN_EMAIL);
  const isSEC144Included = steps.some((step) => step.milestone === Milestones.SEC144);
  const isJobChangeIncluded = steps.some((step) => step.milestone === Milestones.JOB_CHANGE);
  const isJobPromotionIncluded = steps.some((step) => step.milestone === Milestones.JOB_PROMOTION);
  const isCertificationIncluded = steps.some((step) => step.milestone === Milestones.NEW_CERTIFICATION);
  const isAcquisitionIncluded = steps.some((step) => step.milestone === Milestones.ACQUISITION);
  const isBirthdayIncluded = steps.some((step) => step.milestone === Milestones.BIRTHDAY);
  const isAwardsIncluded = steps.some((step) => step.milestone === Milestones.AWARDS);

  return (
    <div>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            zIndex: 999,
            width: '173px',
            borderRadius: '12px',
            padding: '8px',
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.12)',
          },
        }}
      >
        {data.map((group, index) => (
          <NestedMenuItem
            key={index}
            label={group.group}
            parentMenuOpen={Boolean(anchorEl)}
            leftIcon={<div style={{ display: 'flex', alignItems: 'center' }}>{groupIcons[group.group]}</div>}
            rightIcon={<NewArrowRight />}
            sx={{
              '&:hover': {
                backgroundColor: theme.palette.primaryCL.Blue40,
              },
            }}
          >
            {group.items.map((item, subIndex) => {
              const isDisabled =
                !item.isSubMenuItemEnabled ||
                (!twitterConnected && item.requiresTwitter) ||
                (item.id === 'checkin.recurring' && isRecurringStepIncluded) ||
                (item.id === 'connect' && isAutoConnectIncluded) ||
                (item.id === 'follow' && isAutoFollowIncluded) ||
                (item.id === 'like' && isAutoLikeIncluded) ||
                (item.id === 'comment' && isAutoCommentIncluded) ||
                (item.id === 'share' && isAutoReshareIncluded) ||
                (item.id === TopicType.LINKEDIN_EMAIL && isLinkedInMailIncluded) ||
                (item.milestone === Milestones.SEC144 && isSEC144Included) ||
                (item.milestone === Milestones.JOB_CHANGE && isJobChangeIncluded) ||
                (item.milestone === Milestones.JOB_PROMOTION && isJobPromotionIncluded) ||
                (item.milestone === Milestones.NEW_CERTIFICATION && isCertificationIncluded) ||
                (item.milestone === Milestones.ACQUISITION && isAcquisitionIncluded) ||
                (item.milestone === Milestones.BIRTHDAY && isBirthdayIncluded) ||
                (item.milestone === Milestones.AWARDS && isAwardsIncluded);

              return (
                <MenuItem
                  key={subIndex}
                  disabled={isDisabled}
                  onClick={() => handleAddStep(item)}
                  sx={{
                    '&:hover': {
                      backgroundColor: theme.palette.primaryCL.Blue40,
                      borderRadius: '8px',
                    },
                  }}
                >
                  {item.desc}
                </MenuItem>
              );
            })}
          </NestedMenuItem>
        ))}
      </Popover>
    </div>
  );
};

export default PNested;
