import { Mail, Linkedin, ArrowUpRight, ArrowDownLeft, UserPlus, Minus, Calendar, Video } from 'lucide-react';
import moment from 'moment';

export const InteractionIcons = ({ interaction }: { interaction: any }) => {
  const messagingPlatform = interaction?.messagingPlatform;
  const direction = interaction?.messagingTraffic;
  const action = interaction?.action;

  const renderInteractionIcon = () => {
    switch (messagingPlatform) {
      case 'email':
        return <Mail className="h-4 w-4 text-gray-500" />;
      case 'linkedin':
        return <Linkedin className="h-4 w-4 text-gray-500" />;
      case 'calendar':
        return <Calendar className="h-4 w-4 text-gray-500" />;
      case 'meeting':
        return <Video className="h-4 w-4 text-gray-500" />;
      case 'none':
        return action === 'addedToSequence' ? <UserPlus className="h-4 w-4 text-gray-500" /> : null;
      default:
        return null;
    }
  };

  const renderDirectionIcon = () => {
    if (direction === 'incoming' || messagingPlatform === 'calendar') {
      return <ArrowDownLeft className="h-4 w-4 text-green-500" />;
    } else if (direction === 'outgoing') {
      return <ArrowUpRight className="h-4 w-4 text-blue-500" />;
    } else if (action === 'addedToSequence' || messagingPlatform === 'meeting') {
      return <Minus className="h-4 w-4 text-purple-300" strokeWidth={4} />;
    }
    return null;
  };

  return (
    <div className="flex items-center text-sm">
      <div className="flex items-center gap-2">
        <div className="flex items-center">
          {renderInteractionIcon()}
          {renderDirectionIcon()}
        </div>
        <div>
          {(interaction?.actionDate &&
            moment(interaction?.actionDate).isValid() &&
            moment(interaction?.actionDate).fromNow()) ||
            'None'}
        </div>
      </div>
      {interaction?.user?.name && <span className="text-muted-foreground">&nbsp;with {interaction.user.name}</span>}
    </div>
  );
};
