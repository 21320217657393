/*
"location": "Amsterdam, North Holland, NL"
          "location": "Arlington, VA, US"
          "location": "Berlin, DE"
          "location": "Boston, MA, US"
          "location": "Bristow, MA, US"
          "location": "Buenos Aires, AR"
          "location": "Calgary, AB, CA"
          "location": "Centreville, VA, US"
          "location": "Darlington, England, UK"
          "location": "Etobicoke, ON, CA"
          "location": "Fairfield, CA, US"
          "location": "Leeds, England, UK"
          "location": "Leesburg, VA, US"
          "location": "Lexington, MA, US"
          "location": "London, England, UK"
          "location": "Los Angeles, CA, US"
          "location": "Lufkin, TX, US"
          "location": "Manila, PH"
          "location": "Markham, ON, CA"
          "location": "Mountain View, CA, US"
          "location": "New York, NY, US"
          "location": "North Charleston, SC, US"
          "location": "Orlando, Florida, US"
          "location": "Pensacola, Florida, US"
          "location": "Reston, VA, US"
          "location": "San Francisco, CA, US"
          "location": "Suffolk County, MA, US"
          "location": "Tampa, Florida, US"
          "location": "Toronto, ON, CA"
          "location": "Vancouver, BC, CA"
          "location": "Vienna, VA, US"
          "location": "Washington, DC, US"
*/

export const demoData = [
  {
    _id: {
      $oid: '673fdf924743d65402092ea2',
    },
    contacts: [
      {
        contactId: {
          $oid: '673fdf914743d65402092e9f',
        },
        relationshipScore: 1290,
        knownBy: ['Jody Glidden', 'James Wang'],
        contactInfo: [
          {
            _id: {
              $oid: '673fdf884743d65402092de4',
            },
            name: 'Ernesto Oddone',
            linkedin_url: 'https://www.linkedin.com/in/ernestooddone',
            title: 'Director, Information Security and Technology Services',
            location: 'Toronto, ON, CA',
          },
        ],
      },
      {
        contactId: {
          $oid: '673fdfcb4743d65402093d64',
        },
        relationshipScore: 14495,
        knownBy: ['James Wang', 'Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '673fdfc64743d65402093998',
            },
            name: 'Nicole Miles',
            linkedin_url: 'https://www.linkedin.com/in/ms-nicole-miles',
            title: 'Chief Clients and Markets Officer, CA',
            location: 'Toronto, ON, CA',
          },
        ],
      },
      {
        contactId: {
          $oid: '673fe5ed4743d65402206bf6',
        },
        relationshipScore: 400,
        knownBy: ['James Wang', 'Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '673fe5e44743d65402206be0',
            },
            name: 'Jodi Prested',
            linkedin_url: 'https://www.linkedin.com/in/jodi-prested-8349428b',
            title: 'Executive Assistant, Profile and Client Development (CA)',
            location: 'Toronto, ON, CA',
          },
        ],
      },
      {
        contactId: {
          $oid: '673fe8754743d65402265f46',
        },
        relationshipScore: 80,
        knownBy: ['Jody Glidden', 'James Wang'],
        contactInfo: [
          {
            _id: {
              $oid: '673fe86f4743d65402265f34',
            },
            name: 'Andrea Peebles',
            linkedin_url: 'https://www.linkedin.com/in/andreapeebles',
            title: 'Privacy and Compliance Manager',
            location: 'Toronto, ON, CA',
          },
        ],
      },
      {
        contactId: {
          $oid: '673fec094743d654022f4d07',
        },
        relationshipScore: 1080,
        knownBy: ['Jody Glidden', 'James Wang'],
        contactInfo: [
          {
            _id: {
              $oid: '673febfe4743d654022f4c26',
            },
            name: 'Janet McGuigan',
            linkedin_url: 'https://www.linkedin.com/in/janet-mcguigan-34577313',
            title: 'Global Program Director',
            location: 'Darlington, England, UK',
          },
        ],
      },
      {
        contactId: {
          $oid: '673fecbe4743d65402323625',
        },
        relationshipScore: 520,
        knownBy: ['Jody Glidden', 'James Wang'],
        contactInfo: [
          {
            _id: {
              $oid: '673fecbb4743d65402323566',
            },
            name: 'Scott Saundry',
            linkedin_url: 'https://www.linkedin.com/in/scottsaundry',
            title: 'Chief Information Officer (CA)',
            location: 'Calgary, AB, CA',
          },
        ],
      },
      {
        contactId: {
          $oid: '6740034a7573574cdd29e96e',
        },
        relationshipScore: 900,
        knownBy: ['James Wang', 'Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '674003447573574cdd29e960',
            },
            name: 'Ian McGrath',
            linkedin_url: 'https://www.linkedin.com/in/ian-mcgrath-8587434',
            title: 'Partner',
            location: 'Amsterdam, North Holland, NL',
          },
        ],
      },
      {
        contactId: {
          $oid: '674003527573574cdd29e98c',
        },
        relationshipScore: 90,
        knownBy: ['Jody Glidden', 'James Wang'],
        contactInfo: [
          {
            _id: {
              $oid: '6740034e7573574cdd29e980',
            },
            name: 'Glaucia Quednau',
            linkedin_url: 'https://www.linkedin.com/in/quednau/',
            title: 'Global Client Program Manager',
            location: 'Berlin, DE',
          },
        ],
      },
      {
        contactId: {
          $oid: '6740037d7573574cdd29e9c0',
        },
        relationshipScore: 200,
        knownBy: ['Jody Glidden', 'James Wang'],
        contactInfo: [
          {
            _id: {
              $oid: '674003707573574cdd29e9b4',
            },
            name: 'Ijeoma Hunter',
            linkedin_url: 'https://www.linkedin.com/in/ijeomahunter',
            title: 'Global Clients and Markets Senior Manager - Consumer',
            location: 'London, England, UK',
          },
        ],
      },
      {
        contactId: {
          $oid: '6740038c7573574cdd29e9da',
        },
        relationshipScore: 300,
        knownBy: ['James Wang', 'Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '674003827573574cdd29e9cc',
            },
            name: 'David Clay',
            linkedin_url: 'https://www.linkedin.com/in/david-clay-013aa3209',
            title: 'Global Clients and Markets - Resources Industry',
            location: 'Leeds, England, UK',
          },
        ],
      },
      {
        contactId: {
          $oid: '6740039c7573574cdd29e9f4',
        },
        relationshipScore: 400,
        knownBy: ['Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '674003907573574cdd29e9e6',
            },
            name: 'Antonis Patrikios',
            linkedin_url: 'https://www.linkedin.com/in/antonis-patrikios-39342815',
            title: 'Partner, Co-lead Global Privacy & Cyber Group, Global TMT Sector Lead',
            location: 'London, England, UK',
          },
        ],
      },
      {
        contactId: {
          $oid: '67400a43b22ffcb69d47261c',
        },
        relationshipScore: 180,
        knownBy: ['Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '67400a38b22ffcb69d47260e',
            },
            name: 'Sarah Klein',
            linkedin_url: 'https://www.linkedin.com/in/sarah-l-d-klein-9886a21b',
            title: 'Director in the Office of the General Counsel',
            location: 'Calgary, AB, CA',
          },
        ],
      },
      {
        contactId: {
          $oid: '6744e70ebcf141420193f9c5',
        },
        relationshipScore: 400,
        knownBy: ['Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '66689dbccd4e51881ac93ed3',
            },
            name: 'Larry Nevsky',
            title: 'Partner',
            linkedin_url: 'https://www.linkedin.com/in/larry-nevsky-01708725',
            location: 'Toronto, ON, CA',
          },
        ],
      },
    ],
    companyData: {
      name: 'Dentons',
      industry: 'Legal',
      employees: '10,000+',
      relationshipScore: 20335,
    },
  },
  {
    _id: {
      $oid: '673fde7b4743d654020640b3',
    },
    contacts: [
      {
        contactId: {
          $oid: '673fde7a4743d654020640b0',
        },
        relationshipScore: 1200,
        knownBy: ['James Wang', 'Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '673fde714743d65402064096',
            },
            name: 'Bernice Furrer',
            linkedin_url: 'https://www.linkedin.com/in/bernicefurrer',
            title: 'Senior Manager, National Relationships Office',
            location: 'Vancouver, BC, CA',
          },
        ],
      },
      {
        contactId: {
          $oid: '673fdf024743d6540206454e',
        },
        relationshipScore: 4680,
        knownBy: ['James Wang', 'Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '673fdef94743d6540206453e',
            },
            name: 'Gillian Richards',
            linkedin_url: 'https://www.linkedin.com/in/gillianrichards',
            title: 'Director',
            location: 'Toronto, ON, CA',
          },
        ],
      },
      {
        contactId: {
          $oid: '673fe4954743d654021d7098',
        },
        relationshipScore: 2850,
        knownBy: ['James Wang', 'Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '673fe4834743d654021d6f7c',
            },
            name: 'Kristi Kelly',
            linkedin_url: 'https://www.linkedin.com/in/kristi-kelly-28223420',
            title: 'Director',
            location: 'Toronto, ON, CA',
          },
        ],
      },
      {
        contactId: {
          $oid: '673fe7de4743d65402265907',
        },
        relationshipScore: 0,
        contactInfo: [
          {
            _id: {
              $oid: '673fe7da4743d654022658e8',
            },
            name: 'Jutara Thangamornrart',
            linkedin_url: 'https://www.linkedin.com/in/jutarathang',
            title: 'Senior Manager, Strategy and Implementation',
            location: 'Toronto, ON, CA',
          },
        ],
      },
      {
        contactId: {
          $oid: '673fe8354743d65402265cc3',
        },
        relationshipScore: 780,
        knownBy: ['Jody Glidden', 'James Wang'],
        contactInfo: [
          {
            _id: {
              $oid: '673fe82d4743d65402265bfe',
            },
            name: 'Maya Boritz',
            linkedin_url: 'https://www.linkedin.com/in/mayaboritz',
            title: 'Digital and Sales Transformation Lead',
            location: 'Toronto, ON, CA',
          },
        ],
      },
      {
        contactId: {
          $oid: '674003b77573574cdd29ea3c',
        },
        relationshipScore: 200,
        knownBy: ['Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '674003b27573574cdd29ea2c',
            },
            name: 'Jay Crone',
            linkedin_url: 'https://www.linkedin.com/in/jaycrone',
            title: 'Managing Director',
            location: 'Vancouver, BC, CA',
          },
        ],
      },
      {
        contactId: {
          $oid: '674003c37573574cdd29ea54',
        },
        relationshipScore: 200,
        knownBy: ['Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '674003bc7573574cdd29ea48',
            },
            name: 'Jonathan Wolkin',
            linkedin_url: 'https://www.linkedin.com/in/jonathan-wolkin-bb986917',
            title: 'Managing Director',
            location: 'Toronto, ON, CA',
          },
        ],
      },
      {
        contactId: {
          $oid: '6740157d529541eed8e01da4',
        },
        relationshipScore: 20,
        knownBy: ['James Wang'],
        contactInfo: [
          {
            _id: {
              $oid: '67401576529541eed8e01d94',
            },
            name: 'Parker Bollmann',
            linkedin_url: 'https://www.linkedin.com/in/parkerbollmann',
            title: 'Senior Consultant',
            location: 'Toronto, ON, CA',
          },
        ],
      },
      {
        contactId: {
          $oid: '674017a9529541eed8e0349e',
        },
        relationshipScore: 240,
        knownBy: ['James Wang', 'Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '6740179f529541eed8e0348e',
            },
            name: 'Emily Goffin',
            linkedin_url: 'https://www.linkedin.com/in/emily-goffin-aa046835',
            title: 'Director, Priority Client and Cluster Programs',
            location: 'Toronto, ON, CA',
          },
        ],
      },
      {
        contactId: {
          $oid: '674017b9529541eed8e0354f',
        },
        relationshipScore: 10,
        knownBy: ['James Wang'],
        contactInfo: [
          {
            _id: {
              $oid: '674017af529541eed8e0353f',
            },
            name: 'Stacie Baillie',
            linkedin_url: 'https://www.linkedin.com/in/stacie-baillie-96407a27',
            title: 'Senior Account Manager',
            location: 'Toronto, ON, CA',
          },
        ],
      },
      {
        contactId: {
          $oid: '67402b32efb8943e1f9571ca',
        },
        relationshipScore: 20,
        knownBy: ['Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '67402b28efb8943e1f9571bc',
            },
            name: 'Julie Fricker',
            linkedin_url: 'https://www.linkedin.com/in/julie-fricker-b41b7515',
            title: 'Executive Assistant',
            location: 'Toronto, ON, CA',
          },
        ],
      },
      {
        contactId: {
          $oid: '67402b86efb8943e1f9572e2',
        },
        relationshipScore: 100,
        knownBy: ['Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '67402b7cefb8943e1f9572d2',
            },
            name: 'Dana Ursulescu',
            linkedin_url: 'https://www.linkedin.com/in/dana-ursulescu-4423a22',
            title: 'Partner | National Energy & Chemicals Leader',
            location: 'Calgary, AB, CA',
          },
        ],
      },
      {
        contactId: {
          $oid: '67402f62efb8943e1f95962c',
        },
        relationshipScore: 100,
        knownBy: ['Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '67402f58efb8943e1f95961c',
            },
            name: 'Tony Krajewski',
            linkedin_url: 'https://www.linkedin.com/in/tony-krajewski-405895',
            title: 'Partner',
            location: 'Toronto, ON, CA',
          },
        ],
      },
    ],
    companyData: {
      name: 'Deloitte',
      industry: 'Accounting',
      employees: '400,000+',
      relationshipScore: 10400,
    },
  },
  {
    _id: {
      $oid: '673fe4e84743d654021d7f61',
    },
    contacts: [
      {
        contactId: {
          $oid: '673fe18b4743d65402149f37',
        },
        relationshipScore: 5180,
        knownBy: ['Jody Glidden', 'James Wang'],
        contactInfo: [
          {
            _id: {
              $oid: '673fe1824743d65402149f16',
            },
            name: 'Matt Bloom',
            linkedin_url: 'https://www.linkedin.com/in/matt-bloom-547716b',
            title: 'Senior Manager, Competitive Intelligence',
            location: 'New York, NY, US',
          },
        ],
      },
      {
        contactId: {
          $oid: '673fe4e74743d654021d7f5e',
        },
        relationshipScore: 3124,
        knownBy: ['Jody Glidden', 'James Wang'],
        contactInfo: [
          {
            _id: {
              $oid: '666fada7fb4befd8ffb864f2',
            },
            name: 'Meghan Flake',
            title: 'Senior Operations Manager',
            linkedin_url: 'https://www.linkedin.com/in/meghanflake',
            location: 'San Francisco, CA, US',
          },
        ],
      },
      {
        contactId: {
          $oid: '67400d75efb8943e1f94c865',
        },
        relationshipScore: 220,
        knownBy: ['Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '67400d6eefb8943e1f94c857',
            },
            name: 'William Skinner',
            linkedin_url: 'https://www.linkedin.com/in/william-skinner-01516b14',
            title: 'Partner - Tax Group',
            location: 'Mountain View, CA, US',
          },
        ],
      },
      {
        contactId: {
          $oid: '67401758529541eed8e0313a',
        },
        relationshipScore: 33,
        knownBy: ['James Wang', 'Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '6740174f529541eed8e0312c',
            },
            name: 'Elliott Gurnick',
            linkedin_url: 'https://www.linkedin.com/in/elliottgurnick',
            title: 'Technology Attorney',
            location: 'Los Angeles, CA, US',
          },
        ],
      },
      {
        contactId: {
          $oid: '674019b7efb8943e1f950990',
        },
        relationshipScore: 248,
        knownBy: ['Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '666c66165ec0490a34111cf5',
            },
            name: 'Michael Esquivel',
            title: 'Startup Lawyer',
            linkedin_url: 'https://www.linkedin.com/in/mtesquivel',
            location: 'Mountain View, CA, US',
          },
        ],
      },
      {
        contactId: {
          $oid: '67401bc2efb8943e1f951567',
        },
        relationshipScore: 88,
        knownBy: ['Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '67401bb8efb8943e1f951557',
            },
            name: 'Tonya Lyerly',
            linkedin_url: 'https://www.linkedin.com/in/tonya-lyerly-6942aa54',
            title: 'Client Accounting Supervisor',
            location: 'Fairfield, CA, US',
          },
        ],
      },
      {
        contactId: {
          $oid: '67402e83efb8943e1f958c48',
        },
        relationshipScore: 6,
        knownBy: ['Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '67402e78efb8943e1f958c38',
            },
            name: 'Christopher Crawford',
            linkedin_url: 'https://www.linkedin.com/in/christopher-crawford-222a56a',
            title: 'Associate',
            location: 'Los Angeles, CA, US',
          },
        ],
      },
      {
        contactId: {
          $oid: '6745f3108ef6051dc202b1ac',
        },
        relationshipScore: 165,
        knownBy: ['Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '666c66165ec0490a34111ce5',
            },
            name: 'Michael Brown',
            title: 'Partner',
            linkedin_url: 'https://www.linkedin.com/in/michaelalanbrown1',
            location: 'San Francisco, CA, US',
          },
        ],
      },
      {
        contactId: {
          $oid: '6745f3918ef6051dc204c419',
        },
        relationshipScore: 165,
        knownBy: ['Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '666c66165ec0490a34111d49',
            },
            name: 'Ran Ben-Tzur',
            title: 'Partner',
            linkedin_url: 'https://www.linkedin.com/in/ranb1',
            location: 'Los Angeles, CA, US',
          },
        ],
      },
    ],
    companyData: {
      name: 'Fenwick & West',
      industry: 'Legal',
      employees: '1,000+',
      relationshipScore: 9229,
    },
  },
  {
    _id: {
      $oid: '673fd9a14743d65402fa5774',
    },
    contacts: [
      {
        contactId: {
          $oid: '673fd99f4743d65402fa5771',
        },
        relationshipScore: 6300,
        knownBy: ['James Wang', 'Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '64c1dc1e466bdb43759122db',
            },
            name: 'Scott Harper',
            title: 'VICE President',
            linkedin_url: 'https://www.linkedin.com/in/scott-harper-99b8281',
            location: 'Toronto, ON, CA',
          },
        ],
      },
      {
        contactId: {
          $oid: '673fd9f64743d65402fa5ddc',
        },
        relationshipScore: 800,
        knownBy: ['James Wang', 'Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '673fd9e84743d65402fa5dcc',
            },
            name: 'Daniel Reid',
            linkedin_url: 'https://www.linkedin.com/in/daniel-reid-7a194710',
            title: 'Senior Vice President, Managing Director',
            location: 'Toronto, ON, CA',
          },
        ],
      },
      {
        contactId: {
          $oid: '673fda184743d65402fa63af',
        },
        relationshipScore: 720,
        knownBy: ['James Wang'],
        contactInfo: [
          {
            _id: {
              $oid: '673fda134743d65402fa5fd0',
            },
            name: 'Adrian Lee',
            linkedin_url: 'https://www.linkedin.com/in/adrian-lee-3b701327',
            title: 'Executive Vice President Managing Director',
            location: 'Markham, ON, CA',
          },
        ],
      },
      {
        contactId: {
          $oid: '673fdb3c4743d65402fd683b',
        },
        relationshipScore: 80,
        knownBy: ['James Wang', 'Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '673fdb384743d65402fd6825',
            },
            name: 'Tajpreet Madan',
            linkedin_url: 'https://www.linkedin.com/in/tajpreetmadan',
            title: 'Marketing Coordinator',
            location: 'Toronto, ON, CA',
          },
        ],
      },
      {
        contactId: {
          $oid: '67400a8bb22ffcb69d472810',
        },
        relationshipScore: 0,
        contactInfo: [
          {
            _id: {
              $oid: '67400a81b22ffcb69d472802',
            },
            name: 'Nicholas Potkidis',
            linkedin_url: 'https://www.linkedin.com/in/nicholas-potkidis',
            title: 'Vice President, Sales Manager',
            location: 'Toronto, ON, CA',
          },
        ],
      },
      {
        contactId: {
          $oid: '674a22ac9a8ccf2767ca805d',
        },
        relationshipScore: 400,
        knownBy: ['James Wang'],
        contactInfo: [
          {
            _id: {
              $oid: '674a22a59a8ccf2767ca7fe5',
            },
            name: 'Tony Gill',
            linkedin_url: 'https://www.linkedin.com/in/tony-gill-3a3755a',
            title: 'Executive Vice President',
            location: 'Markham, ON, CA',
          },
        ],
      },
    ],
    companyData: {
      name: 'Cbre',
      industry: 'Real Estate',
      employees: '130,000+',
      relationshipScore: 8300,
    },
  },
  {
    _id: {
      $oid: '673fe4d94743d654021d7f15',
    },
    contacts: [
      {
        contactId: {
          $oid: '673fe4d94743d654021d7f12',
        },
        relationshipScore: 7200,
        knownBy: ['James Wang', 'Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '673fe4cf4743d654021d7e4a',
            },
            name: 'Doug Carsley',
            linkedin_url: 'https://www.linkedin.com/in/dougcarsley',
            title: 'Partner',
            location: 'Toronto, ON, CA',
          },
        ],
      },
      {
        contactId: {
          $oid: '673fe6704743d654022353fa',
        },
        relationshipScore: 300,
        knownBy: ['James Wang'],
        contactInfo: [
          {
            _id: {
              $oid: '66689dbccd4e51881ac93ee0',
            },
            name: 'Neil Bhattacharya',
            title: 'Partner, Consulting',
            linkedin_url: 'https://www.linkedin.com/in/neilbhattacharya',
            location: 'Toronto, ON, CA',
          },
        ],
      },
      {
        contactId: {
          $oid: '67401066529541eed8dfe63a',
        },
        relationshipScore: 40,
        knownBy: ['James Wang', 'Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '6740105d529541eed8dfe62c',
            },
            name: 'Abigail Sampang',
            linkedin_url: 'https://www.linkedin.com/in/abigail-sampang-626519103',
            title: 'Team Lead',
            location: 'Manila, PH',
          },
        ],
      },
    ],
    companyData: {
      name: 'EY',
      industry: 'Accounting',
      employees: '400,000+',
      relationshipScore: 7540,
    },
  },
  {
    _id: {
      $oid: '673fe2074743d65402178790',
    },
    contacts: [
      {
        contactId: {
          $oid: '673fe2064743d6540217878d',
        },
        relationshipScore: 50,
        knownBy: ['James Wang'],
        contactInfo: [
          {
            _id: {
              $oid: '66689dbccd4e51881ac93e90',
            },
            name: 'Kate Eisenberg',
            title: 'Director Of Business Development',
            linkedin_url: 'https://www.linkedin.com/in/kateeisenberg',
            location: 'Boston, MA, US',
          },
        ],
      },
      {
        contactId: {
          $oid: '673fe4054743d654021d5fd7',
        },
        relationshipScore: 72,
        knownBy: ['Jody Glidden', 'James Wang'],
        contactInfo: [
          {
            _id: {
              $oid: '673fe3f74743d654021d0de1',
            },
            name: 'David L. Carlson',
            linkedin_url: 'https://www.linkedin.com/in/david-carlson-6629796',
            title: 'Chief Information Officer',
            location: 'Boston, MA, US',
          },
        ],
      },
      {
        contactId: {
          $oid: '673fe4054743d654021d5fe6',
        },
        relationshipScore: 616,
        knownBy: ['Jody Glidden', 'James Wang'],
        contactInfo: [
          {
            _id: {
              $oid: '65b2c8bdc7c8b2526b8f4cec',
            },
            name: 'Chris Doyle',
            title: 'Marketing Technology Manager',
            linkedin_url: 'https://www.linkedin.com/in/chrisjdoyle',
            location: 'Boston, MA, US',
          },
        ],
      },
      {
        contactId: {
          $oid: '673fe4144743d654021d60b5',
        },
        relationshipScore: 4505,
        knownBy: ['James Wang', 'Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '673fe40a4743d654021d60a1',
            },
            name: 'Carolyn Manning',
            linkedin_url: 'https://www.linkedin.com/in/carolynmanning',
            title: 'Chief Marketing and Business Development Officer',
            location: 'Boston, MA, US',
          },
        ],
      },
      {
        contactId: {
          $oid: '673fe41f4743d654021d6198',
        },
        relationshipScore: 6,
        knownBy: ['James Wang'],
        contactInfo: [
          {
            _id: {
              $oid: '673fe4184743d654021d60ce',
            },
            name: 'Jon Clemente',
            title: 'Director of Strategic Accounts',
            linkedin_url: 'https://www.linkedin.com/in/jonathanclemente',
            location: 'New York, NY, US',
          },
        ],
      },
      {
        contactId: {
          $oid: '673fe4284743d654021d61c9',
        },
        relationshipScore: 88,
        knownBy: ['James Wang', 'Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '673fe4224743d654021d61af',
            },
            name: 'Jason Leon',
            linkedin_url: 'https://www.linkedin.com/in/jasonml',
            title: 'Sr. IT Manager - Solutions',
            location: 'Boston, MA, US',
          },
        ],
      },
      {
        contactId: {
          $oid: '673fe67a4743d6540223546c',
        },
        relationshipScore: 55,
        knownBy: ['James Wang', 'Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '673fe6734743d65402235459',
            },
            name: 'James Racine',
            linkedin_url: 'https://www.linkedin.com/in/james-racine-88a12928',
            title: 'Senior Application Developer',
            location: 'Suffolk County, MA, US',
          },
        ],
      },
      {
        contactId: {
          $oid: '67401052529541eed8dfe5e8',
        },
        relationshipScore: 28,
        knownBy: ['James Wang', 'Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '67401044529541eed8dfe5d8',
            },
            name: 'Andrew Berryman',
            linkedin_url: 'https://www.linkedin.com/in/andrewmberryman',
            title: 'Senior Systems Administrator',
            location: 'Lexington, MA, US',
          },
        ],
      },
      {
        contactId: {
          $oid: '67401109529541eed8dfef5b',
        },
        relationshipScore: 6,
        knownBy: ['James Wang'],
        contactInfo: [
          {
            _id: {
              $oid: '674010ff529541eed8dfef4b',
            },
            name: 'Arie Orchanian',
            linkedin_url: 'https://www.linkedin.com/in/arieorchanian',
            title: 'Senior Marketing Technology Specialist',
            location: 'Boston, MA, US',
          },
        ],
      },
      {
        contactId: {
          $oid: '6740156cefb8943e1f94e77f',
        },
        relationshipScore: 949,
        knownBy: ['Jody Glidden', 'James Wang'],
        contactInfo: [
          {
            _id: {
              $oid: '67401565efb8943e1f94e773',
            },
            name: 'Mitch Frazer',
            linkedin_url: 'https://www.linkedin.com/in/mitchfrazer',
            title: 'Managing Partner',
            location: 'Toronto, ON, CA',
          },
        ],
      },
    ],
    companyData: {
      name: 'Mintz',
      industry: 'Legal',
      employees: '1,000+',
      relationshipScore: 6375,
    },
  },
  {
    _id: {
      $oid: '673fe6124743d65402206ddd',
    },
    contacts: [
      {
        contactId: {
          $oid: '673fe6114743d65402206dd6',
        },
        relationshipScore: 180,
        knownBy: ['James Wang', 'Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '673fe6044743d65402206dc0',
            },
            name: 'Lacey Lambert',
            linkedin_url: 'https://www.linkedin.com/in/lacey-lambert-28a95019',
            title: 'Onboarding Specialist',
            location: 'Toronto, ON, CA',
          },
        ],
      },
      {
        contactId: {
          $oid: '673fe61b4743d65402206dfe',
        },
        relationshipScore: 5280,
        knownBy: ['Jody Glidden', 'James Wang'],
        contactInfo: [
          {
            _id: {
              $oid: '673fe6164743d65402206dec',
            },
            name: 'Sebastian Blandizzi',
            linkedin_url: null,
            title: 'CIO, Head of Global Wealth Technology',
            location: 'Toronto, ON, CA',
          },
        ],
      },
    ],
    companyData: {
      name: 'Scotiabank',
      industry: 'Banking',
      employees: '80,000+',
      relationshipScore: 5460,
    },
  },
  {
    _id: {
      $oid: '673fdd5b4743d6540203526a',
    },
    contacts: [
      {
        contactId: {
          $oid: '673fdd594743d65402035267',
        },
        relationshipScore: 1188,
        knownBy: ['James Wang', 'Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '673fdd554743d65402035259',
            },
            name: 'Josh Hasan',
            linkedin_url: 'https://www.linkedin.com/in/josh-hasan-5963631a2',
            title: 'Program Manager, Data and Analytics',
            location: 'Arlington, VA, US',
          },
        ],
      },
      {
        contactId: {
          $oid: '673fe08e4743d654020fd9e3',
        },
        relationshipScore: 333,
        knownBy: ['James Wang', 'Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '66689dbccd4e51881ac93ed7',
            },
            name: 'Craig Abod',
            title: 'President',
            linkedin_url: 'https://www.linkedin.com/in/craigabod',
            location: 'Reston, VA, US',
          },
        ],
      },
      {
        contactId: {
          $oid: '673fe5cb4743d65402206b45',
        },
        relationshipScore: 110,
        knownBy: ['James Wang', 'Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '673fe5c14743d65402206a83',
            },
            name: 'Madeline Spears',
            linkedin_url: 'https://www.linkedin.com/in/madeline-spears-69b0a31b6',
            title: 'Partner Alliance Specialist',
            location: 'North Charleston, SC, US',
          },
        ],
      },
      {
        contactId: {
          $oid: '673fe7ed4743d65402265969',
        },
        relationshipScore: 0,
        contactInfo: [
          {
            _id: {
              $oid: '673fe7e14743d6540226593f',
            },
            name: 'Michael Shrader',
            linkedin_url: 'https://www.linkedin.com/in/michael-shrader-a7b7291',
            title: 'Vice President Intelligence and Innovative Solutions',
            location: 'Reston, VA, US',
          },
        ],
      },
      {
        contactId: {
          $oid: '674005507573574cdd29f0da',
        },
        relationshipScore: 83,
        knownBy: ['James Wang'],
        contactInfo: [
          {
            _id: {
              $oid: '6740054c7573574cdd29f0cc',
            },
            name: 'Kristen Williams',
            linkedin_url: 'https://www.linkedin.com/in/kristen-williams11',
            title: 'Partner Business Development',
            location: 'Vienna, VA, US',
          },
        ],
      },
      {
        contactId: {
          $oid: '674006127573574cdd29f159',
        },
        relationshipScore: 215,
        knownBy: ['Jody Glidden', 'James Wang'],
        contactInfo: [
          {
            _id: {
              $oid: '6740060e7573574cdd29f14b',
            },
            name: 'Corey Rooney',
            linkedin_url: 'https://www.linkedin.com/in/corey-patrick-rooney-50b1045',
            title: 'Director Partner Business Development',
            location: 'Washington, DC, US',
          },
        ],
      },
      {
        contactId: {
          $oid: '674009c5b22ffcb69d47235d',
        },
        relationshipScore: 506,
        knownBy: ['James Wang', 'Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '674009bcb22ffcb69d47234d',
            },
            name: 'Megan Zimmerman',
            linkedin_url: 'https://www.linkedin.com/in/megan-zimmerman-330b19120',
            title: 'Partner Success Manager',
            location: 'Bristow, MA, US',
          },
        ],
      },
      {
        contactId: {
          $oid: '674009e4b22ffcb69d472425',
        },
        relationshipScore: 88,
        knownBy: ['James Wang', 'Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '674009dab22ffcb69d472417',
            },
            name: 'Caitlyn Lewis',
            linkedin_url: 'https://www.linkedin.com/in/caitlyn-lewis-960414142',
            title: 'Market Research Manager',
            location: 'Centreville, VA, US',
          },
        ],
      },
      {
        contactId: {
          $oid: '674009f5b22ffcb69d472443',
        },
        relationshipScore: 88,
        knownBy: ['Jody Glidden', 'James Wang'],
        contactInfo: [
          {
            _id: {
              $oid: '674009e8b22ffcb69d472437',
            },
            name: 'Drew Scherer',
            linkedin_url: 'https://www.linkedin.com/in/andrew--scherer',
            title: 'Fedramp Program Manager',
            location: 'Washington, DC, US',
          },
        ],
      },
      {
        contactId: {
          $oid: '674017ff529541eed8e0394d',
        },
        relationshipScore: 11,
        knownBy: ['James Wang'],
        contactInfo: [
          {
            _id: {
              $oid: '66689dbccd4e51881ac93e92',
            },
            name: 'Scott',
            title: 'Account Representative',
            linkedin_url: 'https://www.linkedin.com/in/evan-scott-388669269',
            location: 'Pensacola, Florida, US',
          },
        ],
      },
      {
        contactId: {
          $oid: '6744e6c30ecf1db145773b8b',
        },
        relationshipScore: 11,
        knownBy: ['James Wang'],
        contactInfo: [
          {
            _id: {
              $oid: '6744e6b80ecf1db145773b7d',
            },
            name: 'Melissa Robledo',
            linkedin_url: 'https://www.linkedin.com/in/melissa-robledo-742859133',
            title: 'Technology Advocate Specialist - Education Team',
            location: 'Lufkin, TX, US',
          },
        ],
      },
      {
        contactId: {
          $oid: '67464bddfd8050f2e70c14d1',
        },
        relationshipScore: 22,
        knownBy: ['Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '67464bd8fd8050f2e70c14c5',
            },
            name: 'Conrad Kodjanian',
            linkedin_url: 'https://www.linkedin.com/in/conrad-kodjanian',
            title: 'Program Manager',
            location: 'Washington, DC, US',
          },
        ],
      },
      {
        contactId: {
          $oid: '67464beafd8050f2e70c15c5',
        },
        relationshipScore: 132,
        knownBy: ['Jody Glidden', 'James Wang'],
        contactInfo: [
          {
            _id: {
              $oid: '67464be5fd8050f2e70c15b3',
            },
            name: 'Aaron Cooperman',
            linkedin_url: 'https://www.linkedin.com/in/aaron-cooperman-b85908122',
            title: 'Director',
            location: 'Washington, DC, US',
          },
        ],
      },
      {
        contactId: {
          $oid: '6747393afd8050f2e76fddbf',
        },
        relationshipScore: 88,
        knownBy: ['James Wang', 'Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '6747392ffd8050f2e76fdcc5',
            },
            name: 'Christopher Baer',
            linkedin_url: 'https://www.linkedin.com/in/christopher-baer-91639b1b7',
            title: 'Systems Integrator Program Manager',
            location: 'Leesburg, VA, US',
          },
        ],
      },
    ],
    companyData: {
      name: 'Carahsoft',
      industry: 'Technology',
      employees: '3,000+',
      relationshipScore: 2875,
    },
  },
  {
    _id: {
      $oid: '6740031f7573574cdd29e906',
    },
    contacts: [
      {
        contactId: {
          $oid: '6740031e7573574cdd29e903',
        },
        relationshipScore: 2789,
        knownBy: ['James Wang', 'Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '6720dd3493cf4609da834b4f',
            },
            name: 'Kelly Harbour',
            title: 'Client Service, Growth, & Innovation Architect',
            linkedin_url: 'https://www.linkedin.com/in/kellyharbour',
            location: 'Boston, MA, US',
          },
        ],
      },
    ],
    companyData: {
      name: 'Goulston & Storrs',
      industry: 'Legal',
      employees: '300+',
      relationshipScore: 2789,
    },
  },
  {
    _id: {
      $oid: '673fe80e4743d65402265a78',
    },
    contacts: [
      {
        contactId: {
          $oid: '673fe80c4743d65402265a75',
        },
        relationshipScore: 420,
        knownBy: ['James Wang', 'Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '673fe8024743d65402265a57',
            },
            name: 'Fabio Arisa',
            linkedin_url: 'https://www.linkedin.com/in/farisa001',
            title: 'Global Digital Sales & Marketing Senior Director',
            location: 'Tampa, Florida, US',
          },
        ],
      },
      {
        contactId: {
          $oid: '674001bb7573574cdd29e3fb',
        },
        relationshipScore: 320,
        knownBy: ['James Wang', 'Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '674001b07573574cdd29e3ef',
            },
            name: 'Annie Pinet',
            linkedin_url: 'https://www.linkedin.com/in/anniepinet',
            title: 'Senior Client Relationship Lead',
            location: 'Toronto, ON, CA',
          },
        ],
      },
      {
        contactId: {
          $oid: '67400da2efb8943e1f94c8e0',
        },
        relationshipScore: 660,
        knownBy: ['Jody Glidden', 'James Wang'],
        contactInfo: [
          {
            _id: {
              $oid: '64c09bf03a51f7cc2f1b3821',
            },
            name: 'Mitchell Reisler',
            title: 'Director, Strategy Leader, Products and Technology',
            linkedin_url: 'https://www.linkedin.com/in/mitchellreisler',
            location: 'Toronto, ON, CA',
          },
        ],
      },
      {
        contactId: {
          $oid: '67400fe2efb8943e1f94cf8f',
        },
        relationshipScore: 800,
        knownBy: ['Jody Glidden', 'James Wang'],
        contactInfo: [
          {
            _id: {
              $oid: '67400fd5efb8943e1f94cf81',
            },
            name: 'Sue Sharp',
            linkedin_url: 'https://www.linkedin.com/in/sue-sharp',
            title: 'Partner, National Customer Experience Transformation and CMO Advisory Leader',
            location: 'Etobicoke, ON, CA',
          },
        ],
      },
      {
        contactId: {
          $oid: '67402714efb8943e1f9553db',
        },
        relationshipScore: 28,
        knownBy: ['Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '67402708efb8943e1f9553cf',
            },
            name: 'Rocio Ayelén Romero',
            linkedin_url: 'https://www.linkedin.com/in/rocio-ayelén-romero-288751291',
            title: 'Bilingual Administrative Assistant',
            location: 'Buenos Aires, AR',
          },
        ],
      },
      {
        contactId: {
          $oid: '674028e2efb8943e1f955ec0',
        },
        relationshipScore: 200,
        knownBy: ['Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '674028d7efb8943e1f955eb2',
            },
            name: 'Louanne Buckley',
            linkedin_url: 'https://www.linkedin.com/in/louanne-buckley-b02a6113',
            title: 'Partner',
            location: 'Toronto, ON, CA',
          },
        ],
      },
      {
        contactId: {
          $oid: '67403031efb8943e1f959a66',
        },
        relationshipScore: 20,
        knownBy: ['Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '6740302cefb8943e1f959a5a',
            },
            name: 'Laura Talarcek',
          },
        ],
      },
      {
        contactId: {
          $oid: '6740325fefb8943e1f95afd1',
        },
        relationshipScore: 0,
        knownBy: ['Jody Glidden'],
        contactInfo: [
          {
            _id: {
              $oid: '67403254efb8943e1f95afc5',
            },
            name: 'Laura Davis',
            linkedin_url: 'https://www.linkedin.com/in/ljd5109',
            title: 'Senior Manager, Global CRM Innovation',
            location: 'Orlando, Florida, US',
          },
        ],
      },
    ],
    companyData: {
      name: 'PWC',
      industry: 'Accounting',
      employees: '300,000+',
      relationshipScore: 2448,
    },
  },
];

const contactNames = [];
// Iterate over each company in the demoData array
demoData.forEach((company) => {
  // Iterate over each contact in the company's contacts array
  company.contacts.forEach((contact) => {
    // Iterate over each contactInfo in the contact's contactInfo array
    contact.contactInfo.forEach((info) => {
      // Output the name from contactInfo
      contactNames.push(info.name);
    });
  });
});

console.log(contactNames);
